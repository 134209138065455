<template>
  <div class="content-box">
    
    <div class="unedited" v-if="!showList">
      <div class="top-card">
        <div class="card-title">
          <div class="title-left">管理员</div>
          <div class="title-right">
            <div class="member-number">{{ this.manageList.length }}人</div>
            <div class="more-roles" @click.stop="openChoice">
              <el-image
              class="images" 
              :src="require('@/assets/more-roles.png')"
              fit="cover">
              </el-image>
            </div>
          </div>
        </div>
        <div class="sign-out" :class="showOut?'show-out':''">
          <div class="edit-information" @click.stop="addAdministrator()"><span class="out-text">添加管理员</span></div>
          <div class="delete-image" @click.stop="removeAdministrator()"><span class="out-text">移除管理员</span></div>
        </div>
      </div>
      <div v-if="!removeAdmin">
        <div class="member-card" v-loading="loadingMember"  v-if="this.manageList.length!=0">
          <div class="fans-box">
            <div class="fans-detail" v-for="(item, index) in manageList" :key="index" @click="openMyroles(item)"> 
              <div class="fans-image">
                <img-head :avatarUrl="item.avatarUrl" :Radius="50" :size="60"></img-head>	
              </div>
              <div class="fans-name">{{ item.nickname }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="member-card"  v-if="this.manageList.length!=0">
          <div class="fans-box">
            <div class="fans-detail" v-for="(item, index) in manageList" :key="index" @click="choiceremove(item)"> 
              <div class="fans-image">
                <img-head :avatarUrl="item.avatarUrl" :Radius="50" :size="60"></img-head>	
              </div>
              <div class="fans-name">{{ item.nickname }}</div>
              <div class="nochoices" >
                <div class="content">
                  <el-image
                    class="more-roles" 
                    :src="require('@/assets/unchoicePhoto.png')"
                    fit="cover">
                  </el-image>                  
                </div>
                <div class="contents" v-if="item.checked">
                  <el-image
                    class="more-roles" 
                    :src="require('@/assets/choicePhoto.png')"
                    fit="cover">
                  </el-image>
                </div>
              </div> 
            </div>
          </div>
        </div>
        <div class="buttons-box" >
          <div class="cancel">
            <div class="text" @click="cancelRemove">取消</div>
          </div>
          <div class="unadd" v-if="!buttonDisable">
            <div class="text">移除</div>
          </div>
          <div class="add" v-else>
            <div class="text" @click="isRemoveAdmin">移除</div>
          </div>
        </div> 
      </div>   
    </div>
    
    <!-- 添加管理员页面 -->
    <div class="edited" v-else> 
      <div class="top-card">
        <div class="card-title">
          <div class="title-left">名单</div>
          <div class="title-right">
            <div class="member-number">{{ this.manageList.length }}人</div>
          </div>
        </div>
      </div>
      <div class="group-box" v-if="this.groupMember.length!=0">
        <div class="group-card" v-loading="loadingMember"  v-for="(item, index) in groupMember" :key="index">
          <div class="fans">{{ item.groupName }}（{{ item.groupMember.length }}人）</div>
          <div class="fans-box">
            <div class="fans-detail" v-for="(items, indexs) in item.groupMember" :key="indexs" @click="clickgroup(items)">
              <div class="fans-image" >
                <img-head :avatarUrl="items.avatarUrl" :Radius="50" :size="60"></img-head>	
              </div>
              <div class="fans-name">{{ items.groupNickname }}</div>
              <div class="nochoice" >
                <div class="content">
                  <el-image
                    class="more-roles" 
                    :src="require('@/assets/unchoicePhoto.png')"
                    fit="cover">
                  </el-image>                  
                </div>
                <div class="contents" v-if="items.checked">
                  <el-image
                    class="more-roles" 
                    :src="require('@/assets/choicePhoto.png')"
                    fit="cover">
                  </el-image>
                </div>
              </div> 
            </div>
          </div>
        </div>      
      </div>
      <div class="member-card" v-loading="loadingMember" v-if="this.memberList.length!=0">
        <div class="fans">成员（{{ this.memberList.length }}人）</div>
        <div class="fans-box">
          <div class="fans-detail" v-for="(item, index) in memberList" :key="index" @click="clicknogroup(item)">
            <div class="fans-image" >
              <img-head :avatarUrl="item.avatarUrl" :Radius="50" :size="60"></img-head>	
            </div>
            <div class="fans-name">{{ item.nickname }}</div>
            <div class="nochoices" >
              <div class="content">
                <el-image
                  class="more-roles" 
                  :src="require('@/assets/unchoicePhoto.png')"
                  fit="cover">
                </el-image>                  
              </div>
              <div class="contents" v-if="item.checked">
                <el-image
                  class="more-roles" 
                  :src="require('@/assets/choicePhoto.png')"
                  fit="cover">
                </el-image>
              </div>
            </div> 
          </div>
        </div>
      </div>
      <div class="button-box">
        <div class="cancel">
          <div class="text" @click="cancelDelect">取消</div>
        </div>
        <div class="unadd" v-if="!addbuttonDisable">
          <div class="text">添加</div>
        </div>
        <div class="add" v-else>
          <div class="text" @click="confirmadministrator">添加</div>
        </div>
      </div>   
    </div>

    <!-- 添加管理员权限弹窗 -->
    <div class="box-card" v-if="openPopup">
      <span class="box-view-top">
        <div class="title">
          <span class="title-text">请选择管理员权限</span>
          <icon-font name="close" color="#444444" size="16px"  @click="closeadminpopup" style="cursor: pointer;margin-right: 15px;margin-top: -20px"></icon-font>
        </div>
        <div class="default-cover-list">
          <div class="roles-list">
            <div class="item-line" v-for="(item, index) in titleArr" :key="index" @click="getSelectId(index)" >
              <div class="left-name">{{item.name}}</div>
              <div class="right"  v-if="item.checked">
                <icon-font name="mychoice" color="#1465CF" size="16px" ></icon-font>
              </div>
              <div class="right" v-else>
                <icon-font name="weixuanzhongyuanquan" color="#444444" size="16px" ></icon-font>
              </div>
            </div>
          </div>
        </div>
        <div class="button-box" style="">
          <div class="confirm">
            <div class="confirm-text" @click="confirmadmin()">确定</div>
          </div>	
        </div>			
      </span>
    </div>

    <!-- 提示弹窗 -->
    <tip-popup :title="this.titles" :isOpen="isOpen" @close="closePopup" @confirm="confirm"></tip-popup>

  </div>
</template>

<script>
import jpg from "@/assets/default-avatar.png"
import tipPopup from '../../components/tip-popup.vue'
import iconFont from "../../components/IconFont.vue";
import imgHead from '../../components/img-head.vue'
import {eventBus} from '../../main.js'

export default {
  data() {
    return {
      imgUrl: jpg,                                    // 加载不出的图片
      loadingMember: false,                           // 是否显示加载动画
      showOut: false,                                 // 是否打开权限窗口
      manageList: [],                                 // 俱乐部管理员  
      titles: '',                                     // 弹窗提示标题
      isOpen: false,                                  // 是否打开提示弹窗
      showList: false,                                // 添加管理员窗口   
      groupMember: [],                                // 分组人员
      memberList: [],                                 // 成员人员
      openPopup: false,                               // 选择管理员权限弹窗
      titleArr: [],                                   // 权限列表
      choiceItem: [],                                 // 选中的管理员
      buttonDisable: false,                           // 移除管理员buttton显示
      addbuttonDisable: false,                        // 添加管理员buttton显示
      removeAdmin: false,                             // 显示移除管理员操作
      modifyPermissions: false,                       // 是否修改权限
      groupNumber: 0,                                 // 
    }
  },
  components:{
    tipPopup,
    iconFont,
    imgHead
  },

  async mounted(){
    eventBus.$on('closeEditMode', () => {
      if(this.showList) {
        this.cancelDelect()
      }
      if(this.removeAdmin) {
        this.cancelRemove()
      }
    })
    await this.searchMember();
    await this.getClubRoleList();
    document.addEventListener('click', this.HiddenClick,false)
  },

  beforeDestroy(){
    document.removeEventListener('click', this.HiddenClick,false)
  },

  methods:{
    // 点击页面事件 隐藏需要隐藏的区域
    HiddenClick () {
      if(this.showOut){
        this.showOut = false
      }
    },

    // 打开图片权限窗口
    openChoice() {
      this.showOut = !this.showOut
    },

    // 查询俱乐部创建者、管理员、成员
    async searchMember() {
      this.loadingMember = true 
      try {
        let data = {
          clubId: this.$route.params.clubId,
        }
        let res = await this.$request.post("/clubMemberInfo/selectMemberGroupList", data)
        if (res.code === 200) {        
          this.memberList = res.data.notGroupMember
          this.manageList = res.data.managers
          this.groupMember = res.data.groupMember
          this.loadingMember = false
          let i = 0
          for(let item of res.data.groupMember){
            item.checked = false
            for(let items of item.groupMember){
              if(items.id!=0){
                i++
              }
            }
          }
          this.groupNumber = i
        }
      } catch (e) {
        this.loadingMember = false
				//
			}
    },

    // 查询俱乐部的权限
    async getClubRoleList(){
      try {
        let res = await this.$request.get('/latteRoleInfo/getClubRoleList')
        for(let item of res.data){
          item.checked = true
        }
        this.selectCount = res.data.length
        this.titleArr = res.data
      } catch(e) {
        // 
      }
    },

    // 选择授予权限
    getSelectId(index){
      this.titleArr[index].checked = !this.titleArr[index].checked
    },

    // 修改管理员权限
    async openMyroles(item){
      await this.getClubRoleList()
      this.openPopup = true
      this.modifyPermissions = true
      try {
        let res = await this.$request.get(`/latteRoleInfo/getClubManagerRole/?clubId=${this.$route.params.clubId}&userId=${item.userId}`)
        for(let item of this.titleArr){
          item.checked = false
        }
        for (let item of this.titleArr) {
          for (let items of res.data) {
            if (item.id === items.roleId){
              item.checked =  true
            }
          }
        }
        this.userList = [item.id]
      } catch(e) {
        // 
      }
    },

    // 添加管理员
    async addAdministrator() {
      // this.isOpen = true
      if(this.groupNumber===0 && this.memberList.length===0){
        this.$message.success({
          showClose: true,
          message: '暂无更多人员可添加为管理员'
        });        
        return
      }
      this.showList = true
      this.showOut = false
      await this.getClubRoleList();
    },

    // 询问是否移除管理员
    removeAdministrator() {
      if(this.manageList.length===0){
        this.$message.warning({
          showClose: true,
          message: '无法做此操作'
        });     
        return
      }
      this.removeAdmin = true
      this.showOut = false
			// this.isOpen = true
      // this.titles = "确定要移除管理员吗？"       
    },

    // 选中移除管理人员
    choiceremove(item) {
      item.checked = !item.checked
      for(let items of this.manageList){
        if(items.checked){
          this.buttonDisable = true
          return
        }else{
          this.buttonDisable = false
        }
      }
    },

    // 取消移除管理员
    async cancelRemove() {
      this.removeAdmin = false 
      for(let items of this.manageList){
        if(items.checked){
          items.checked = false
        }
      }
      this.buttonDisable = false
    },

    // 询问是否确定移除管理员
    isRemoveAdmin() {
      this.isOpen = true
      this.titles = "确定要移除管理员吗？"
    },

    // 最后移除管理员
    async deleteAdmin(){
      let arr = this.manageList
      let selectArr = []
      for(let item of arr){
        if(item.checked){
          selectArr.push(item.id)
        }
      }
      let data = {
        clubId: this.$route.params.clubId,
        idList: selectArr
      }
      let res = await this.$request.post('/clubMemberInfo/cancelManagers', data)
      if(res.code===200){
        this.$message.success({
          showClose: true,
          message: '已移除管理员'
        });      
        this.removeAdmin = false       
        this.searchMember()
      }
    },

    // 选中group人员
    clickgroup(items) {
      items.checked = !items.checked
      for(let item of this.groupMember){
        for(let items of item.groupMember){
          if(items.checked){
            this.addbuttonDisable = true
            return 
          }else{
            this.addbuttonDisable = false
          }    
        }
      }
      for(let items of this.memberList){
        if(items.checked){
          this.addbuttonDisable = true
          return
        }else{
          this.addbuttonDisable = false
        }
      }
    },

    // 选中nogroup人员
    clicknogroup(item) {
      item.checked = !item.checked
      for(let items of this.memberList){
        if(items.checked){
          this.addbuttonDisable = true
          return
        }else{
          this.addbuttonDisable = false
        }
      }
      for(let item of this.groupMember){
        for(let items of item.groupMember){
          if(items.checked){
            this.addbuttonDisable = true
            return 
          }else{
            this.addbuttonDisable = false
          }    
        }
      }
    },

    // 取消添加管理员
    async cancelDelect() {
      this.showList = false
      for(let items of this.memberList){
        if(items.checked){
          items.checked = false
        }
      }
      for(let item of this.groupMember){
        for(let items of item.groupMember){
          if(items.checked){
            items.checked = false 
          }
        }
      }
      this.addbuttonDisable = false
    },

    // 添加管理员询问
    confirmadministrator() {
      let group = false
      let groupList = []
      let nogroup = false
      let nogroupList = []
      for(let item of this.memberList){
        if(item.checked){
          nogroup = true
          nogroupList.push(item.id)
        }
      }
      for(let item of this.groupMember){
        for(let items of item.groupMember){
          if(items.checked){
            group = true
            groupList.push(items.id)
          }    
        }
      }
      if(group || nogroup){
        // 
      }else{
        this.$message.success({
          showClose: true,
          message: '请至少选择一名管理员'
        }); 
        return
      }
      this.openPopup = true
      this.userList = [...groupList,...nogroupList]
    },  

    // 弹窗再次确定添加管理员
    async confirmadmin() {
      let roleIds = []
      for(let item of this.titleArr){
        if(item.checked){
          roleIds.push(item.id)
        }
      }
      if(roleIds.length === 0) {
        this.$message.success({
          showClose: true,
          message: '请至少选择一项权限'
        }); 
        return
      }

      let data = {
        clubId: this.$route.params.clubId,
        idList: this.userList,
        roleIds: roleIds
      }
      try{
        let res = await this.$request.post('/clubMemberInfo/setManagers', data)
        if(res.code===200){
          if(this.modifyPermissions){
            this.$message.success({
              showClose: true,
              message: '已修改管理员权限'
            }); 
            this.modifyPermissions = false
          }else{
            this.$message.success({
              showClose: true,
              message: '已添加为管理员'
            }); 
            this.modifyPermissions = false
          }
          this.userList = []
          this.showList = false
          this.openPopup = false
        }
        await this.searchMember()
      } catch(e){
        // this.buttonDisable = false
      }
    },

    // 关闭删除动态弹窗
		closePopup() {
      this.isOpen = false
      this.showOut = false
    },

    // 关闭管理员弹窗
    closeadminpopup() {
      this.openPopup = false      
    },

    // 确认回调事件
    async confirm() {
      if(this.titles=="确定要移除管理员吗？"){
        this.deleteAdmin()
        this.isOpen = false
        this.titles = ''
        this.showOut = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .content-box{
    width: 790px;
    min-height: 661px;
    background: #ffffff;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 20px;
    position: relative;
    .unedited{
      .top-card{
        position: relative;
        margin-bottom: 20px;
        .card-title{
          display: flex;
          justify-content: space-between;
          .title-left {
            font-size: 18px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            text-align: left;
            color: #000000;
            line-height: 25px;
          }
          .title-right {
            display: flex;
            align-items: center;
            .member-number{
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: bold;
              text-align: center;
              color: #444444;
              line-height: 20px;
              margin-bottom: 5px;
              margin-right: 20px;
            }
            .more-roles{
              cursor: pointer;
              .images{
                width: 18px;
                height: 18px;
              }
            }
          }
        }
        .sign-out{
          cursor: pointer;
          width: 120px;
          height: 0;
          background: #fff;
          border-radius: 5px;
          position:absolute;
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
          right: -52px;
          z-index: 99;
          //修改页面抖动
          top: 28px;
          box-sizing: border-box;
          transition: all 0.25s cubic-bezier(0.49, -0.3, 0.68, 1.23);
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .edit-information{
            margin-bottom: 5px;
            color:#444444;
            .out-text{
              font-size: 14px;
            }
          }
          .edit-information:hover{
            color: #1465CF !important;
          }
          .delete-image{
            margin-bottom: 5px;
            color:#444444;
            .out-text{
              font-size: 14px;
            }
          }
          .delete-image:hover{
            color: #1465CF !important;
          }
          .delete-album{
            color:#444444;
            .out-text{
              font-size: 14px;
            }
          }
          .delete-album:hover{
            color: #1465CF !important;
          }
        }
        .show-out{
        height: 70px;
        // border: 1px solid #dddddd;
        box-shadow: 0px 2px 10px 0px undefined; 
        }
      }
      .roles-text{
        position: relative;
        padding-bottom: 10px;
        .total-intro{
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #444444;
          line-height: 22px;
          margin-bottom: 5px;       
        }
        .detail-intro{
          font-size: 14px;
          font-family: PingFang, PingFang-Regular;
          font-weight: 400;
          text-align: left;
          color: #888888;
          line-height: 20px;
          margin-bottom: 5px;       
        }
      }
      .roles-text:after{
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0.05rem;
        background-color: #dddddd;
        transform: scaleY(0.5);
        transform-origin: center top;
        z-index: 2;
      }
      .member-card{
        margin-top: 20px;
        .fans{
          margin-bottom: 20px;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          color: #444444;
          line-height: 22px;
        }
        .fans-box{
          display: flex;
          cursor: pointer;
          .fans-detail{
            display: flex;
            flex-direction: column;
            height: 85px;
            width: 60px;
            margin-right: 20px;
            margin-bottom: 20px;
            position: relative;
            .fans-image{
              cursor: pointer;
              .images{
                border-radius: 50%;
                width: 60px;
                height: 60px;
              }
            }
            .fans-name{
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: bold;
              text-align: center;
              color: #444444;
              line-height: 20px;
              width: 65px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: center;
              margin-left: -3px;         
            }
            .nochoices{
              position: absolute;
              left: 17px;
              top: 40px;
              width: 20px;
              height: 20px;
              z-index: 999;
              border-radius: 50%;
              cursor: pointer;
              .content{
                margin-top: 2.5px;
                margin-left: 29px;
                width: 18px;
                height: 18px;
                z-index: 99;
              }
              .contents{
                margin-top: -19px;
                margin-left: 28px;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                z-index: 99;              
              }
            }
          }
        }
      }
      .buttons-box{
        position: absolute;
        bottom: 0;
        left: 310px;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        .cancel{
            width: 100px;
            height: 30px;
            background: #F7F8FA;
            border-radius: 5px;  
            margin-right: 10px;
            cursor: pointer;  
            .text{
              font-size: 14px;
              font-weight: 400;
              color: #444444;
              line-height: 20px;
              padding: 5px 35px;
            }
          }  
        .add{
            width: 100px;
            height: 30px;
            background: #EC4747;
            border-radius: 5px;  
            cursor: pointer;  
            .text{
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
              padding: 5px 35px;
            }
          }
        .unadd{
            width: 100px;
            height: 30px;
            background: #A8A8A8;
            border-radius: 5px;  
            cursor: pointer;  
            .text{
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
              padding: 5px 35px;
            }
        }
      }  
    }
    .edited{
      .top-card{
        position: relative;
        .card-title{
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          .title-left {
            font-size: 18px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            text-align: left;
            color: #000000;
            line-height: 25px;
          }
          .title-right {
            display: flex;
            align-items: center;
            .member-number{
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: bold;
              text-align: center;
              color: #444444;
              line-height: 20px;
              margin-bottom: 5px;
            }
            .more-roles{
              cursor: pointer;
              .images{
                width: 18px;
                height: 18px;
              }
            }
          }
        }
        .sign-out{
          cursor: pointer;
          width: 120px;
          height: 0;
          background: #fff;
          border-radius: 5px;
          position:absolute;
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
          right: -52px;
          z-index: 99;
          //修改页面抖动
          top: 28px;
          box-sizing: border-box;
          transition: all 0.25s cubic-bezier(0.49, -0.3, 0.68, 1.23);
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .edit-information{
            margin-bottom: 5px;
            color:#444444;
            .out-text{
              font-size: 14px;
            }
          }
          .edit-information:hover{
            color: #1465CF !important;
          }
          .delete-image{
            margin-bottom: 5px;
            color:#444444;
            .out-text{
              font-size: 14px;
            }
          }
          .delete-image:hover{
            color: #1465CF !important;
          }
          .delete-album{
            color:#444444;
            .out-text{
              font-size: 14px;
            }
          }
          .delete-album:hover{
            color: #1465CF !important;
          }
        }
        .show-out{
          height: 70px;
          box-shadow: 0px 2px 10px 0px undefined; 
        }
      }
      .group-box {
        .group-card{
          position: relative;
          .fans{
            margin-bottom: 20px;
            padding-top: 20px;
            font-size: 16px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            color: #444444;
            line-height: 22px;
          }
          .fans-box{
            display: flex;
            flex-wrap: wrap;
            cursor: pointer;
            .fans-detail{
              display: flex;
              flex-direction: column;
              height: 85px;
              width: 60px;
              margin-right: 20px;
              margin-bottom: 20px;
              position: relative;
              .fans-image{
                cursor: pointer;
                .images{
                  border-radius: 50%;
                  width: 60px;
                  height: 60px;
                }
              }
              .fans-name{
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: bold;
                text-align: center;
                color: #444444;
                line-height: 20px;
                width: 50px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: center;         
              }
              .nochoice{
                position: absolute;
                left: 17px;
                top: 40px;
                width: 20px;
                height: 20px;
                z-index: 999;
                border-radius: 50%;
                cursor: pointer;
                .content{
                  margin-top: 2.5px;
                  margin-left: 29px;
                  width: 18px;
                  height: 18px;
                  z-index: 99;
                }
                .contents{
                  margin-top: -19px;
                  margin-left: 28px;
                  width: 18px;
                  height: 18px;              
                  background: #1465CF;
                  border-radius: 50%;
                  z-index: 99;              
                }
              }
            }
          }
        }
        .group-card:after{
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 0.05rem;
          background-color: #dddddd;
          transform: scaleY(0.5);
          transform-origin: center top;
          z-index: 2;
        }
        .group-card:last-child{
          border: none !important;    
        }      
      }
      .member-card{
        padding-top: 20px;
        position: relative;      
        .fans{
          margin-bottom: 20px;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          color: #444444;
          line-height: 22px;
        }
        .fans-box{
          display: flex;
          flex-wrap: wrap;
          .fans-detail{
            cursor: pointer;
            display: flex;
            flex-direction: column;
            height: 85px;
            width: 60px;
            margin-right: 20px;
            margin-bottom: 20px;
            position: relative;
            .fans-image{
              cursor: pointer;
              .images{
                border-radius: 50%;
                width: 60px;
                height: 60px;
              }
            }
            .fans-name{
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: bold;
              text-align: center;
              color: #444444;
              line-height: 20px;
              width: 50px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: center;         
            }
            .nochoices{
              position: absolute;
              left: 17px;
              top: 40px;
              width: 20px;
              height: 20px;
              z-index: 999;
              border-radius: 50%;
              cursor: pointer;
              .content{
                margin-top: 2.5px;
                margin-left: 29px;
                width: 18px;
                height: 18px;
                z-index: 99;
              }
              .contents{
                margin-top: -19px;
                margin-left: 28px;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                z-index: 99;              
              }
            }
          }
        }
      }
      .member-card:after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 0.05rem;
        background-color: #dddddd;
        transform: scaleY(0.5);
        transform-origin: center top;
        z-index: 2;
      }
      .button-box{
        position: absolute;
        bottom: 0;
        left: 310px;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        .cancel{
            width: 100px;
            height: 30px;
            background: #F7F8FA;
            border-radius: 5px;  
            margin-right: 10px;
            cursor: pointer;  
            .text{
              font-size: 14px;
              font-weight: 400;
              color: #444444;
              line-height: 20px;
              padding: 5px 35px;
            }
          }  
        .add{
            width: 100px;
            height: 30px;
            background: #EC4747;
            border-radius: 5px;  
            cursor: pointer;  
            .text{
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
              padding: 5px 35px;
            }
          }
        .unadd{
            width: 100px;
            height: 30px;
            background: #A8A8A8;
            border-radius: 5px;  
            cursor: pointer;  
            .text{
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
              padding: 5px 35px;
            }
          }
      }  
    }

    // 弹窗样式
    .box-card{
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, .2);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.box-view-top{
			box-sizing: border-box;
			width: 480px;
			height: 436px;
			background: #FFFFFF;
			border-radius: 10px;
			overflow: hidden;
			position: relative;
			.title{
				margin-top: 20px;
				display: flex;
				.title-text{
					width: 100%;
					margin-left: 170px;
					font-size: 18px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: bold;
					color: #000000;
					line-height: 25px;;
				}
				.close{
					width: 15px;
					height: 15px;
					cursor: pointer;
				}
      }
      .default-cover-list{
        display: flex;
        flex-wrap: wrap;
        margin: 30px 60px 10px 60px;
        .roles-list{
          .item-line{
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #DDDDDD;
            width: 360px;
            .left-name{ 
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #000000;
              line-height: 20px;
            }    
          }
          .item-line:last-child{
            border-bottom: none;
          }
        }
      }
			.button-box{
        display: flex;
        align-items: center;
        justify-content: center;
				.confirm{
					width: 100px;
					height: 30px;
					background:#1465CF;
					border-radius: 5px;
					cursor: pointer;
					.confirm-text{
						font-size: 14px;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 20px;
						padding: 4px 35px;
					}
				}
			}
		}
  }
  }
</style> 

<style lang="scss">
  .el-message_icon{
    font-size: 20px;
    margin-left: 20px;
  }
  .el-icon-success {
    color: #FFAF5A !important;
  }
  .el-message{
    min-width: auto;
    background-color: #000000;
    border-radius: 5px;
    opacity: 0.8;
  }
  .el-message--success .el-message__content{
    color: #FFFFFF!important;
  }
  .el-message.is-closable .el-message__content {
    padding-right: 30px;
  }
</style>