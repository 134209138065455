<template>
    <div class="container">
        <p class="title">超级分享者明细数据</p>
        <div class="tab-box">
            <div class="tab" @click="tabClick(1)">
                <span class="label" :class="tabType == 1 ? 'select-style' : ''">分享明细</span>
                <div class="tag" :class="tabType == 1 ? 'tag-select' : ''"></div>
            </div>
            <div class="tab" @click="tabClick(2)">
                <span class="label" :class="tabType == 2 ? 'select-style' : ''">分享者管理</span>
                <div class="tag" :class="tabType == 2 ? 'tag-select' : ''"></div>
            </div>
        </div>
        <div class="content-box">
            <template v-if="tabType == 2">
                <div class="table">
                    <el-table
                        empty-text="暂无数据"
                        :data="superSharerList"
                        style="width: 100%"
                        :border="true"
                        :header-cell-style="headerCellStyle">
                        <el-table-column
                            fixed
                            prop="avatarUrl"
                            align="center"
                            width="137">
                            <template slot="header">
                                <div class="table-header">超级分享者昵称</div>
                            </template>
                            <template slot-scope="scope">
                                <div style="display: flex; align-items: center; overflow: hidden;">
                                    <el-image :src="$cosUrl + scope.row.distributionUserUrl" fit="cover" style="width: 40px; height: 40px; border-radius: 50%; overflow: hidden"></el-image>
                                    <p class="table-text" style="margin-left: 10px; max-width: 66px;">{{ scope.row.distributionUserName }}</p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="username"
                            align="center"
                            width="120">
                            <template slot="header">
                                <div class="table-header">拿铁号</div>
                            </template>
                            <template slot-scope="scope">
                                <div class="table-text">{{ scope.row.distributionLatteNo }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="joinTime"
                            align="center"
                            width="168">
                            <template slot="header">
                                <div class="table-header">联系方式</div>
                            </template>
                            <template slot-scope="scope">
                                <div class="table-text">{{ scope.row.distributionUserPhoneNumber }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="firstJoinActivityTime"
                            align="center"
                            width="170">
                            <template slot="header">
                                <div class="table-header">分享成交数量</div>
                            </template>
                            <template slot-scope="scope">
                                <div class="table-text">{{ scope.row.memberCount }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="lastJoinActivityTime"
                            align="center"
                            width="170"
                            class-name="group-list">
                            <template slot="header">
                                <div class="table-header">分享获得的总奖励金</div>
                            </template>
                            <template slot-scope="scope">
                                <div class="table-text">{{ scope.row.distributionAmount }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="sevenDayJoinActivityNum"
                            align="center"
                            width="120"
                            class-name="group-list">
                            <template slot="header">
                                <div class="table-header">操作</div>
                            </template>
                            <template slot-scope="scope">
                                <div class="remove-super-share" @click="removeBtnClick(scope.row)">移除推广权限</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div style="width: 100%; padding: 12px 0; display: flex; align-items: flex-end;">
                    <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageIndex"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="totalCount"
                    layout="total, sizes, prev, pager, next, jumper"
                    >
                    </el-pagination>
                </div>
            </template>
            <template v-else>
                <div class="table">
                    <el-table
                        empty-text="暂无数据"
                        :data="superShareRecordList"
                        style="width: 100%"
                        :border="true"
                        :header-cell-style="headerCellStyle">
                        <el-table-column
                            fixed
                            prop="avatarUrl"
                            align="center"
                            width="137">
                            <template slot="header">
                                <div class="table-header">超级分享者昵称</div>
                            </template>
                            <template slot-scope="scope">
                                <div style="display: flex; align-items: center; overflow: hidden;">
                                    <el-image :src="$cosUrl + scope.row.distributionUserUrl" fit="cover" style="width: 40px; height: 40px; border-radius: 50%; overflow: hidden"></el-image>
                                    <p class="table-text" style="margin-left: 10px; max-width: 66px;">{{ scope.row.distributionUserName }}</p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="username"
                            align="center"
                            width="120">
                            <template slot="header">
                                <div class="table-header">拿铁号</div>
                            </template>
                            <template slot-scope="scope">
                                <div class="table-text">{{ scope.row.distributionLatteNo }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="joinTime"
                            align="center"
                            width="168">
                            <template slot="header">
                                <div class="table-header">联系方式</div>
                            </template>
                            <template slot-scope="scope">
                                <div class="table-text">{{ scope.row.distributionUserPhoneNumber }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="firstJoinActivityTime"
                            align="center"
                            width="170">
                            <template slot="header">
                                <div class="table-header">推广活动</div>
                            </template>
                            <template slot-scope="scope">
                                <div class="table-text">{{ scope.row.activityName }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="lastJoinActivityTime"
                            align="center"
                            width="170"
                            class-name="group-list">
                            <template slot="header">
                                <div class="table-header">分享成交的用户</div>
                            </template>
                            <template slot-scope="scope">
                                <div style="display: flex; align-items: center; justify-content: center; overflow: hidden;">
                                    <el-image :src="$cosUrl + scope.row.userUrl" fit="cover" style="width: 40px; height: 40px; border-radius: 50%; overflow: hidden"></el-image>
                                    <p class="table-text" style="margin-left: 10px; max-width: 66px;">{{ scope.row.userName }}</p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="sevenDayJoinActivityNum"
                            align="center"
                            width="120"
                            class-name="group-list">
                            <template slot="header">
                            <div class="table-header">分享成交数量</div>
                            </template>
                            <template slot-scope="scope">
                            <div class="table-text">{{ scope.row.memberCount }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="sevenDayJoinActivityRate"
                            align="center"
                            width="120"
                            class-name="group-list">
                            <template slot="header">
                            <div class="table-header">分享奖励金</div>
                            </template>
                            <template slot-scope="scope">
                            <div class="table-text">+{{ scope.row.distributionAmount }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="benefitsAmount"
                            align="center"
                            width="120"
                            class-name="group-list">
                            <template slot="header">
                            <div class="table-header">平台服务费</div>
                            </template>
                            <template slot-scope="scope">
                            <div class="table-text">-{{ scope.row.benefitsAmount }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="realIncomeAmount"
                            align="center"
                            width="120"
                            class-name="group-list">
                            <template slot="header">
                            <div class="table-header">实际收入</div>
                            </template>
                            <template slot-scope="scope">
                            <div class="table-text">{{ scope.row.realIncomeAmount || '0' }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="thirtyDayJoinActivityNum"
                            align="center"
                            width="120"
                            class-name="group-list">
                            <template slot="header">
                                <div class="table-header">结算状态</div>
                            </template>
                            <template slot-scope="scope">
                                <div class="table-text">{{ distributionSettlementStatus(scope.row) }}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div style="width: 100%; padding: 12px 0; display: flex; align-items: flex-end;">
                    <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageIndex"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="totalCount"
                    layout="total, sizes, prev, pager, next, jumper"
                    >
                    </el-pagination>
                </div>
            </template>
        </div>

        <!-- 提示弹窗 -->
        <tip-popup title="确定要移除推广权限吗？" :isOpen="isOpenDialog" @close="closePopup" @confirm="confirm"></tip-popup>
    </div>
</template>

<script>
  import tipPopup from '../../components/tip-popup.vue'
  export default {
    components: {
        tipPopup
    },
    data() {
      return {
        tabType: 1,
        headerCellStyle: {
            height: '48px',
            backgroundColor: '#F9FBFF',
            padding: '4px 0',
        },
        pageIndex: 1,
        pageSize: 10,
        totalCount: 0,
        superShareRecordList: [], // 分享明细
        superSharerList: [], // 分享者列表
        isOpenDialog: false,
        selectSuperSharer: null,
      }
    },
    computed: {
        distributionSettlementStatus() {
            return function(item) {
                if (!item) return ""
                let statusStr = ""
                if (item.orderStatus == 3) {
                    statusStr = `不结算(全额退款/用户解散)`
                } else {
                    if (item.distributionStatus == 1) {
                        statusStr = `待结算`
                    } else if (item.distributionStatus == 2) {
                        statusStr = `已结算`
                    }

                    if (statusStr && item.orderStatus == 2) {
                        statusStr = statusStr + '(部分退款)'
                    }
                }

                return statusStr
            }
        },
    },
    async mounted() {
        this.getSuperShareRecordList()
    },

    methods: {
        tabClick(type) {
            this.tabType = type
            this.pageIndex = 1
            this.pageSize = 10
            this.totalCount = 0
            this.reqInfo()
        },
        // 获取分享明细
        async getSuperShareRecordList() {
            try {
                let params = {
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    clubId: this.$route.params.clubId,
                }
                console.log(params)
                let res = await this.$request.get(`/activityDistributionRecord/listClubActivityDistributionRecord?pageIndex=${this.pageIndex}&pageSize=${this.pageSize}&clubId=${this.$route.params.clubId}`)
                if (res.page) {
                    this.superShareRecordList = res.page.list
                    this.totalCount = res.page.totalCount
                }
            }
            catch (e) {
                //
            }
        },
        // 获取分享者列表
        async getSuperSharerList() {
            try {
                let params = {
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    clubId: this.$route.params.clubId,
                }
                console.log(params)
                let res = await this.$request.get(`/clubDistributionUser/listClubDistributionUserData?pageIndex=${this.pageIndex}&pageSize=${this.pageSize}&clubId=${this.$route.params.clubId}`)
                if (res.page) {
                    this.superSharerList = res.page.list
                    this.totalCount = res.page.totalCount
                }
            }
            catch (e) {
                //
            }
        },
        removeBtnClick(item) {
            console.log(item)
            this.selectSuperSharer = item
            this.isOpenDialog =  true
        },
        reqInfo() {
            if (this.tabType == 2) {
                this.getSuperSharerList()
            } else {
                this.getSuperShareRecordList()
            }
        },
        closePopup() {
            this.isOpenDialog = false
        },
        async confirm() {
            try {
                let params = {
                    id: this.selectSuperSharer.id,
                    isDelete: 2
                }
                console.log(params)
                await this.$request.post(`/clubDistributionUser/updateClubDistributionUser`, params)
                this.isOpenDialog = false
                this.getSuperSharerList()
            }
            catch (e) {
                //
            }
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.reqInfo()
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.reqInfo()
        },
    }
  }
</script>

<style scoped lang="scss">
.container {
    width: 842px;
    border-radius: 10px;
    padding: 16px 24px 24px;
    background: #fff;
    box-sizing: border-box;
    .title {
        font-size: 20px;
        line-height: 28px;
        color: #003D6E;
    }
    .tab-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 68px;
        .tab {
            margin-right: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .label {
                font-size: 16px;
                line-height: 22px;
                color: #003D6E;
                cursor: pointer;
            }
            .select-style {
                color: #2487FF;
            }
            .tag {
                margin-top: 4px;
                width: 52px;
                height: 2px;
            }
            .tag-select {
                background: linear-gradient(90deg, #2487FF 0%, #5D9EFF 100%);
            }
        }
    }
    .remove-super-share {
        color: #0078FF;
        font-size: 14px;
        cursor: pointer;
    }
}
</style>