<template>
  <div class="club-moment-page">
    <div style="width: 790px; height: auto; background: #FFFFFF; border-radius: 10px; margin-bottom: 15px;">
      <club-info :detail="clubDetail" :myRole="myRole" :clubRoles="clubRoles" @refresh="getClubDetail"></club-info>
    </div>
    <div class="home-nav">
      <div class="top-item" :class="current === 1 ? 'current' : ''" @click="clickTab(1)">
        <span class="text">动态</span>
      </div>
      <div class="top-item" :class="current === 2 ? 'current' : ''" @click="clickTab(2)">
        <span class="text">活动</span>
      </div>
    </div>
    <transition name="fade-transform" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      pageIndex: 1,
      pageSize: 10,
      nomore: false,
      isEmpty: false,
      isLoading: false,
      clubDetail: null,
      clubRoles: [],
      myRole: 0,
      current: 1
    }
  },

  computed: {
    //这里需要把store 动态的数据放到computed里面才会同步更新 视图
    roles() {
      return this.$store.state.clubRoles
    }
  },

  created() {
    this.getMyClubRole()
    this.getClubDetail()
    this.getClubHomeNews()
    window.addEventListener("scroll", this.scroll)
  },

  destroyed() {
    window.removeEventListener('scroll', this.scroll)
  },

  methods: {
    clickTab(index) {
      this.current = index
      if (index == 1) {
        this.$router.push({ name: 'ClubMoment' })
      }
      else if (index == 2) {
        this.$router.push({ name: 'ClubActivity' })
      }
    },

    refresh() {
      this.pageIndex = 1,
        this.pageSize = 10,
        this.nomore = false,
        this.getClubHomeNews(true)
    },

    // 获取俱乐部主页动态信息
    async getClubHomeNews(isRefresh = false) {
      this.isLoading = true
      try {
        let res = await this.$request.get(`/home/clubHomeNews?clubId=${this.$route.params.clubId}&pageIndex=${this.pageIndex}&pageSize=${this.pageSize}`)
        if (res.page.totalCount == 0) {
          this.isEmpty = true
        }
        else {
          this.isEmpty = false
          let data = res.page.list
          // 循环遍历，绑定唯一id
          for (let item of data) {
            item.viewId = `follow${item.newsId}`
          }
          if (isRefresh) {
            this.list = data
          } else {
            this.list = [...this.list, ...data]
          }
          if (res.page.totalPage == res.page.pageIndex) {
            this.nomore = true
          }
          else {
            this.nomore = false
            this.pageIndex++
          }
        }
      } catch (error) {
        // console.log('err-------', error)
      }
      this.isLoading = false
    },

    // 获取俱乐部信息
    async getClubDetail() {
      try {
        let res = await this.$request.get(`/clubInfo/selectByClubId/${this.$route.params.clubId}`)
        this.clubDetail = res.data
      }
      catch (e) {
        //
      }
    },

    // 获取用户在俱乐部的权限
    async getMyClubRole() {
      try {
        let res = await this.$request.get(`/clubMemberInfo/getMyRole/${this.$route.params.clubId}`)
        let data = {
          clubId: this.$route.params.clubId,
          role: 0,
          canEditNews: false,
          canEditActivity: false,
          canEditMember: false,
          canEditTheme: false,
          canEditData: false,
          canEditClub: false,
          canActivityData: false,
          canManagerSuperShare: false,
        }
        if (res.data.role) {
          this.myRole = res.data.role
          this.clubRoles = res.data.roles
          data.role = res.data.role
          for (let i = 0; i < res.data.roles.length; i++) {
            if (res.data.roles[i].roleId == 2) {
              // 俱乐部动态管理
              data.canEditNews = true
            }
            else if (res.data.roles[i].roleId == 3) {
              // 俱乐部活动管理
              data.canEditActivity = true
            }
            else if (res.data.roles[i].roleId == 4) {
              // 俱乐部成员管理
              data.canEditMember = true
            }
            else if (res.data.roles[i].roleId == 5) {
              // 俱乐部相册管理
              data.canEditTheme = true
            }
            else if (res.data.roles[i].roleId == 6) {
              // 编辑俱乐部信息
              data.canEditClub = true

            }
            else if (res.data.roles[i].roleId == 7) {
              // 查看俱乐部数据
              data.canEditData = true
            }
            else if (res.data.roles[i].roleId == 10) {
              // 查看活动数据
              data.canActivityData = true
            }
                            else if (res.data.roles[i].roleId == 11) {
                                // 超级分享者管理
                                data.canManagerSuperShare = true
                            }
          }
        }
        this.$store.commit('SET_CLUBROLES', data)
      } catch (error) {
        //
      }
    },
    //监听页面滚动
    scroll() {
      // 距离底部50px时加载一次
      let bottomOfWindow = document.documentElement.offsetHeight - document.documentElement.scrollTop - window.innerHeight <= 50
      if (bottomOfWindow && !this.isLoading && !this.nomore && !this.isEmpty) {
        this.getClubHomeNews()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.empty {
  width: 790px;
  height: 456px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  .empty-title {
    color: #444;
    font-size: 16px;
    font-weight: bold;
  }
}
.home-nav {
  width: 790px;
  height: 51px;
  background-color: #fff;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  .top-item {
    width: 50px;
    margin: 0 35px;
    height: 100%;
    box-sizing: border-box;
    transition: all 0.5s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      font-size: 14px;
    }
  }
  .current {
    color: #1465cf;
  }
}
</style>