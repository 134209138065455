<template>
	<div class="activity-item" v-if="refresh"> 
		<div class="item-left">
			<div class="activity-cover">
				<el-image class="cover" :src="info.url" fit="cover"></el-image>
        <div class="tag-item">
					<div class="tag" v-if="info.isOver == 1">已结束</div>
					<div class="tag" v-else-if="info.isJoin == 1">已参加</div>
					<div class="tag tag-ing" v-else-if="info.isOverJoin == 2">报名中</div>
					<div class="tag" v-else-if="info.isOverJoin == 1">报名截止</div>
				</div>
			</div>
			<div class="activity-info">
				<div class="info-line">
						<el-popover
							placement="bottom"
							width="50"
							trigger="hover">
							<div class="message-popover" v-if="info.isOver == 1 && info.isMyActivity ">
								<div class="message-popover-item" @click="deleteActivity">删除活动</div>
							</div>
							<div class="message-popover" v-else-if="info.isMyActivity && info.isOver == 2 && info.afterCheacked ==2">
								<div class="message-popover-item" @click="popup">解散活动</div>
								<div class="message-popover-item" @click="deleteActivity">删除活动</div>
							</div>
							<div class="message-popover" v-else-if="!info.isMyActivity">
								<div class="message-popover-item" @click="reportActivity">投诉</div>
							</div>
							<div class="message-popover" v-else>
								<div class="message-popover-item" @click="reportActivity">投诉</div>
							</div>
							<div class="drop-icon" slot="reference" style="float: right;cursor: pointer;">
								<icon-font name="arrow-down" color="#b1b1b1" size="14px"></icon-font>
							</div>
						</el-popover>
					<!-- <div class="arrow" style="float: right;cursor: pointer;" >
						<icon-font	icon-font class="arrow-down" name="arrow-down" size="12px" color="#000000"></icon-font>
					</div> -->
          <div class="name">{{ info.activityName }}</div>
					<div class="time">时间：{{ info.startTimeStr }} ~ {{ info.endTimeStr }}</div>
					<div class="location-box" style="width: 330px">
						<div class="location">地点：{{ info.showAddress }}</div>
					</div>
          <div class="detail" v-if="info.nonMemberAmount > 0">
            <span>费用：￥{{ info.nonMemberAmount.toFixed(2) }}元/人</span>
          </div>
          <div class="detail" v-else>
            <span>费用：免费</span>
          </div>
				</div>
			</div>
		</div>
		<div class="activity-member-view" v-if="showMember">
			<div class="activity-member-title">报名/签到用户</div>
			<div class="activity-member-list" v-loading="loadingMember">
				<div class="activity-member-item" v-for="item in memberList" :key="item.userId">
					<div class="avatar">
						<el-avatar :size="50" :src="$cosUrl + item.avatarUrl"></el-avatar>
						<div class="checked" v-if="item.signTime">
							<icon-font name="checked" size="7px" color="#fff"></icon-font>
						</div>
					</div>
					<div class="nickname">{{ item.nickname }}离开手机放啦是的肌肤</div>
					<div class="latte1">拿铁号</div>
					<div class="latte2">{{ item.userId }}</div>
				</div>
			</div>
		</div>
		<div class="delete-activity" v-if="showDelete">
			<div class="delete-title">给报名用户发送的活动解散短信通知</div>
			<div class="delete-name">@用户名雪雪，由于</div>
			<div class="delete-input">
				<textarea name="" id="" v-model="message" cols="30" rows="10"></textarea>
			</div>
			<div class="delete-tip1">
				您报名的「{{ info.activityName }}」被组织者解散了。
			</div>
			<div class="delete-tip2">
				感谢您的积极参与，期待下次再见～
			</div>
			<div class="delete-btn" @click="disbandActivity">确认解散并发送</div>
		</div>
    <div class="box-card"  v-if="upPop">
      <span class="box-view-top">
        <div class="title">
          <span class="title-text">提示</span>
          <el-image
						class="close" 
						:src="require('@/assets/close.png')"
						fit="cover"
						@click="close"
          ></el-image>
        </div>
				<div class="introduction">
					<el-input class="textarea" type="textarea" v-model="message" :autosize="{ minRows: 5, maxRows: 18}" placeholder="告诉小伙伴解散理由吧~" maxlength="60" resize="none"  show-word-limit></el-input>
				</div>
				<div class="button-box">
					<div class="cancel">
						<div class="cancel-text" @click="close">取消</div>
					</div>
					<div class="confirm">
						<div class="confirm-text" @click="disbandActivity">确定</div>
					</div>	
				</div>	
			</span>
		</div>
		<complaint :detail="this.info" :isClub="true" :type=2 :flag="iscomplaint" @close="closeComplaint" ></complaint>
		<tip-popup :title="this.titles" :isOpen="isOpen" @close="closePopup" @confirm="confirm"></tip-popup>
	</div>
	
</template>

<script>
	import iconFont from '../IconFont'
	import complaint from './club-complaint.vue';
	import tipPopup from '../tip-popup.vue'
	export default {
		data() {
			return {
				titles: "确定要删除此活动吗？",
				isOpen: false,
				showDelete: false,
				showMember: false,
				loadingMember: false,
				memberList: [],
				message: '',
				upPop: false,
				iscomplaint: false,
				refresh: true,
			}
		},
		
		components: {
			iconFont,
			complaint,
			tipPopup
		},
		
		props: {
			info: {
				type: Object,
				default: () => null
			}
		},
		
		methods: {
			clickDeleteActivity() {
				this.showMember = false
				this.showDelete = !this.showDelete
				if(!this.showDelete) {
					this.message = ''
				}
			},

			// 解散活动
			async disbandActivity() {
				if(this.message.length == 0) {
					this.$message({
						message: '请填写解散理由',
						type: 'warning'
					})
					return
				}
				if(this.message.length > 60) {
					this.$message({
						message: '解散理由最多不能超过60字哦',
						type: 'warning'
					})
					return
				}
				const loading = this.$loading({
					lock: true,
					text: "Loading",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				try{
					let data = {"clubId": this.info.clubId, "activityId": this.info.activityId, "message": this.message}
					await this.$request.post('/activityMemberInfo/disbandActivity', data)
					this.message = ''
					this.$message({
						message: '活动已解散',
						type: 'success'
					})
					loading.close();
					this.refresh = false
				}catch(e){
					// console.log(e)
				}
				loading.close();
			},

			// 打开解散理由弹窗
			async popup(){
				this.upPop = true
			},

			// 关闭解散活动弹窗
			close(){
				this.upPop = false
			},

			async deleteActivity() {
				// if(this.message.length == 0) {
				// 	this.$message({
				// 		message: '请填写解散理由',
				// 		type: 'warning'
				// 	})
				// 	return
				// }
				// const loading = this.$loading({
				// 	lock: true,
				// 	text: "Loading",
				// 	spinner: "el-icon-loading",
				// 	background: "rgba(0, 0, 0, 0.7)",
				// });
				// this.$alert('确定要删除此活动吗','提示', {
				// 	confirmButtonText: '确定',
				// 	showCancelButton: true
				// }).then(async() => {
				// 	let data = {"clubId": this.info.clubId, "id": this.info.activityId}
				// 	let res = await this.$request.post('/activityInfo/removeActivity', data)
				// 	console.log("删除的res",res)
				// 	if(res.code===200){
				// 		this.$message({
				// 		message: '活动已删除',
				// 		type: 'success'
				// 		})
				// 	}else{
				// 		return
				// 	}
				// 	this.$emit('refresh', true)
				// }).catch(() => {
				// 	this.$message({
				// 		type: 'info',
				// 		message: '已取消删除'
				// 	});          
				// });
				// loading.close();
				this.isOpen = true
			},

			reportActivity() {
				this.iscomplaint = true
			},
			
			//处理投诉
			closeComplaint() {
				this.iscomplaint = false
				// this.handleComplaintFlag = !this.handleComplaintFlag;
			},

			//关闭提示弹窗
			closePopup(){
				this.isOpen = false
			},

			async confirm(){
				const loading = this.$loading({
					lock: true,
					text: "Loading",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				if(this.info.userCount>1){
					this.$message.warning("暂无法删除未被解散的活动")
					loading.close();
					this.isOpen = false
					return
				}
				try{
					let data = {"clubId": this.info.clubId, "id": this.info.activityId}
					let res = await this.$request.post('/activityInfo/removeActivity', data)
					if(res.code===200){
						this.$message({
						message: '活动已删除',
						type: 'success'
						})
					}else{
						loading.close();
						return
					}
					this.$emit('refresh', true)
					loading.close();
				}catch(e){
					this.isOpen = false
					loading.close();
					return
				}
			}
		}
		
	};
</script>

<style lang="scss" scoped>
	.activity-item {	
    border-radius: 5px;
		box-sizing: border-box;
		width: 790px;
		box-sizing: border-box;
		background: #fff;
		margin-bottom: 15px;
		// margin-right: 10px;
		padding: 20px;
		.item-left {
			box-sizing: border-box;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			box-sizing: border-box;
			.activity-cover {
				width: 335px;
				height: 176px;
				border-radius: 5px;
				background: #b3b3b3;
				// overflow: hidden;
        position: relative;
				.cover {
					width: 335px;
					height: 176px;
					border-radius: 5px;
					display: block;
				}
        .tag-item {
          position: absolute;
          top: 15px;
          left: 20px;
					.tag {
						color: #FFF;
						font-size: 14px;
						padding: 2px 10px;
						border: 1px solid transparent;
						border-radius: 1px;
						border-radius: 3px;
						white-space: nowrap;
            background: rgba(0, 0, 0, .49);
					}
					.tag-ing {
						color: #1465CF;
						background: #EDF5FF;
						border-color: #C6DFFF;
					}
				}
			}
			.activity-info {
				flex: 1;
				height: 170px;
				margin-left: 20px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
        .info-line {
          .name {
            color: #444;
            font-size: 18px;
            font-weight: bold;
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-bottom: 20px;
          }
          .time {
            color: #444;
            font-size: 14px;
            margin-bottom: 10px;
          }
          .location {
            width: 100%;
            color: #444;
            font-size: 14px;
            margin-bottom: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .detail {
            display: flex;
            align-items: center;
            color: #444;
            font-size: 14px;
          }
        }
        .button-line {
          display: flex;
          align-items: center;
          .btn-item {
            width: 76px;
            height: 26px;
            border-radius: 3px;
            border: 1px solid #DDDDDD;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #444;
            margin-right: 10px;
            cursor: pointer;
          }
        }
			}
		}
		.activity-member-view {
			width: 100%;
			height: 376px;
			background: #FFFFFF;
			border-radius: 5px;
			border: 1px solid #DDDDDD;
			box-sizing: border-box;
			margin: 20px 0 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			.activity-member-title {
				font-size: 16px;
				font-weight: bold;
				color: #444;
				padding: 15px 0;
			}
			.activity-member-list {
				width: 100%;
				flex: 1;
				display: flex;
				flex-wrap: wrap;
				align-content: flex-start;
				overflow: scroll;
				.activity-member-item {
					width: 85px;
					margin-bottom: 20px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					.avatar {
						height: 50px;
						position: relative;
						.checked {
							width: 17px;
							height: 17px;
							border-radius: 50%;
							background: #1465CF;
							display: flex;
							align-items: center;
							justify-content: center;
							position: absolute;
							bottom: 0;
							right: 0;
						}
					}
					.nickname {
						width: 80%;
						font-size: 14px;
						color: #444;
						margin-top: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.latte1 {
						font-size: 12px;
						color: #888;
						margin-top: 5px;
					}
					.latte2 {
						font-size: 12px;
						color: #888;
					}
				}
			}
		}
		.delete-activity {
			width: 100%;
			height: 260px;
			border-radius: 5px;
			border: 1px solid #DDDDDD;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 15px 0 20px;
			box-sizing: border-box;
			margin: 20px 0 30px;
			.delete-title {
				font-size: 16px;
				color: #444;
			}
			.delete-name {
				font-size: 14px;
				color: #444;
				margin-top: 20px;
			}
			.delete-input {
				width: 335px;
				height: 60px;
				border-radius: 3px;
				margin-top: 5px;				
				textarea {
					width: 335px;
					height: 60px;
					resize: none;
					border: none;
					outline: none;
					box-sizing: border-box;
					border: 1px solid #DDDDDD;
				}
			}
			.delete-tip1 {
				font-size: 14px;
				color: #444;
				margin-top: 10px;
			}
			.delete-tip2 {
				font-size: 14px;
				color: #444;
			}
			.delete-btn {
				width: 118px;
				height: 28px;
				border-radius: 3px;
				font-size: 14px;
				color: #fff;
				background: #1465CF;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 20px;
				cursor: pointer;
			}
		}
	}
	.box-card{
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .box-view-top{
		width: 480px;
		height: 257px;
		background: #FFFFFF;
		border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-bottom: 58px;
    .title{
      .title-text{
        // margin: 20px 258px;
				margin-left: 229px;
				margin-top: 20px;
      }
      .close{
        width: 15px;
        height: 15px;
        margin-left: 190px;
        margin-top: 15px;
        cursor: pointer;
      }
    }
    .type-titles{
      width: 154px;
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #F5A623;
      line-height: 20px;
      margin-left: 40px;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .introduction{
      font-size: 12px;
      font-weight: 500;
      color: #444444;
      line-height: 17px;
			.textarea{
				width: 400px;
				font-size: 16px;
				color: #999;
				margin-top: 10px;
				outline: none;                   
			}
		}
		.button-box{
			display: flex;
			justify-content: center;
			.confirm{
				width: 100px;
				height: 30px;
				background: #EC4747;
				border-radius: 5px;
				cursor: pointer;
				margin-right: 130px;
				.confirm-text{
					font-size: 14px;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 20px;
					padding: 6px 35px;
				}
			}
			.cancel{
				width: 100px;
				height: 30px;
				border: 1px solid #DDDDDD;
				border-radius: 5px;
				margin: 25px auto;
				margin-left: 135px;
				cursor: pointer;
				.cancel-text{
					font-size: 14px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #444444;
					line-height: 20px;
					padding: 6px 35px;
				}
			}
		}
  }
  .box-view-bottom{
    width: 630px;
    height: 376px;
    background: #FFFFFF;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    .title{
      .title-text{
        margin-left: 305px;
      }
      .close{
        width: 15px;
        height: 15px;
        margin-left: 220px;
        margin-top: 20px;
        cursor: pointer;
      }
    }
    .content-box{
      border-bottom: 1px solid #DDDDDD;	
      padding-bottom: 20px;			
      .title{
        display: flex;
        justify-content: space-around;
        align-content: center;
        margin-right: 65px;
          margin-left: -20px;
        margin-top: 20px;
        .labels{
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #A8A8A8;
          line-height: 20px;						
        }
      }
      .reply{
        margin-top: 8px;
        display: flex;
        align-content: center;
        .labels1{
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #444444;
          line-height: 20px;
          margin-left: 30px;					
        }
        .labels2{
          height: 40px;
          width: 126px;
          font-size: 14px;
          font-weight: 400;
          color: #444444;
          line-height: 20px;
          margin-left: 30px;
          display: inline-block;		
          .labels2-text{
            width: 100%;
            height: 100%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;							
          }					
        }
        .labels3-loading{
          height: 26px;
          border-radius: 5px;
          border: 1px solid #DDDDDD;
          font-size: 14px;
          font-weight: 400;
          color: #444444;
          line-height: 20px;
          margin-left: 27px;	
          padding: 3px 10px 0 10px;				
        }
        .labels3-success{
          height: 26px;
          border-radius: 5px;
          border: 1px solid#1465CF;
          font-size: 14px;
          font-weight: 400;
          color: #1465CF;
          line-height: 20px;
          margin-left: 27px;	
          padding: 3px 10px 0 10px;				
        }					
        .labels4{
          height: 40px;
          width: 75px;
          font-size: 14px;
          font-weight: 400;
          color: #444444;
          line-height: 20px;
          margin-left: 100px;
          display: inline-block;
          .labels4-text{
            width: 100%;
            height: 100%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }					
        }										
      }
      .reply:last-child{
      border: none !important;
    }
    }
    .content-box:last-child{
      border: none !important;
      margin-bottom: 15px;
    }
  }
}
</style>
<style lang="scss" scoped>
	.el-button--primary {
			color: #fff !important;
	}
	// .el-message-box .el-message-box__btns .el-button--primary span {
  //   color: #fff;
  //   font-size: 14px;
	// 	font-weight: 500;
	// 	line-height: 20px;
	// }
	// .el-message-box .el-message-box__btns .el-button--primary {
  //   width: 100px;
  //   height: 30px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   margin-right: 10px;
  //   margin-top: 0px;
	// }
	// .el-message-box .el-message-box__btns .el-button--primary:nth-child(2) {
  //   margin-left: 10px;
	// }
	// .el-button--mini, .el-button--small {
	// 	font-size: 14px;
	// 	padding: 8px 36px;
	// 	margin-left: 30px;
	// }
</style>