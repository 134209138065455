<template>
	<div class="box-card" v-if="flag">
		<div class="box-view-top" @click="close">
			<div class="title">
				<el-image
				class="close" 
				:src="require('@/assets/wechat_program_code.png')"
				style="width:180px;height:180px;"
				fit="cover"
        ></el-image>
			</div>
			<div class="introduction">
				<div class="introduction-text">微信扫码打开LattePlus 小程序</div>
			</div>
			<div class="confirms">
				<div class="confirms-text">关闭</div>
			</div>		
		</div>
	</div>
</template>

<script>
export default {
	props:{
		flag:{
			type: Boolean,
			default: false
		},
	},

	methods:{
		//关闭投诉框
		close(){
			this.$emit('close')
		},
	}
}
</script>

<style lang="scss" scoped>
	.box-card{
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, .3);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		
		.box-view-top{
			width: 330px;
			background: #FFFFFF;
			border-radius: 10px;
			overflow: hidden;
			cursor: pointer;
			.title{
				margin-top: 15px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.introduction{
				font-size: 16px;
				color: #000000;
				line-height: 22px;
				display: block;
				text-align: center;
				margin-top: 15px;
				margin-bottom: 15px;
			}
			.confirms{
				display: flex;
				align-content: center;
				justify-content: center;
				cursor: pointer;				
				margin-top: 10px;
				margin-bottom: 10px;
				border-top: 1px solid #dddddd;
				.confirms-text{
					width: 32px;
					height: 22px;
					font-size: 16px;
					font-weight: 400;
					color: #1465CF;
					line-height: 22px;
					padding-top: 12px;
				}
			}
		}
	}
</style>
