import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

axios.defaults.timeout = 5000;
axios.interceptors.request.use(function (config) {
  config.headers.common['terminalType'] = 'web'
  return config
})

export default {
  post: function (path = '', data = {}) {
    return new Promise((resolve, reject) => {
      axios.post(path, data)
      .then((response) => {
        if (response.data.code === 200) {
          return resolve(response.data)
        }
        else {
          alert(response.data.msg)
          return reject(response.data.msg)
        }
      })
      .catch((error) => {
        reject(error);
      });
    });
  },

  get: function (path = '', data = {}) {
    return new Promise((resolve, reject) => {
      axios.get(path, data)
      .then((response) => {
        if(response.data.code === 200) {
          return resolve(response.data)
        }
        else {
          alert(response.data.msg)
          return reject(response.data.msg)
        }
      })
      .catch((error) => {
        reject(error);
      })
    })
  }
}