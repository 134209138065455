<template>
  <div class="container">
    <div class="club-roles">
      <div class="release-button" @click="releaseNews" v-if="this.$store.state.clubRoles.role != 0">
        <div class="button-text" :class="isShowReleaseNews?'activeBtn':''">
          <span class="el-icon-s-promotion iconBtu" :class="isShowReleaseNews?'activeBtn':''"></span>
          <span class="text" :class="isShowReleaseNews?'activeBtn':''">发布动态</span>
        </div>
      </div>
      <div class="release-button" @click="releaseActivity" v-if="(this.$store.state.clubRoles.role == 2 && this.$store.state.clubRoles.canEditActivity) || this.$store.state.clubRoles.role == 3">
        <div class="button-text" :class="isShowReleaseActivity?'activeBtn':''">
          <span class="el-icon-s-promotion iconBtu" :class="isShowReleaseActivity?'activeBtn':''"></span>
          <span class="text" :class="isShowReleaseActivity?'activeBtn':''">发布活动</span>
        </div>
      </div>
      <div class="club-role-item" id="clubHome" :class="navIndex ==  1? 'active' : '' " @click="checkedNeedTip(1)">
        <span class="albums-icon iconfont icon-club-navz"></span>
        <div class="role-text">俱乐部首页</div>
      </div>
      <div class="role-item" :class="navIndex ==  2? 'active' : '' " @click="checkedNeedTip(2)">
        <span class="albums-icon iconfont icon-club-navx"></span>
        <div class="role-text">相册</div>
      </div>
      <div class="role-items">
        <span class="albums-icon iconfont icon-club-navc"></span>
        <div class="role-text" @click="()=>{isShowPersonFlag = !isShowPersonFlag,isShowDataFlag = false,checkedNeedTip(6)}">
          <span :class="isShowPersonFlag?'active':''">成员</span>
          <span :class="isShowPersonFlag?'el-icon-arrow-down arrow-down active':'el-icon-arrow-right arrow-down'"></span>
        </div>
      </div>

      <template v-if="isShowPersonFlag">
        <div class="role-item data-line" :class="navIndex == 6 ? 'active' : '' " @click="checkedNeedTip(6)">
          <span class="albums-icon iconfont icon-club-navs"></span>
          <div class="role-text">全部成员</div>
        </div>
        <div class="role-item data-line" :class="navIndex == 7 ? 'active' : '' " @click="checkedNeedTip(7)" v-if="this.$store.state.clubRoles.role === 3 || this.$store.state.clubRoles.canEditMember">
          <span class="albums-icon iconfont icon-club-navs"></span>
          <div class="role-text">成员管理</div>
        </div>
        <div class="role-item data-line" :class="navIndex == 111 ? 'active' : '' " @click="checkedNeedTip(111)" v-if="this.$store.state.clubRoles.role === 3 || this.$store.state.clubRoles.canManagerSuperShare">
            <span class="albums-icon iconfont icon-club-navs"></span>
            <div class="role-text">超级分享者</div>
        </div>
        <div class="role-item data-line" :class="navIndex == 8 ? 'active' : '' " @click="checkedNeedTip(8)" v-if="this.$store.state.clubRoles.role === 3">
          <span class="albums-icon iconfont icon-club-navs"></span>
          <div class="role-text">管理员</div>
        </div>
        <div class="role-item data-line" :class="navIndex == 9 ? 'active' : '' " @click="checkedNeedTip(9)" v-if="this.$store.state.clubRoles.role === 3 || this.$store.state.clubRoles.canEditMember">
          <span class="albums-icon iconfont icon-club-navs"></span>
          <div class="role-text">黑名单</div>
        </div>
      </template>

      <div class="role-items" v-if="(this.$store.state.clubRoles.role == 2 && (this.$store.state.clubRoles.canEditData || this.$store.state.clubRoles.canActivityData)) || this.$store.state.clubRoles.role == 3">
        <span class="albums-icon iconfont icon-club-navs"></span>
        <div class="role-text" @click="openDataPanel">
          <span :class="isShowDataFlag?'active':''">数据看板</span>
          <span :class="isShowDataFlag?'el-icon-arrow-down arrow-down active':'el-icon-arrow-right arrow-down'"></span>
        </div>
      </div>
      <template v-if="isShowDataFlag">
        <div class="role-item data-line" :class="navIndex == 4 ? 'active' : '' " @click="checkedNeedTip(4)" v-if="(this.$store.state.clubRoles.role == 2 && this.$store.state.clubRoles.canEditData) || this.$store.state.clubRoles.role == 3">
          <span class="albums-icon iconfont icon-club-navs"></span>
          <div class="role-text">数据概览</div>
        </div>
        <div class="role-item data-line" :class="navIndex == 10 ? 'active' : '' " @click="checkedNeedTip(10)" v-if="(this.$store.state.clubRoles.role == 2 && this.$store.state.clubRoles.canEditData) || this.$store.state.clubRoles.role == 3">
          <span class="albums-icon iconfont icon-club-navs"></span>
          <div class="role-text">活跃数据</div>
        </div>
        <div class="role-item data-line" :class="navIndex == 11 ? 'active' : '' " @click="checkedNeedTip(11)" v-if="(this.$store.state.clubRoles.role == 2 && this.$store.state.clubRoles.canActivityData) || this.$store.state.clubRoles.role == 3">
          <span class="albums-icon iconfont icon-club-navs"></span>
          <div class="role-text">活动数据</div>
        </div>
        <div class="role-item data-line" :class="navIndex == 5 ? 'active' : '' " @click="checkedNeedTip(5)" v-if="this.$store.state.clubRoles.role == 3">
          <span class="albums-icon iconfont icon-club-navs"></span>
          <div class="role-text">更多</div>
        </div>
      </template>
    </div>
    <div style="height: 140px; width: 100%"></div>
  </div>
</template>

<script>
import { eventBus } from '../../main.js'
export default {
  data() {
    return {
      isShowReleaseNews: false,
      isShowReleaseActivity: false,
      isShowPersonFlag: false,
      isShowDataFlag: false,
      showLink: 1,
      isOpenNewPages: false,
      num: -2,
      type: 0, // 页面跳转类型
    }
  },
  props: {
    navIndex: {
      type: Number,
      default: 1
    }
  },

  async created() {
    eventBus.$on("tipsShowAccept", this.tipsShowAccept)
    eventBus.$on("dataChanges", (num) => {
      if (num == 0) {
        this.isOpenNewPages = true
      } else {
        this.isOpenNewPages = false
      }
    })
    eventBus.$on("haveConfirm", (num) => {
      if (num == 0) {
        this.isOpenNewPages = false
      }
    })
  },
  watch: {
    $route: {
      handler(val) {
        if (val.name == 'ClubReleaseNew') {
          this.num = -1
        } else if (val.name == 'ClubReleaseEvent') {
          this.num = 0
        }
      },
    }
  },

  mounted() {
    if (this.$store.state.clubRoles.role === 0) {
      const element = document.getElementById('clubHome')
      element.style.setProperty('--clubHome', '0')
    }
  },
  beforeDestroy() {
    eventBus.$off("tipsShowAccept")
  },
  methods: {
    openDataPanel() {
      this.isShowDataFlag = !this.isShowDataFlag
      this.isShowPersonFlag = false
      if ((this.$store.state.clubRoles.role == 2 && this.$store.state.clubRoles.canEditData) || this.$store.state.clubRoles.role == 3) {
        this.checkedNeedTip(4)
      } else {
        this.checkedNeedTip(11)
      }
    },
    tipsShowAccept() {
      this.openNewPage()
    },
    checkedNeedTip(type) {
      this.isShowReleaseNews = false
      this.isShowReleaseActivity = false
      if (type === 1 || type === 2) {
        this.isShowDataFlag = false
        this.isShowPersonFlag = false
      }
      this.type = type
      if (this.isOpenNewPages && this.navIndex === 0) {
        eventBus.$emit('tipsShowWatch', { show: true, content: '离开页面无法保存数据，是否继续？' })
      } else {
        this.openNewPage()
      }
    },
    openNewPage() {
      let index = this.type
      this.isOpenNewPages = false
      eventBus.$emit('haveConfirm', 0)
      if (index === 1) {
        this.$router.push({ name: 'ClubDetail' })
        this.$emit('currentNav', index)
      } else if (index === 2) {
        this.$router.push({ name: 'ClubTheme' })
        this.$emit('currentNav', index)
      } else if (index === 3) {
        this.$router.push({ name: 'AllMember' })
        this.$emit('currentNav', 6)
      } else if (index === 4) {
        if (this.$store.state.clubRoles.role === 3 || this.$store.state.clubRoles.canEditData || this.$store.state.clubRoles.canActivityData) {
          this.$router.push({ name: 'ClubData' })
          this.$emit('currentNav', index)
        } else {
          this.$alert('你暂无权限', '提示', {
            confirmButtonText: '确定',
          })
        }
      } else if (index === 5) {
        this.$alert('后续功能正在开发中，敬请期待~', '提示', {
          confirmButtonText: '确定',
        }).then(() => {
          // this.$emit('currentNav',1)
        })
      } else if (index === 6) {
        this.$router.push({ name: 'AllMember' })
        this.$emit('currentNav', index)
      } else if (index === 7) {
        this.$router.push({ name: 'ManageMember' })
        this.$emit('currentNav', index)
      } else if (index === 8) {
        if (this.$store.state.clubRoles.role === 3) {
          this.$router.push({ name: 'Administrator' })
          this.$emit('currentNav', index)
        } else {
          this.$alert('你暂无权限', '提示', {
            confirmButtonText: '确定',
          })
        }
      } else if (index === 9) {
        this.$router.push({ name: 'Blacklist' })
        this.$emit('currentNav', index)
      } else if (index === 10) {
        this.$router.push({ name: 'ClubActiveData' })
        this.$emit('currentNav', index)
      } else if (index === 11) {
        this.$router.push({ name: 'ClubActivityData' })
        this.$emit('currentNav', index)
      } else if (index === 111) {
        this.$router.push({ name: 'ClubSuperShare' })
        this.$emit('currentNav', index)
      }
      document.documentElement.scrollTop = 0
    },

    releaseNews() {
      if (this.isOpenNewPages && this.num == 0) {
        this.$confirm('离开页面无法保存数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          eventBus.$emit('haveConfirm', 0)
          this.isOpenNewPages = false
          this.$router.push({ name: 'ClubReleaseNew' })
          this.$emit('currentNav', 0)
        }).catch(() => {
          this.isOpenNewPages = true
          // this.$message.warning({
          // 	showClose: true,
          // 	type: 'info',
          // 	message: '已取消跳转'
          // });
        })
      } else {
        this.$router.push({ name: 'ClubReleaseNew' })
        this.$emit('currentNav', 0)
      }
      this.checkedNeedTip(99)
      this.isShowReleaseNews = true
      this.isShowReleaseActivity = false
      this.isShowPersonFlag = false
      this.isShowDataFlag = false

    },

    releaseActivity() {

      this.checkedNeedTip(99)
      this.isShowReleaseActivity = true
      this.isShowReleaseNews = false
      this.isShowPersonFlag = false
      this.isShowDataFlag = false

      this.$alert('功能升级中，请先使用移动端发布活动', '提示', {
        confirmButtonText: '确定',
      })
      return
      // if (this.isOpenNewPages && this.num == -1) {
      //   this.$confirm('离开页面无法保存数据, 是否继续?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning',
      //     center: true
      //   }).then(() => {
      //     eventBus.$emit('haveConfirm', 0)
      //     this.isOpenNewPages = false
      //     if (this.$store.state.clubRoles.role === 3 || this.$store.state.clubRoles.canEditActivity) {
      //       this.$router.push({ name: 'ClubReleaseEvent' })
      //       this.$emit('currentNav', 0)
      //     } else {
      //       this.$alert('你暂无权限', '提示', {
      //         confirmButtonText: '确定',
      //       })
      //     }
      //   }).catch(() => {
      //     this.isOpenNewPages = true
      //     // this.$message.warning({
      //     // 	showClose: true,
      //     // 	type: 'info',
      //     // 	message: '已取消跳转'
      //     // });
      //   })
      // } else {
      //   if (this.$store.state.clubRoles.role === 3 || this.$store.state.clubRoles.canEditActivity) {
      //     this.$router.push({ name: 'ClubReleaseEvent' })
      //     this.$emit('currentNav', 0)
      //   } else {
      //     this.$alert('你暂无权限', '提示', {
      //       confirmButtonText: '确定',
      //     })
      //   }
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 215px;
  overflow-y: auto;
  max-height: calc(100vh - 96px);
}
.club-roles {
  width: 215px;
  background: #ffffff;
  border-radius: 5px;
  box-sizing: border-box;
  .release-button {
    .button-text {
      box-sizing: border-box;
      border-radius: 5px;
      font-size: 18px;
      color: #003d6e;
      padding: 0px 15px 0px 30px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      cursor: pointer;
      line-height: 50px;
      &:hover {
        background-color: #edf5ff;
      }
      &.activeBtn {
        background-color: #edf5ff;
      }
      .iconBtu {
        font-size: 18px;
        color: #003d6e;
        &.activeBtn {
          font-size: 20px;
          color: #0078ff;
          font-weight: 500;
        }
      }
      .text {
        font-size: 18px;
        margin-left: 9px;
        color: #003d6e;
        &.activeBtn {
          font-size: 18px;
          color: #0078ff;
          font-weight: 500;
        }
      }
    }
  }
  .release-button:nth-child(1) {
    padding-top: 15px;
  }
  .club-role-item {
    cursor: pointer;
    position: relative;
    padding: 15px 15px 15px 22px;
    box-sizing: border-box;
    color: #003d6e;
    font-size: 18px;
    display: flex;
    align-items: center;
    transition: all 0.5s;
    &:hover {
      background-color: #edf5ff;
      color: #1465cf;
    }
    .albums-icon {
      margin-right: 15px;
    }
    .role-text {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      .active {
        background-color: #0078ff;
        color: #003d6e !important;
      }
      .arrow-down {
        font-size: 18px;
        color: #c4c4c6;
        transition: all 0.5s;
      }
    }
  }
  .club-role-item:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: var(--clubHome);
    background-color: #dddddd;
    transform: scaleY(0.5);
    transform-origin: center top;
    z-index: 2;
  }
  .role-item {
    cursor: pointer;
    position: relative;
    padding: 15px 15px 15px 22px;
    box-sizing: border-box;
    color: #003d6e;
    font-size: 18px;
    display: flex;
    align-items: center;
    transition: all 0.5s;
    &:hover {
      background-color: #edf5ff;
    }
    .albums-icon {
      margin-right: 15px;
    }
    .role-text {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .arrow-down {
        font-size: 18px;
        color: #c4c4c6;
        transform: rotateX(0deg);
        transition: all 0.5s;
      }
    }
  }
  .role-item:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0.05rem;
    background-color: #dddddd;
    transform: scaleY(0.5);
    transform-origin: center top;
    z-index: 2;
  }
  .role-items {
    position: relative;
    padding: 15px 15px 15px 22px;
    box-sizing: border-box;
    color: #003d6e;
    font-size: 18px;
    display: flex;
    align-items: center;
    transition: all 0.5s;
    .albums-icon {
      margin-right: 15px;
    }
    .role-text {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .arrow-down {
        font-size: 18px;
        color: #c4c4c6;
        transform: rotateX(0deg);
        transition: all 0.5s;
      }
    }
    .role-text .active,
    role-text .arrow-down.active {
      background-color: #fff;
    }
  }
  .role-items:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0.05rem;
    background-color: #ddd;
    transform: scaleY(0.5);
    transform-origin: center top;
    z-index: 2;
  }
  .data-line {
    position: relative;
    font-size: 14px;
    &:hover {
      background-color: #edf5ff;
      color: #1465cf;
    }
    .albums-icon {
      color: #fff;
    }
  }
  .data-line:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0.05rem;
    background-color: #fff;
    transform: scaleY(0.5);
    transform-origin: center top;
    z-index: 2;
  }
  .role-item:hover {
    color: #1465cf;
    .role-text {
      color: #1465cf;
      .arrow-down {
        color: #1465cf;
      }
    }
  }
  .active {
    background-color: #edf5ff;
    color: #1465cf;
    font-size: 20px;
    /*子模块*/
    &.data-line {
      font-size: 14px;
    }
    .role-text {
      color: #1465cf;
      .arrow-down {
        color: #1465cf;
        transform: rotateX(180deg);
      }
    }
  }
  .active-icon {
    .role-text {
      .arrow-down {
        transform: rotateX(180deg);
      }
    }
  }
  .data-line.active {
    .role-text {
      // font-size: 20px;
      color: #0078ff;
      font-weight: 500;
    }
    .albums-icon {
      // color: #edf5ff;
      // font-size: 20px;
      color: #0078ff;
      font-weight: 500;
    }
  }
}
.club-roles .role-items .role-text .arrow-down.active {
  color: #0078ff;
  font-weight: 500;
}
</style>

<style lang="scss" scoped>
// .el-message-box .el-message-box__header .el-message-box__title span {
//   color: #000;
//   font-size: 17px;
//   font-weight: bold;
// }
:root {
  --clubHome: 0.05rem;
}
::-webkit-scrollbar {
  display: none;
  width: 8px;
  height: 0px;
  box-sizing: border-box;
}
</style>