<template>
  <div class="content-box">
    <div class="main">
      <div class="main__header">
        <div class="main__header__left">全部成员</div>
        <div class="main__header__right">
          <div class="main__header__right__text">
            成员{{ this.allmember }}人 / 粉丝{{ this.fansObj.list.length }}人
          </div>
        </div>
      </div>
      <div class="main__creator" v-if="ownList.length != 0">
        <div class="main__creator__avatar">
          <img-head :avatarUrl="ownList[0].avatarUrl" :Radius="50" :size="60"></img-head>	
        </div>
        <div class="main__creator__name">{{ ownList[0].nickname }}</div>
        <div class="main__creator__role">主理人</div>
      </div>
      <div class="main__group" v-if="manageList.length != 0">
        <div class="main__group__title">管理员（{{ manageList.length }}人）</div>
        <div class="main__group__list">
          <div class="main__group__member" v-for="(item, index) in manageList" :key="index">
            <div class="main__group__member__avatar">
              <img-head :avatarUrl="item.avatarUrl" :Radius="50" :size="60"></img-head>	
            </div>
            <div class="main__group__member__name">{{ item.nickname }}</div>
          </div>
        </div>
      </div>
      <div class="main__group" v-if="memberList.length != 0">
        <div class="main__group__title">成员（{{ memberList.length }}人）</div>
        <div class="main__group__list">
          <div class="main__group__member" v-for="(item, index) in memberList" :key="index">
            <div class="main__group__member__avatar">
              <img-head :avatarUrl="item.avatarUrl" :Radius="50" :size="60"></img-head>	
            </div>
            <div class="main__group__member__name">{{ item.nickname }}</div>
          </div>
        </div>
      </div>
      <div class="main__group" v-if="fansObj.list.length != 0">
        <div class="main__group__title">粉丝（{{ fansObj.list.length }}人）</div>
        <div class="main__group__list">
          <div class="main__group__member" v-for="(item, index) in fansObj.list" :key="index">
            <div class="main__group__member__avatar">
              <img-head :avatarUrl="item.avatarUrl" :Radius="50" :size="60"></img-head>	
            </div>
            <div class="main__group__member__name">{{ item.nickname }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 明细数据 -->
    <div class="data" v-if="$store.state.clubRoles.role === 3">
      <div class="data__header">
        <div class="data__header__title">明细数据</div>
        <div class="data__header__download" @click="selectMemberInfoList(true)">下载</div>
      </div>
      <!-- 表格 -->
      <div class="table">
        <el-table
          v-if="memberInfoList.length > 0"
          :data="memberInfoList"
          style="width: 100%"
          border
          :header-cell-style="headerCellStyle">
          <el-table-column
            fixed
            prop="avatarUrl"
            align="center"
            width="137">
            <template slot="header">
              <div class="table-header">用户</div>
            </template>
            <template slot-scope="scope">
              <div style="display: flex; align-items: center; overflow: hidden;">
                <el-image :src="$cosUrl + scope.row.avatarUrl" fit="cover" style="width: 35px; height: 35px; border-radius: 50%; overflow: hidden"></el-image>
                <p class="table-text" style="margin-left: 10px; max-width: 66px;">{{ scope.row.nickname }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="username"
            align="center"
            width="120">
            <template slot="header">
              <div class="table-header">拿铁号</div>
            </template>
            <template slot-scope="scope">
              <div class="table-text">{{ scope.row.username }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="joinTime"
            align="center"
            width="168">
            <template slot="header">
              <div class="table-header">加入时间</div>
            </template>
            <template slot-scope="scope">
              <div class="table-text">{{ scope.row.joinTime }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="firstJoinActivityTime"
            align="center"
            width="170">
            <template slot="header">
              <div class="table-header">第一次参加活动时间</div>
            </template>
            <template slot-scope="scope">
              <div class="table-text">{{ scope.row.firstJoinActivityTime }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="lastJoinActivityTime"
            align="center"
            width="170"
            class-name="group-list">
            <template slot="header">
              <div class="table-header">最近参加活动时间</div>
            </template>
            <template slot-scope="scope">
              <div class="table-text">{{ scope.row.lastJoinActivityTime }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="sevenDayJoinActivityNum"
            align="center"
            width="120"
            class-name="group-list">
            <template slot="header">
              <div class="table-header">7天参加活动次数</div>
            </template>
            <template slot-scope="scope">
              <div class="table-text">{{ scope.row.sevenDayJoinActivityNum }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="sevenDayJoinActivityRate"
            align="center"
            width="120"
            class-name="group-list">
            <template slot="header">
              <div class="table-header">7天内参与率</div>
            </template>
            <template slot-scope="scope">
              <div class="table-text">{{ scope.row.sevenDayJoinActivityRate }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="thirtyDayJoinActivityNum"
            align="center"
            width="120"
            class-name="group-list">
            <template slot="header">
              <div class="table-header">30天参加活动次数</div>
            </template>
            <template slot-scope="scope">
              <div class="table-text">{{ scope.row.thirtyDayJoinActivityNum }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="thirtyDayJoinActivityRate"
            align="center"
            width="120">
            <template slot="header">
              <div class="table-header">30天内参与率</div>
            </template>
            <template slot-scope="scope">
              <div class="table-text">{{ scope.row.thirtyDayJoinActivityRate }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="width: 100%; padding: 12px 0; display: flex; align-items: flex-end;">
        <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalCount"
          layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 提示弹窗 -->
    <tip-popup :title="this.titles" :isOpen="isOpen" @close="closePopup" @confirm="confirm"></tip-popup>
    
  </div>
</template>

<script>
import jpg from "@/assets/default-avatar.png"
import tipPopup from '../../components/tip-popup.vue'
import imgHead from '../../components/img-head.vue'
import Export2Excel from "@/utils/Export2Excel.js"
import { eventBus } from '../../main.js'
export default {
  data() {
    return {
      imgUrl: jpg,                                   // 加载不出的图片
      showOut: false,                                // 显示更多操作窗口
      clubRoles: 2,                                  // 俱乐部权限
      loadingMember: false,                          // 是否显示加载
      manageList: [],                                // 俱乐部管理员      
      memberList: [],                                // 俱乐部成员
      ownList: {},                                   // 俱乐部创建者
      groupMember: [],                               // 俱乐部分组信息
      allmember: 0,                                  // 俱乐部除粉丝以外人员总数        
      fansObj: {                                     // 俱乐部粉丝列表
        list: [],
        totalCount: 0,
        pageIndex: 1,
        nomore: false,
      },
      titles: '',                                     // 弹窗提示标题
      isOpen: false,                                  // 是否打开提示弹窗
      selectheirs: false,                             // 是否创建者权限转让
      buttonDisable: false,                           // 是否高亮转移按钮
      selectedid: false,                              // 记录已选中成员
      memberInfoList: [],
      pageIndex: 1,
      pageSize: 20,
      totalCount: 0,
      clubName: '',
      headerCellStyle: {
        height: '48px',
        backgroundColor: '#F3F5F8',
        padding: '4px 0',
      },
    }
  },
  components:{
    tipPopup,
    imgHead
  },

  beforeMount() {
    eventBus.$on("closeEditMode", () => {
      this.cancelRemove()
		})
  },

  async mounted() {
    this.searchMember()
    this.searchFans()
    this.getClubDetail()
    this.selectMemberInfoList()
    this.clubRoles = this.$store.state.clubRoles.role
    document.addEventListener('click', this.HiddenClick,false)
  },

  beforeDestroy(){
    document.removeEventListener('click', this.HiddenClick,false)
  },

  methods:{
    // 获取数据明细
    async selectMemberInfoList(download = false) {
      try {
        if(download) {
          let res = await this.$request.get(`/clubData/selectMemberInfoList?clubId=${this.$route.params.clubId}&dataType=1&pageIndex=1&pageSize=1`)
          this.exportExcel(res.page.list)
        } else {
          let res = await this.$request.get(`/clubData/selectMemberInfoList?clubId=${this.$route.params.clubId}&dataType=0&pageIndex=${this.pageIndex}&pageSize=${this.pageSize}`)
          this.totalCount = res.page.totalCount
          this.memberInfoList = res.page.list
        }
      } catch (error) {
        console.log('error:', error)
      }
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.selectMemberInfoList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.selectMemberInfoList()
    },
    // 点击页面事件 隐藏需要隐藏的区域
    HiddenClick () {
      if(this.showOut){
        this.showOut = false
      }
    },

    // 打开图片权限窗口
    openChoice() {
      this.showOut = !this.showOut
    },

    // 查询俱乐部创建者、管理员、成员
    async searchMember() {
      try {
        let data = {
          clubId: this.$route.params.clubId,
        }
        let res = await this.$request.post("/clubMemberInfo/selectMemberGroupList", data)
        if (res.code === 200) {         
          this.memberList = res.data.notGroupMember
          this.manageList = res.data.managers
          this.groupMember = res.data.groupMember
          this.allmember = res.data.memberCount 
          this.ownList[0] = res.data.create
        }
      } catch (e) {
				//
			}
    },

    // 查询俱乐部粉丝
    async searchFans() {
      this.loadingMember = true
      let data = {
        clubId: this.$route.params.clubId,
        pageIndex: this.fansObj.pageIndex,
        pageSize: 80,
      };
      try {
        let res = await this.$request.post("/clubFansInfo/selectAllFans", data);
        this.fansObj.list = res.page.list
        if(res.page.totalPage == res.page.pageIndex) {
          this.fansObj.nomore = true
        }
        else {
          this.fansObj.nomore = false
          this.fansObj.pageIndex++
        }
        this.canLoading = true
      } catch (e) {
        //TODO handle the exception
      }
      this.loadingMember = false
    },

    // 解散俱乐部功能 
    dissolutionClub() {
      if(this.clubRoles==2){
        this.$message.warning({
          showClose: true,
          message: "无法做此操作",
        })
        return
      }
			this.isOpen = true
      this.titles = "俱乐部解散之后无法恢复,确定要解散吗？"
    },

    // 俱乐部权限转让功能 
    permissionTransfer() {
      if(this.clubRoles==2 || this.allmember-1 ==0){
        this.$message.warning({
          duration: 10000,
          showClose: true,
          message: "无法做此操作",
        })
        return
      }

      this.selectheirs = true
      this.showOut = false
    },

    // 名单-判断是否已有选中
    judgmentChoice() {
      for(let items of this.manageList){
        if(items.checked){
          this.buttonDisable = true
          return
        }else{
          this.buttonDisable = false
        }
      }
      for(let item of this.groupMember){
        for(let items of item.groupMember){
          if(items.checked){
            this.buttonDisable = true
            return 
          }else{
            this.buttonDisable = false
          }    
        }
      }
      for(let items of this.memberList){
        if(items.checked){
          this.buttonDisable = true
          return
        }else{
          this.buttonDisable = false
        }
      }
    },    

    // 权限转让-点击管理员
    choiceAdmin(item) {
      if(this.selectedid == item.userId){
        item.checked = !item.checked
        this.judgmentChoice() 
        return
      }
      for(let items of this.manageList){
        if(items.checked){
          items.checked = false
        }
      }
      for(let item of this.groupMember){
        for(let items of item.groupMember){
          if(items.checked){
            items.checked = false
          }
        }  
      }
      for(let items of this.memberList){
        if(items.checked){
          items.checked = false
        }
      }
      item.checked = !item.checked
      this.judgmentChoice() 
      this.selectedid = item.userId
    },

    // 权限转让-点击group
    choiceGroup(items) {
      if(this.selectedid == items.userId){
        items.checked = !items.checked
        this.judgmentChoice() 
        return
      }
      for(let items of this.manageList){
        if(items.checked){
          items.checked = false
        }
      }
      for(let item of this.groupMember){
        for(let items of item.groupMember){
          if(items.checked){
            items.checked = false
          }
        }  
      }
      for(let items of this.memberList){
        if(items.checked){
          items.checked = false
        }
      }
      items.checked = !items.checked
      this.judgmentChoice() 
      this.selectedid = items.userId
    },

    // 权限转让-点击nogroup
    choiceNogroup(item) {
      if(this.selectedid == item.userId){
        item.checked = !item.checked
        this.judgmentChoice() 
        return
      }
      for(let items of this.manageList){
        if(items.checked){
          items.checked = false
        }
      }
      for(let item of this.groupMember){
        for(let items of item.groupMember){
          if(items.checked){
            items.checked = false
          }
        }  
      }
      for(let items of this.memberList){
        if(items.checked){
          items.checked = false
        }
      }
      item.checked = !item.checked
      this.judgmentChoice() 
      this.selectedid = item.userId
    },

    // 确认移除弹窗
    isRemoveAdmin() {
			this.isOpen = true
      this.titles = "您将失去俱乐部创建者身份,是否确定转让？"
    },

    // 取消权限转让人员选择
    cancelRemove() {
      this.selectheirs = false
      for(let items of this.manageList){
        if(items.checked){
          items.checked = false
        }
      }
      for(let item of this.groupMember){
        for(let items of item.groupMember){
          if(items.checked){
            items.checked = false
          }
        }
      }
      for(let items of this.memberList){
        if(items.checked){
          items.checked = false
        }
      }
      this.buttonDisable = false      
    },

    // 关闭删除动态弹窗
		closePopup() {
      this.isOpen = false
      this.showOut = false
    },

    // 确认创建者权限转让
    async transferCreator() {
			let data = {
        clubId:this.$route.params.clubId,
        userId:this.selectedid
			}
      try {
        let res = await this.$request.post('/clubMemberInfo/changeCreater', data)
        if(res.code === 200){
          this.$message.success({
            showClose: true,
            message: "转让成功",
          })
          this.selectheirs = false
          this.searchMember();
          this.searchFans();
        }
        if(res.code === 2015){
          this.$alert('该用户已经是其他俱乐部的创建者了','提示', {
            confirmButtonText: '确定',
          })	
        }
      } catch(e) {
        // 
      }
    },

    // 确认解散俱乐部
    async confirmDisbandClub() {
      try{
        let res = await this.$request.post('/clubInfo/disbandClub', {id:this.$route.params.clubId})
        if(res.code === 200){
          this.$message.success({
            showClose: true,
            message: "俱乐部已解散",
          })
          this.$router.push( {name: 'Home'} )
        }
        if(res.code === 2016){
          this.$alert('俱乐部需要在解散前30天内无任何活动或账','提示', {
            confirmButtonText: '确定',
          })	
        }
      }catch(e){
        // 
      }
    },

    // 确认回调事件
    confirm() {
      if(this.titles=="俱乐部解散之后无法恢复,确定要解散吗？"){
        this.confirmDisbandClub()
        this.isOpen = false
        this.titles = ''
        this.showOut = false
      }
      if(this.titles=="您将失去俱乐部创建者身份,是否确定转让？"){
        this.transferCreator()
        this.isOpen = false
        this.titles = ''
        this.showOut = false
      }
    },

    fullNumber(str) {
      if(parseInt(str) > 9) {
        return str
      } else {
        return `0${str}`
      }
    },

    // 获取俱乐部信息
    async getClubDetail() {
      try {
        let res = await this.$request.get(`/clubInfo/selectByClubId/${this.$route.params.clubId}`)
        this.clubName = res.data.clubName
      }
      catch (e) {
        //
      }
    },

    // 导出excel
    exportExcel(datalist) {
      let header = ['用户', '拿铁号', '加入时间', '第一次参加活动时间', '最近参加活动时间', '7天参加活动次数', '7天内参与率', '30天参加活动次数', '30天内参与率']
      let filterVal = ['nickname', 'username', 'joinTime', 'firstJoinActivityTime', 'lastJoinActivityTime', 'sevenDayJoinActivityNum', 'sevenDayJoinActivityRate', 'thirtyDayJoinActivityNum', 'thirtyDayJoinActivityRate']
      let list = (datalist || []).map((item) => {
        return {
          nickname: item.nickname,
          username: item.username,
          joinTime: item.joinTime,
          firstJoinActivityTime: item.firstJoinActivityTime,
          lastJoinActivityTime: item.lastJoinActivityTime,
          sevenDayJoinActivityNum: item.sevenDayJoinActivityNum,
          sevenDayJoinActivityRate: item.sevenDayJoinActivityRate,
          thirtyDayJoinActivityNum: item.thirtyDayJoinActivityNum,
          thirtyDayJoinActivityRate: item.thirtyDayJoinActivityRate
        }
      })
      let data = Export2Excel.formatJson(filterVal, list)
      data.unshift(header)
      let today = new Date()
      let year = today.getFullYear()
      let month = this.fullNumber(today.getMonth()+1)
      let date = this.fullNumber(today.getDate())
      let todayString = `${year}${month}${date}`
      Export2Excel.export_json_to_excel({
        multiHeader: [[]],
        filename: `【${this.clubName}】用户明细数据${todayString}`,
        sheetname: [`用户明细数据${todayString}`],
        data: [data],
        merges: []
      })
    }
    
  }
}
</script>

<style lang="scss" scoped>
  .content-box {
    width: 790px;
    min-height: 614px;
    box-sizing: border-box;
    position: relative;
    .top-card{
      position: relative;
      background-color: #fff;
      .card-title{
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        .title-left {
          font-size: 18px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          text-align: left;
          color: #000000;
          line-height: 25px;
        }
        .title-right {
          display: flex;
          align-items: center;
          .member-number{
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            text-align: center;
            color: #444444;
            line-height: 20px;
            margin-bottom: 5px;
            margin-right: 20px;
          }
          .more-roles{
            cursor: pointer;
            .images{
              width: 18px;
              height: 18px;
            }
          }
        }
      }
      .sign-out{
        cursor: pointer;
        width: 120px;
        height: 0;
        background: #fff;
        border-radius: 5px;
        position:absolute;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
        right: -52px;
        z-index: 99;
        //修改页面抖动
        top: 28px;
        box-sizing: border-box;
        transition: all 0.25s cubic-bezier(0.49, -0.3, 0.68, 1.23);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .edit-information{
          margin-bottom: 5px;
          color:#444444;
          .out-text{
            font-size: 14px;
          }
        }
        .edit-information:hover{
          color: #1465CF !important;
        }
        .delete-image{
          margin-bottom: 5px;
          color:#444444;
          .out-text{
            font-size: 14px;
          }
        }
        .delete-image:hover{
          color: #1465CF !important;
        }
        .delete-album{
          color:#444444;
          .out-text{
            font-size: 14px;
          }
        }
        .delete-album:hover{
          color: #1465CF !important;
        }
      }
      .show-out{
      height: 70px;
      box-shadow: 0px 2px 10px 0px undefined; 
      }
      .card-creater{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 15px;
        .creater-avatar{
          .images{
            width: 60px;
            height: 60px;
            border-radius: 50%; 
          }
        }
        .creater-name{
          margin-top: 5px;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          text-align: center;
          color: #444444;
          line-height: 22px;
        }
      }
    }
    .manage-card{
      padding-top: 10px;
      position: relative;
      .fans{
        margin-bottom: 20px;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: bold;
        color: #444444;
        line-height: 22px;
      }
      .fans-box{
        display: flex;
        flex-wrap: wrap;
        .fans-detail{
          display: flex;
          flex-direction: column;
          cursor: pointer;
          position: relative;
          height: 85px;
          width: 60px;
          margin-right: 20px;
          margin-bottom: 20px;
          .fans-image{
            .images{
              border-radius: 50%;
              width: 60px;
              height: 60px;
            }
          }
          .fans-name{
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            text-align: center;
            color: #444444;
            line-height: 20px;
            width: 50px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
          }
          .nochoices{
            position: absolute;
            left: 17px;
            top: 40px;
            width: 20px;
            height: 20px;
            z-index: 99;
            border-radius: 50%;
            cursor: pointer;
            .content{
              margin-top: 2.5px;
              margin-left: 29px;
              width: 18px;
              height: 18px;
              z-index: 99;
            }
            .contents{
              margin-top: -19px;
              margin-left: 28px;
              width: 18px;
              height: 18  px;
              border-radius: 50%;
              z-index: 99;
            }
          }
        }
      }
    }
    .manage-card:after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 0.05rem;
      background-color: #dddddd;
      transform: scaleY(0.5);
      transform-origin: center top;
      z-index: 2;
    }
    .group-box {
      .group-card{
        padding-top: 10px;   
        position: relative;  
        .fans{
          margin-bottom: 20px;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          color: #444444;
          line-height: 22px;
        }
        .fans-box{
          display: flex;
          .fans-detail{
            display: flex;
            flex-direction: column;
            cursor: pointer;
            position: relative;
            height: 85px;
            width: 60px;
            margin-right: 20px;
            margin-bottom: 20px;
            .fans-image{
              .images{
                border-radius: 50%;
                width: 60px;
                height: 60px;
              }
            }
            .fans-name{
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: bold;
              text-align: center;
              color: #444444;
              line-height: 20px;
              width: 50px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: center;
            }
            .nochoices{
              position: absolute;
              left: 17px;
              top: 40px;
              width: 20px;
              height: 20px;
              z-index: 99;
              border-radius: 50%;
              cursor: pointer;
              .content{
                margin-top: 2.5px;
                margin-left: 29px;
                width: 18px;
                height: 18px;
                z-index: 99;
              }
              .contents{
                margin-top: -19px;
                margin-left: 28px;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                z-index: 99;
              }
            }
          }
        }
      }
      .group-card:after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 0.05rem;
        background-color: #dddddd;
        transform: scaleY(0.5);
        transform-origin: center top;
        z-index: 2;
      }
    }
    .member-card{
      padding-top: 10px;
      position: relative;
      .fans{
        margin-bottom: 20px;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: bold;
        color: #444444;
        line-height: 22px;
      }
      .fans-box{
        display: flex;
        flex-wrap: wrap;
        .fans-detail{
          display: flex;
          flex-direction: column;
          position: relative;
          cursor: pointer;
          height: 85px;
          width: 60px;
          margin-right: 20px;
          margin-bottom: 20px;
          .fans-image{
            .images{
              border-radius: 50%;
              width: 60px;
              height: 60px;
            }
          }
          .fans-name{
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            text-align: center;
            color: #444444;
            line-height: 20px;
            width: 52px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
          }
          .nochoices{
            position: absolute;
            left: 17px;
            top: 40px;
            width: 20px;
            height: 20px;
            z-index: 99;
            border-radius: 50%;
            cursor: pointer;
            .content{
              margin-top: 2.5px;
              margin-left: 29px;
              width: 18px;
              height: 18px;
              z-index: 99;
            }
            .contents{
              margin-top: -19px;
              margin-left: 28px;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              z-index: 99;
            }
          }
        }
      }
    }
    .member-card:after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 0.05rem;
      background-color: #dddddd;
      transform: scaleY(0.5);
      transform-origin: center top;
      z-index: 2;
    }
    .fans-card{
      padding-top: 10px;
      position: relative;
      .fans{
        margin-bottom: 20px;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: bold;
        color: #444444;
        line-height: 22px;
      }
      .fans-box{
        display: flex;
        flex-wrap: wrap;
        .fans-detail{
          display: flex;
          flex-direction: column;
          cursor: pointer;
          position: relative;
          height: 85px;
          width: 60px;
          margin-right: 20px;
          margin-bottom: 20px;
          .fans-image{
            .images{
              border-radius: 50%;
              width: 60px;
              height: 60px;
            }
          }
          .fans-name{
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            text-align: center;
            color: #444444;
            line-height: 20px;
            width: 52px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
          }
          .nochoices{
            position: absolute;
            left: 17px;
            top: 40px;
            width: 20px;
            height: 20px;
            z-index: 99;
            border-radius: 50%;
            cursor: pointer;
            .content{
              margin-top: 2.5px;
              margin-left: 29px;
              width: 18px;
              height: 18px;
              z-index: 99;
            }
            .contents{
              margin-top: -19px;
              margin-left: 28px;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              z-index: 99;
            }
          }
        }
      }
    }
    .fans-card:after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 0.05rem;
      background-color: #dddddd;
      transform: scaleY(0.5);
      transform-origin: center top;
      z-index: 2;
    }
    .buttons-box{
      position: absolute;
      bottom: 0;
      left: 310px;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      .cancel{
          width: 100px;
          height: 30px;
          background: #F7F8FA;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;  
          .text{
            font-size: 14px;
            font-weight: 400;
            color: #444444;
            line-height: 20px;
            padding: 5px 35px;
          }
        }  
      .add{
          width: 100px;
          height: 30px;
          background:#EC4747;
          border-radius: 5px;
          cursor: pointer;  
          .text{
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
            padding: 5px 35px;
          }
        }
      .unadd{
          width: 100px;
          height: 30px;
          background: #A8A8A8;
          border-radius: 5px;  
          cursor: pointer;  
          .text{
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
            padding: 5px 35px;
          }
      }
    } 
  }
  .main {
    width: 790px;
    background-color: #FFF;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 15px;
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__left {
        font-size: 18px;
        font-weight: 500;
        color: #000;
      }
      &__right {
        display: flex;
        align-items: center;
        &__text {
          font-size: 14px;
          font-weight: 500;
          color: #444;
        }
        &__icon {
          width: 18px;
          height: 18px;
          margin-left: 20px;
        }
      }
    }
    &__creator {
      padding: 5px 0 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &__avatar {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
      }
      &__name {
        font-size: 14px;
        font-weight: 500;
        color: #444;
        margin-top: 5px;
      }
      &__role {
        font-size: 16px;
        font-weight: 500;
        color: #444;
        margin-top: 10px;
      }
    }
    &__group {
      border-top: 0.5px solid #ddd;
      padding: 9px 0 20px;
      &__title {
        font-size: 16px;
        font-weight: 500;
        color: #444;
      }
      &__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      &__member {
        width: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 23px;
        margin-top: 20px;
        &__avatar {
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
        &__name {
          width: 100%;
          font-size: 14px;
          font-weight: 500;
          color: #444;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          white-space: nowrap;
          text-align: center;
          margin-top: 5px;
        }
      }
    }
  }
  .table-text {
    color: #333;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .data {
    width: 790px;
    border-radius: 5px;
    background-color: #FFF;
    margin-top: 15px;
    padding: 0 20px;
    box-sizing: border-box;
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      &__title {
        font-size: 18px;
        font-weight: 500;
        color: #444;
      }
      &__download {
        width: 68px;
        height: 34px;
        border-radius: 5px;
        background-color: #1465CF;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
</style> 