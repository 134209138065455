<template>
  <div class="container">
    <!-- pc端 -->
    <div class="nav">
      <navbar-card :showLink="0" :showAvatar="true" :isIndex="true" @login="openLogin"></navbar-card>
    </div>
    <div class="main pc">
      <div class="pccircle1"></div>
      <div class="pccircle2"></div>
      <div class="pccircle3"></div>
      <div class="pccircle4"></div>
      <div class="bg">
        <img class="bg__url" src="../assets/index_bg.png">
      </div>
      <div class="slogan">
        <img class="slogan__url" src="../assets/slogan_pc.png">
      </div>
      <div class="group nopad">
        <img class="group__url" src="../assets/index_main.png">
      </div>
      <div class="pad">
        <div class="group" style="display: flex; align-items: center; justify-content: center;">
          <img class="group__url" src="../assets/phone_h5.png">
        </div>
      </div>
    </div>

    <div class="h5">
      <div class="circle1"></div>
      <div class="circle2"></div>
      <div class="circle3"></div>
      <div class="circle4"></div>
      <div class="slogan">
        <img class="slogan__url" src="../assets/slogan.png">
      </div>
      <div class="h5banner">
        <img class="h5banner__url" src="../assets/phone_h5.png">
        <a class="h5button" :href="urlScheme">立即体验</a>
      </div>
      <div class="mask"></div>
    </div>
    <!-- 联系我们 -->
    <div class="contact">
      <img class="contact__us" src="../assets/contact_us.png" alt="联系我们">
      <div class="contact__h5title">联系我们</div>
      <div class="contact__main">
        <div class="contact__item">
          <img class="contact__item__url" src="../assets/qrcode.png" alt="品牌合作">
          <p class="contact__item__text">品牌合作</p>
        </div>
        <div class="contact__item">
          <img class="contact__item__url" src="../assets/qrcode.png" alt="客服咨询">
          <p class="contact__item__text">客服咨询</p>
        </div>
      </div>
    </div>
    <!-- 群岛 -->
    <div class="saas">
      <img class="saas__url" src="../assets/qundao.png" alt="群岛/一站式社群服务工具">
      <a class="saas__button" href="https://domain.imlatte.com/" target="blank">免费体验</a>
    </div>
    <!-- 页脚 -->
    <div class="footer">
      <page-footer></page-footer>
    </div>
  </div>
</template>

<script>
import navbarCard from '../components/navbar-card.vue'
import pageFooter from '../components/Footer.vue'
export default {
  name: 'Index',
  components: {
    navbarCard,
    pageFooter,
  },
  data() {
    return {
      current: 0,
      showLogin: false,
      showIosCode: false,
      showAndroidCode: false,
      userInfo: null,					// 用户信息
      timestamp: 0,
      urlScheme: ''
    }
  },

  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'min-height:100%;margin:0;padding:0;background:  #fff;')
  },

  async created() {
    this.getUrlScheme()
    const token = window.localStorage.getItem('imlatte_token')
    if (token != null) {
      try {
        let res = await this.$request.post(`/userInfo/personalInfo`)
        this.$store.commit("SET_USER", res.data)
        this.userInfo = res.data
      } catch (e) {
        this.$store.commit('CLEAR_STORAGE')
      }
    } else {
      //
    }
  },

  methods: {
    async getUrlScheme() {
      try {
        let res = await this.$login.get('/others/urlScheme')
        this.urlScheme = res.data
      } catch (error) {
        // console.log('报错', error)
      }
    },
    linkTo(selector) {
      //模拟锚点跳转
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth"
      })
    },

    clickTabs(index) {
      this.current = index
      if (index === 1) {
        this.$router.push({ name: 'Moment' })
      }
      else if (index === 2) {
        if (window.localStorage.getItem('imlatte_token')) {
          this.$router.push({ name: 'ClubRecommendation' })
        } else {
          this.openLogin()
        }
      }
    },

    closeLogin() {
      this.showLogin = false
      this.current = 0
    },

    openLogin() {
      this.showLogin = true
    },

    showAndroidDownload() {
      this.showAndroidCode = true
      let nowTimestamp = new Date().getTime()
      if ((nowTimestamp - this.timestamp) > 60000)
        this.timestamp = nowTimestamp
    }
  }

}
</script>

<style lang="scss" scoped>
  .container {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .nav {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1999;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
  }
  .main {
    height: calc(100 * var(--vh) - 80px);
    padding-top: 80px;
    position: relative;
    overflow: hidden;
    .pccircle1 {
      width: 116px;
      height: 116px;
      border-radius: 50%;
      background: linear-gradient(92.64deg, rgba(255, 255, 255, 0.5) -1%, rgba(194, 215, 255, 0.5) 24.04%, rgba(105, 155, 248, 0.5) 75.8%);
      opacity: 0.2;
      filter: blur(6px);
      position: absolute;
      top: 50px;
      left: 336px;
    }
    .pccircle2 {
      width: 641px;
      height: 641px;
      border-radius: 50%;
      background: linear-gradient(92.64deg, #FFFFFF -1%, #C2D7FF 24.04%, #699BF8 75.8%);
      opacity: 0.2;
      position: absolute;
      top: -105px;
      right: -177px;
    }
    .pccircle3 {
      width: 645px;
      height: 645px;
      border-radius: 50%;
      background: linear-gradient(92.64deg, #FFFFFF -1%, #C2D7FF 24.04%, #699BF8 75.8%);
      opacity: 0.2;
      transform: rotate(135deg);
      position: absolute;
      top: 175px;
      left: -320px;
    }
    .pccircle4 {
      width: 669px;
      height: 669px;
      border-radius: 50%;
      background: linear-gradient(92.64deg, #FFFFFF -1%, #FFECBF 24.04%, #FFB6C8 75.8%);
      opacity: 0.2;
      filter: blur(14.5px);
      position: absolute;
      top: 744px;
      right: -67px;
    }
    .bg {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      transform: translateY(calc(-8 * var(--vh)));
      &__url {
        width: 100%;
        object-fit: contain;
        display: block;
      }
    }
  }
  .group {
    height: calc(60 * var(--vh));
    max-height: 1000px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &__url {
      height: 100%;
      object-fit: contain;
      position: absolute;
      display: block;
    }
  }
  .contact {
    width: 100%;
    height: 180px;
    background: #003D6E;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    &__main {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__us {
      position: absolute;
      top: 70px;
      transform: translateX(-200%);
      width: 108px;
      object-fit: contain;
    }
    &__h5title {
      font-size: 20px;
      font-weight: bold;
      color: white;
      display: none;
      margin-bottom: 20px;
    }
    &__item {
      margin-right: 40px;
      &:last-child {
        margin-right: 0;
      }
      &__url {
        width: 80px;
        height: 80px;
        display: block;
      }
      &__text {
        font-size: 18px;
        color: white;
        margin-top: 14px;
        text-align: center;
      }
    }
  }
  .pad {
    display: none;
  }
  .h5 {
    position: relative;
    display: none;
    height: calc(100*var(--vh) - 80px);
    .circle1 {
      width: 73px;
      height: 73px;
      border-radius: 50%;
      background: linear-gradient(92.64deg, rgba(255, 255, 255, 0.5) -1%, rgba(194, 215, 255, 0.5) 24.04%, rgba(105, 155, 248, 0.5) 75.8%);
      opacity: 0.2;
      filter: blur(6px);
      position: absolute;
      top: 96px;
      left: -36px;
    }
    .circle2 {
      width: 398px;
      height: 398px;
      border-radius: 50%;
      background: linear-gradient(92.64deg, #FFFFFF -1%, #C2D7FF 24.04%, #699BF8 75.8%);
      opacity: 0.2;
      position: absolute;
      top: -70px;
      right: -194px;
    }
    .circle3 {
      width: 400px;
      height: 400px;
      border-radius: 50%;
      background: linear-gradient(92.64deg, #FFFFFF -1%, #C2D7FF 24.04%, #699BF8 75.8%);
      opacity: 0.2;
      transform: rotate(135deg);
      position: absolute;
      top: 300px;
      left: -200px;
    }
    .circle4 {
      width: 415px;
      height: 415px;
      border-radius: 50%;
      background: linear-gradient(92.64deg, #FFFFFF -1%, #FFECBF 24.04%, #FFB6C8 75.8%);
      opacity: 0.2;
      filter: blur(15px);
      position: absolute;
      top: 526px;
      right: -200px;
    }
  }
  .slogan {
    height: calc(18 * var(--vh));
    margin-top: calc(7 * var(--vh));
    margin-bottom: calc(2.6 * var(--vh));
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &__url {
      height: 100%;
      object-fit: contain;
      display: block;
    }
  }
  .h5banner {
    height: calc(67.5 * var(--vh));
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &__url {
      height: 100%;
      object-fit: contain;
      display: block;
    }
  }
  .h5button {
    min-width: 318px;
    width: calc(39 * var(--vh));
    height: calc(5.5 * var(--vh));
    border-radius: calc(5.5 * var(--vh));
    background: #003D6E;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(2.5 * var(--vh));
    font-weight: bold;
    color: white;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
  }
  .mask {
    width: 100%;
    height: calc(20 * var(--vh));
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50%);
    position: absolute;
    bottom: 0;
  }
  .saas {
    width: 100%;
    height: 110px;
    background: white;
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    box-sizing: border-box;
    position: relative;
    &__url {
      width: 108px;
      object-fit: contain;
      display: block;
    }
    &__button {
      width: 118px;
      height: 32px;
      border-radius: 32px;
      background: linear-gradient(80.72deg, #0078FF -31.46%, #84AEFF 145.61%);
      font-size: 16px;
      font-weight: bold;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .footer {
    position: relative;
  }

  /*响应式兼容样式*/

  /*pad样式*/
  @media screen and (max-width: 1010px) and (min-width: 730px) {
    .pad {
      display: block;
    }
    .nopad {
      display: none;
    }
  }
  /*手机端样式*/
  @media screen and (max-width: 730px) {
    .container {
      overflow: hidden;
    }
    .nav {
      position: relative;
    }
    .h5 {
      display: block;
    }
    .saas {
      display: flex;
    }
    .pc {
      display: none;
    }
    .contact {
      height: 210px;
      &__us {
        display: none;
      }
      &__h5title {
        display: block;
      }
      &__item {
        &__url {
          width: 88px;
          height: 88px;
        }
        &__text {
          font-size: 14px;
        }
      }
    }
    .slogan {
      height: calc(15 * var(--vh));
      margin-top: 0;
      margin-bottom: calc(3.6 * var(--vh));
    }
  }
</style>