<template>
  <div class="content-box">
    <div class="club-temem-page" v-if="!openAlbums">
      <div class="top">
        <div class="title">相册</div>
        <div class="ablum" v-loading="loadingTheme" v-infinite-scroll="selectClubPhotoTheme" style="overflow:auto">
          <div class="img-avatars" @click="createTheme" v-if="isOwn">
            <icon-font name="add" size="48px" color="#ddd"></icon-font>
            <el-image style="width: 32px; height: 32px" :src="require('@/assets/add.png')" fit="cover"></el-image>
          </div>
          <div class="ablum-detail" v-for="(item,index) in photoThemeObj.list" :key="item.id" @click="clickTheme(item)">
            <div class="ablum-img">
              <el-image class="club-avatar" v-if="item.themePhoto" fit="cover" :src="$cosUrl + item.themePhoto">
                <div slot="error" class="image-slot">
                  <el-image :src="errorImg"></el-image>
                </div>
              </el-image>
              <div class="ablumn-null" v-else>
                <icon-font name="photo-null" size="78px" color="#eee"></icon-font>
              </div>
            </div>
            <div class="more-roles" v-if="isOwn" @click.stop="clickThemeMore(index)">
              <el-image :src="require('@/assets/more-roles.png')" fit="cover"></el-image>
            </div>
            <div class="sign-outs" :class="item.isHide ? 'show-outs' : ''">
              <div class="edit-information" @click.stop="editProfile(item, index)">编辑资料</div>
              <div class="edit-information" @click.stop="deleteAlbum(1, item, index)">删除相册</div>
            </div>
            <div class="ablum-name">
              {{item.themeName ? item.themeName : "相册主题"}}
            </div>
            <div class="ablum-count">
              {{item.themeSize}}张照片
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="club-temem-page" v-else>
      <div class="back-button" v-if="!isEdit && !isChangeCover" @click="goback">
        <icon-font class="icon" name="arrow-left" size="13px" color="#1465CF"></icon-font>
        <div class="button-text">返回</div>
      </div>
      <div class="theme-detail">
        <div class="theme-detail-left">
          <div class="theme-name">{{ currentTheme.themeName }}</div>
          <div class="theme-content" v-if="currentTheme.content">{{ currentTheme.content }}</div>
          <div class="theme-address" v-if="currentTheme.address">地址：{{ currentTheme.address.indexOf("|") != -1 ? currentTheme.address.split("|")[1] : currentTheme.address }}</div>
          <div class="theme-count">{{ currentThemeSize }}张照片</div>
        </div>
        <div class="theme-detail-right">
          <el-image class="theme-cover" v-if="currentTheme.themePhoto" :src="$cosUrl + currentTheme.themePhoto" fit="cover"></el-image>
        </div>
        <div class="theme-edit" v-if="isOwn && !isEdit && !isChangeCover" @click.stop="openChoice">
          <el-image class="more-roles" :src="require('@/assets/more-roles.png')" fit="cover"></el-image>
        </div>
        <div class="role-pop" :class="showOut ? 'show-role-pop' : ''" :style="{height: !showOut ? '0' : currentThemeSize > 0 ? '130px' : '65px'}">
          <div class="edit-information" @click.stop="editProfile(currentTheme)">编辑相册</div>
          <div class="edit-information" v-if="currentThemeSize > 0" @click="changeCover">封面更换</div>
          <div class="edit-information" v-if="currentThemeSize > 0" @click.stop="deleteImage">删除照片</div>
          <div class="edit-information" @click.stop="deleteAlbum(2, currentTheme)">删除相册</div>
        </div>
      </div>

      <!-- 浏览图片 -->
      <div class="bottom">
        <div class="picture-list" v-loading="loadingPicture" v-infinite-scroll="selectThemePicture" style="overflow:auto">
          <div class="img-avatar" @click="chooseAvatar" v-if="isOwn && !isEdit && !isChangeCover">
            <el-image class="upload" :src="require('@/assets/add.png')" fit="cover"></el-image>
          </div>
          <div class="ablum-img" v-for="(item, index) in themePictureObj.list" :key="index" @click="clickPhoto(index, item.id)">
            <div class="choice-circel" v-if="isEdit || isChangeCover">
              <el-image class="more-roles" v-if="item.checked || selectCoverId === item.id" :src="require('@/assets/choicePhoto.png')" fit="cover"></el-image>
              <el-image class="more-roles" v-else :src="require('@/assets/unchoicePhoto.png')" fit="cover"></el-image>
            </div>
            <el-image class="club-avatar" fit="cover" :src="item.url" :preview-src-list="isEdit || isChangeCover ? [] : srcList">
              <div slot="error" class="image-slot">
                <el-image :src="errorImg"></el-image>
              </div>
            </el-image>
          </div>
        </div>
        <div class="button-box" v-if="isEdit || isChangeCover">
          <div class="default-btn cancel" @click="cancelDelect">取消</div>
          <div class="default-btn" :class="selectCount > 0 ? 'del' : ''" v-if="isEdit" @click="delDialog">删除</div>
          <div class="default-btn" :class="selectCount > 0 ? 'confirm' : ''" v-if="isChangeCover" @click="postEditPhotoTheme">确定</div>
        </div>
      </div>
    </div>

    <input v-show="false" accept="image/jpg,image/jpeg,image/png,image/gif" multiple type="file" id="uploadIMG" @change="photoUploadChange($event)" ref="inputImage" />

    <!-- 新增编辑相册弹窗 -->
    <div class="login" v-if="showEditPop">
      <div class="login-wrap">
        <div class="title-line">
          <h1 class="login-text">{{ editType === 1 ? '新建相册' : '编辑相册' }}</h1>
          <icon-font class="close" name="close" color="#999999" size="16px" @click="closeEditPop"></icon-font>
        </div>
        <div class="login-area">
          <div class="edit-name-line">
            <div class="album-theme">
              <el-input class="theme-input" type="text" :value="editThemeData.themeName" @input="themelenMin" placeholder="相册主题"></el-input>
            </div>
            <span class="text-statistics">{{ themeStatistics }}/10</span>
          </div>
          <div class="edit-content-line">
            <el-input type="textarea" :value="editThemeData.content" resize='none' class="input-content" @input="introlenMin" placeholder="相册介绍（选填）"></el-input>
            <div class="text-statistic">{{ introStatistics }}/100</div>
          </div>
          <div class="club-address">
            <div class="address-text">地址</div>
            <div class="input-dress" @click="showLocationSearchList = !showLocationSearchList" @keyup.enter="searchAddress">
              <input class="input-address" type="text" v-model="editThemeData.showAddress" placeholder="添加位置（选填）" />
              <div class="searchLocations">
                <search-location :showLocationSearchList="showLocationSearchList" :address="editThemeData.showAddress" :enterEvents="enterEvents" @show="shows" @chooseAddress="chooseAddress"></search-location>
              </div>
            </div>
          </div>
        </div>
        <div class="submit-area">
          <button class="submit" type="button" @click="submitAblum">保存</button>
        </div>
      </div>
    </div>

    <!-- 提示弹窗 -->
    <tip-popup :title="this.titles" :isOpen="isOpenDialog" @close="closePopup" @confirm="confirm"></tip-popup>

  </div>
</template>

<script>
import errorImg from "@/assets/error-img.png"
import iconFont from '../../components/IconFont.vue'
import { eventBus } from '../../main.js'
import tipPopup from '../../components/tip-popup.vue'
import searchLocation from '../../components/searchLocation.vue'
const base64 = require('../../utils/base64.js')
export default {
  data() {
    return {
      errorImg: errorImg,
      currentThemeIndex: -1,                  // 判断是否关闭操作弹窗
      titles: "",                             // 发送弹窗显示内容
      isOpenDialog: false,                    // 判断是否打开确认弹窗
      type: 0,                                // 1是相册,2是相册照片
      showEditPop: false,                     // 显示创建、编辑相册资料弹框
      openAlbums: false,                      // 是否打开相册集
      photoThemeObj: {                        // 相册列表
        pageIndex: 1,
        nomore: false,
        list: [],
      },
      themePictureObj: {                      // 相册照片列表
        pageIndex: 1,
        nomore: false,
        list: []
      },
      currentThemeSize: 0,                    // 当前选择的相册的照片数量
      currentTheme: {                         // 当前选择的相册的基本信息
        themeName: ''
      },
      selectCount: 0,                         // 删除、更换封面时，选中的照片数量
      selectCoverId: null,                    // 当前选中的封面图id
      isThemeEmpty: false,                    // 相册是否为空
      loadingTheme: false,                    // 正在请求相册列表接口
      loadingPicture: false,                  // 正在请求相册照片列表接口
      srcList: [],                             // 预览照片的url列表
      isEdit: false,                          // 进入删除相册照片模式
      isChangeCover: false,                   // 进入更换相册封面模式
      isOwn: false,                           // 是否拥有俱乐部编辑相册权限
      showOut: false,                          // 打开图片相册操作权限弹窗
      showLocationSearchList: false,          // 展示搜索组件  
      enterEvents: false,                     // enter事件
      editType: 1,                            // 1:创建相册 2:编辑相册
      themeStatistics: 0,                     // 相册主题文字统计
      introStatistics: 0,                     // 相册介绍文字统计
      themeTitleLen: null,                    // 动态标题长度
      themeContentLen: null,                  // 动态内容长度
      deleteThemeData: null,                  // 即将删除的相册信息
      deleteThemeType: 1,                     // 1:相册列表页删除   2:相册详情页删除
      editThemeData: {                        // 存放编辑相册信息时的数据
        themeName: '',
        content: '',
        showAddress: ''
      },
      clubName: ''
    }
  },

  created() {
    eventBus.$on("closeEditMode", () => {
      this.cancelDelect()
    })
    this.getClubDetail()
    this.selectClubPhotoTheme()
  },

  mounted() {
    document.addEventListener('click', this.HiddenClick, false)
  },

  beforeDestroy() {
    document.removeEventListener('click', this.HiddenClick, false)
    eventBus.$off("closeEditMode")
  },

  watch: {
    '$store.state.clubRoles': function() {
      this.isOwn = this.$store.state.clubRoles.canEditTheme
    },
  },

  beforeMount() {
    if (this.$store.state.clubRoles) {
      let data = this.$store.state.clubRoles
      this.isOwn = data.canEditTheme
    }
  },

  components: {
    iconFont,
    tipPopup,
    searchLocation
  },

  methods: {
    HiddenClick() {
      if (this.showOut) {
        this.showOut = false
      }
      if (this.currentThemeIndex !== -1) {
        this.photoThemeObj.list[this.currentThemeIndex].isHide = false
      }
    },

    async getClubDetail() {
      try {
        let res = await this.$request.get(`/clubInfo/selectByClubId/${this.$route.params.clubId}`)
        this.clubName = res.data.clubName
      }
      catch (e) {
        //
      }
    },

    // 查询相册列表
    async selectClubPhotoTheme(isRefresh = false) {
      if (isRefresh) {
        this.photoThemeObj.pageIndex = 1
        this.photoThemeObj.nomore = false
      }
      if (this.loadingTheme || this.photoThemeObj.nomore) {
        return
      } else {
        this.loadingTheme = true
      }
      try {
        let data = {
          clubId: this.$route.params.clubId,
          pageIndex: this.photoThemeObj.pageIndex,
          pageSize: 20,
        }
        let res = await this.$request.post(`/clubPhotoTheme/selectClubPhotoTheme`, data)
        if (res.page.totalCount == 0) {
          this.isThemeEmpty = true
          this.photoThemeObj.list = null
          this.themePictureObj.list = null
          eventBus.$emit('changeAblum', 1)
        }
        else {
          this.isThemeEmpty = false
          if (res.page.totalPage == res.page.pageIndex) {
            this.photoThemeObj.nomore = true
          } else {
            this.photoThemeObj.nomore = false
            this.photoThemeObj.pageIndex++
          }
          let arr = []
          for (let item of res.page.list) {
            item.isHide = false
            arr.push(item)
          }
          if (isRefresh) {
            this.photoThemeObj.list = arr
          } else {
            this.photoThemeObj.list = [...this.photoThemeObj.list, ...arr]
          }
          this.$store.commit("SET_ABLUMSTATE", true)
          eventBus.$emit('changeAblum', 1)
        }
      } catch (e) {
        //
      }
      this.loadingTheme = false
    },

    // 点击相册集
    clickTheme(data) {
      // 初始化
      this.themePictureObj = {
        pageIndex: 1,
        nomore: false,
        list: []
      }
      this.showOut = false
      this.openAlbums = true
      this.currentTheme = data
      this.srcList = []
      if (data.address && data.address.indexOf("|") != -1) {
        this.currentTheme.showAddress = data.address.split('|')[1]
      } else {
        this.currentTheme.showAddress = data.address
      }
      for (let item of this.photoThemeObj.list) {
        if (item.isHide) {
          item.isHide = false
        }
      }
    },

    // 多选照片
    clickPhoto(index, id) {
      if (this.isEdit) {
        this.themePictureObj.list[index].checked = !this.themePictureObj.list[index].checked
        if (this.themePictureObj.list[index].checked) {
          this.selectCount++
        } else {
          this.selectCount--
        }
      } else {
        if (this.selectCoverId === id) {
          this.selectCoverId = null
          this.selectCount--
        } else {
          this.selectCoverId = id
          this.selectCount++
        }
      }
    },

    // 查找相册集对应的照片
    async selectThemePicture(isRefresh = false) {
      if (isRefresh) {
        this.themePictureObj.pageIndex = 1
        this.themePictureObj.nomore = false
      }
      if (this.loadingPicture || this.themePictureObj.nomore) {
        return
      } else {
        this.loadingPicture = true
      }
      try {
        let data = {
          id: this.currentTheme.id,
          pageIndex: this.themePictureObj.pageIndex,
          pageSize: 20,
        }
        let res = await this.$request.post("/clubPhotoUrl/selectPhotoByThemeId", data)
        if (res.page.totalCount == 0) {
          this.themePictureObj.list = null
          this.currentThemeSize = 0
        } else {
          let arr = []
          let arrs = []
          for (let item of res.page.list) {
            item.url = this.$cosUrl + item.url
            item.url = this.addWatermark(item)
            this.$set(item, "checked", false)
            arr.push(item.url)
            arrs.push(item)
          }
          if (isRefresh) {
            this.srcList = arr
            this.themePictureObj.list = arrs
          } else {
            this.srcList = [...this.srcList, ...arr]
            this.themePictureObj.list = [...this.themePictureObj.list, ...arrs]
          }
          this.currentThemeSize = res.page.totalCount
          if (res.page.totalPage == res.page.pageIndex) {
            this.themePictureObj.nomore = true
          } else {
            this.themePictureObj.nomore = false
            this.themePictureObj.pageIndex++
          }
        }
      } catch (error) {
        // console.log(error)
      }
      this.loadingPicture = false
    },

    addWatermark(data) {
      let regStr = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig
      // 图片水印
      let logo = this.$cos + '/default/WATERMAKE_two.png'
      let marklogo = base64.securityBase64(base64.encode(logo))
      // 文字水印，俱乐部名称需要把emoji表情替换为空格
      let marktext = base64.securityBase64(base64.encode(this.clubName.replace(regStr, ' ')))
      let newUrl = ''
      if (data.fileStatus == 2) {
        // 图片被封禁
        newUrl = this.$cosUrl + '/default/VIOLATION_DEFAULT_IMAGE.png'
      } else {
        if (data.width) {
          // 图片携带原始宽高，展示水印
          let dx = 0.027 * data.width
          let dy = 0.072 * data.width
          let fontSize = 0.035 * data.width
          // 图片水印
          let ImageWatermark = `watermark/1/image/${marklogo}/gravity/southeast/scatype/1/spcent/150/dissolve/100/dx/${dx}/dy/${dx}`
          // 文字水印
          let textWatermark = `watermark/2/text/${marktext}/fontsize/${fontSize}/fill/I0ZGRkZGRg==/gravity/southeast/dx/${dx}/dy/${dy}`
          newUrl = `${data.url}?${ImageWatermark}|${textWatermark}`
        } else {
          // 图片未携带原始宽高，不展示水印
          newUrl = data.url
        }
      }
      return newUrl
    },

    // 点击相册右上角的更多按钮
    clickThemeMore(index) {
      if (this.currentThemeIndex != index) {
        if (this.currentThemeIndex !== -1) {
          this.photoThemeObj.list[this.currentThemeIndex].isHide = false
        }
        this.photoThemeObj.list[index].isHide = !this.photoThemeObj.list[index].isHide
        this.currentThemeIndex = index
      } else {
        this.photoThemeObj.list[index].isHide = !this.photoThemeObj.list[index].isHide
      }
    },

    // 查找俱乐部相册主题信息
    async getClubPhotoThemeById() {
      try {
        let res = await this.$request.get(`/clubPhotoTheme/getClubPhotoThemeById/${this.currentTheme.id}`)
        this.currentTheme = res.data
      } catch (error) {
        // 
      }
    },

    // 更换相册封面
    async postEditPhotoTheme() {
      if (this.selectCoverId == null) {
        return
      }
      try {
        let data = {
          "id": this.currentTheme.id,
          "clubId": this.currentTheme.clubId,
          "themeName": this.currentTheme.themeName,
          "content": this.currentTheme.content,
          "country": this.currentTheme.country,
          "province": this.currentTheme.province,
          "city": this.currentTheme.city,
          "area": this.currentTheme.area,
          "address": this.currentTheme.address,
          "longitude": this.currentTheme.longitude ? this.currentTheme.longitude : '',
          "latitude": this.currentTheme.latitude ? this.currentTheme.latitude : '',
          "coverPhotoId": this.selectCoverId
        }
        await this.$request.post('/clubPhotoTheme/editPhotoTheme', data)
        this.getClubPhotoThemeById()
        this.selectClubPhotoTheme(true)
        this.isChangeCover = false
        this.isEdit = false
      } catch (error) {
        // console.log('error:', error)
      }
    },

    // 打开确认删除照片的弹框
    async delDialog() {
      if (this.selectCount > 0) {
        this.titles = "你确定要删除照片吗？"       // 删除弹窗标题内容
        this.type = 2                          // 删除相册集标志2
        this.isOpenDialog = true               // 打开确定弹窗
      }
    },

    //取消相册编辑
    cancelDelect() {
      this.isEdit = false
      this.isChangeCover = false
    },

    // 选择图片
    chooseAvatar() {
      this.$refs.inputImage.click()
    },

    // 获取图片信息
    fileImgInfo(url) {
      return new Promise((resolve) => {
        let img = new Image()
        img.src = url
        img.onload = function() {
          let urlHeight = 750 * (img.height / img.width)
          return resolve({
            width: img.width,
            height: img.height,
            urlHeight: urlHeight
          })
        }
      })
    },

    // 上传照片
    async photoUploadChange(event) {
      try {
        let files = event.target.files
        await this.$credential(5, this.$route.params.clubId)
        let pictureArr = []
        // 循环图片列表获取服务器图片地址列表
        for (let item of files) {
          let fileUrl = URL.createObjectURL(item)
          let imageInfo = await this.fileImgInfo(fileUrl)
          let uploadPath = await this.$uploadFile(5, item)
          pictureArr.push({
            url: uploadPath,
            width: imageInfo.width,
            height: imageInfo.height,
            urlWidth: 750,
            urlHeight: imageInfo.urlHeight,
            defaultUrl: 2,
          })
        }
        let data = {
          clubId: this.$route.params.clubId,    //俱乐部ID
          id: this.currentTheme.id,             //俱乐部相册ID
          urlRequestVoList: pictureArr          //将要上传的图片列表
        }
        let res = await this.$request.post('/clubPhotoUrl/addPhoto', data)
        if (res.code === 200) {
          this.$message.success({
            showClose: true,
            message: '上传成功'
          })
          this.getClubPhotoThemeById()
          this.selectThemePicture(true)
          this.selectClubPhotoTheme(true)
        }
      } catch (error) {
        // console.log('选择图片出错',error)
      }
      event.srcElement.value = ''
    },

    // 打开图片权限窗口
    openChoice() {
      this.showOut = !this.showOut
    },

    // 打开编辑相册资料弹框
    editProfile(item, index) {
      this.showOut = false                                        // 关闭权限小弹窗
      this.editType = 2
      this.editThemeData = JSON.parse(JSON.stringify(item))
      this.editThemeData.showAddress = !this.editThemeData.address ? '' : this.editThemeData.address.indexOf('|') !== -1 ? this.editThemeData.address.split('|')[1] : this.editThemeData.address
      this.themeStatistics = Math.ceil(parseFloat(unescape(encodeURIComponent(this.editThemeData.themeName)).split("").map(val => val.charCodeAt()).length) / 3)
      this.introStatistics = Math.ceil(parseFloat(unescape(encodeURIComponent(this.editThemeData.content)).split("").map(val => val.charCodeAt()).length) / 3)
      this.showEditPop = true
      if (index >= 0) {
        this.photoThemeObj.list[index].isHide = false
      }
    },

    //删除相册集
    async deleteAlbum(delType, theme, index) {
      // delType --> 1:相册列表页删除   2:相册详情页删除
      this.titles = "你确定要删除此相册吗？"      // 删除弹窗标题内容
      this.showOut = !this.showOut            // 打开图片相册操作权限弹窗
      this.isOpenDialog = true                // 打开确定弹窗
      this.type = 1                           // 删除相册集标志
      this.deleteThemeData = theme            // 即将删除的相册信息
      this.deleteThemeType = delType          // 删除相册的入口
      if (index >= 0) {
        this.photoThemeObj.list[index].isHide = false
      }
    },

    // 选择封面
    changeCover() {
      this.cleanPhotoStatus()
      this.showOut = false
      this.isEdit = false
      this.isChangeCover = true
      this.selectCount = 0
      this.selectCoverId = null
    },

    // 删除照片
    deleteImage() {
      this.cleanPhotoStatus()
      this.showOut = false
      this.isChangeCover = false
      this.isEdit = true
      this.selectCount = 0
      this.selectCoverId = null
    },

    // 取消照片选中状态
    cleanPhotoStatus() {
      for (let i = 0; i < this.themePictureObj.list.length; i++) {
        this.themePictureObj.list[i].checked = false
      }
    },

    // 回退
    goback() {
      this.openAlbums = false
    },

    // 打开新建相册窗口
    createTheme() {
      this.editType = 1
      this.showEditPop = true
      this.editThemeData = {
        themeName: '',
        content: '',
        address: '',
        showAddress: ''
      }
    },

    // 关闭二次确认弹框
    closePopup() {
      this.isOpenDialog = false
    },

    // 删除相册 需要判断是在相册列表直接删除相册还是在相册详情中删除的
    async postDeletePhotoTheme() {
      try {
        await this.$request.post('/clubPhotoTheme/deletePhotoTheme', this.deleteThemeData)
        this.$message.success({
          showClose: true,
          message: '删除成功'
        })
        this.isOpenDialog = false
        if (this.deleteThemeType === 2) {
          this.openAlbums = false
        }
        this.selectClubPhotoTheme(true)
      } catch (error) {
        // console.log('error', error)
      }
    },

    // 删除照片
    async postDeletePhoto() {
      try {
        let deleteArr = []
        for (let item of this.themePictureObj.list) {
          if (item.checked) {
            deleteArr.push(item.id)
          }
        }
        let data = {
          clubId: this.$route.params.clubId,
          id: this.currentTheme.id,
          photoIds: deleteArr
        }
        let res = await this.$request.post('/clubPhotoUrl/deletePhoto', data)
        if (res.code === 200) {
          this.$message.success({
            showClose: true,
            message: '删除成功'
          })
          this.getClubPhotoThemeById()
          this.selectThemePicture(true)
          this.selectClubPhotoTheme(true)
          this.isOpenDialog = false
          this.isEdit = false
          this.isChangeCover = false
        }
      } catch (error) {
        // console.log('error', error)
      }
    },

    // 点击二次询问弹框的确定按钮
    async confirm() {
      // 删除相册
      if (this.type === 1) {
        this.postDeletePhotoTheme()
      }
      // 删除照片
      else if (this.type === 2) {
        this.postDeletePhoto()
      }
      // 关闭确认弹窗
      else if (this.type === 3) {
        this.isOpenDialog = false
        this.showEditPop = false
      }
    },

    // 关闭编辑相册资料弹窗
    closeEditPop() {
      this.titles = "暂未保存相册资料,确定要退出吗？"
      this.type = 3
      this.isOpenDialog = true
      this.showLocationSearchList = false
    },

    shows() {
      this.showLocationSearchList = !this.showLocationSearchList
    },

    searchAddress() {
      this.enterEvents = true
    },

    chooseAddress(data) {
      this.editThemeData.address = data.address
      this.editThemeData.province = data.province
      this.editThemeData.city = data.city
      this.editThemeData.area = data.area
      this.editThemeData.showAddress = data.myShowAddres
      this.editThemeData.longitude = data.longitude
      this.editThemeData.latitude = data.latitude
      this.showLocationSearchList = !this.showLocationSearchList
    },

    //新增相册操作
    async submitAblum() {
      if (!this.editThemeData.themeName) {
        this.$message.warning({
          showClose: true,
          message: '相册主题不能为空'
        })
        return
      }
      // 新建相册
      if (this.editType === 1) {
        let data = {
          "clubId": this.$route.params.clubId,
          "themeName": this.editThemeData.themeName,
          "content": this.editThemeData.content,
          "country": '中国',
          "province": this.editThemeData.province ? this.editThemeData.province : '',
          "city": this.editThemeData.city ? this.editThemeData.city : '',
          "area": this.editThemeData.area ? this.editThemeData.area : '',
          "address": this.editThemeData.address ? this.editThemeData.address : '',
          "longitude": this.editThemeData.longitude ? this.editThemeData.longitude : '',
          "latitude": this.editThemeData.latitude ? this.editThemeData.latitude : '',
        }
        let res = await this.$request.post('/clubPhotoTheme/createPhotoTheme', data)
        if (res.code === 200) {
          this.$message.success({
            showClose: true,
            message: '创建成功'
          })
          this.showEditPop = false
          this.selectClubPhotoTheme(true)
          this.editThemeData = null
        }
      }
      // 编辑相册
      if (this.editType === 2) {
        let data = {
          "id": this.editThemeData.id,
          "clubId": this.editThemeData.clubId,
          "themeName": this.editThemeData.themeName,
          "content": this.editThemeData.content,
          "country": '中国',
          "province": this.editThemeData.province,
          "city": this.editThemeData.city,
          "area": this.editThemeData.area,
          "address": this.editThemeData.address,
          "longitude": this.editThemeData.longitude,
          "latitude": this.editThemeData.latitude,
        }
        let res = await this.$request.post('/clubPhotoTheme/editPhotoTheme', data)
        if (res.code === 200) {
          this.currentTheme.themeName = this.editThemeData.themeName
          this.currentTheme.content = this.editThemeData.content
          this.currentTheme.address = this.editThemeData.address
          this.$message.success({
            showClose: true,
            message: '保存成功'
          })
          this.showEditPop = false
          this.selectClubPhotoTheme()
        }
      }
    },

    // 获取相册长度
    themelenMin(e) {
      let cursor = e.length								            // 获取光标位置
      let regSpace = /\s{3,}|[\r\n]/g			            // 多个空格或换行
      let trimStrL = e.replace(/^\s*/g, "")            // 去掉左边空格
      let trimStrC = ''										            // 替换空格后数据
      let realLength = 0 									            // 字节长度
      let byteItemLength = 0
      if (cursor !== -1) {
        let strL = trimStrL.slice(0, cursor)
        cursor = strL.replace(regSpace, '  ').length
      }
      trimStrC = Array.from(trimStrL.replace(regSpace, '  '))
      if (trimStrC.length == 0) {
        this.themeTitleLen = 0
      }
      for (let i = 0; i < trimStrC.length; i++) {
        byteItemLength = (unescape(encodeURIComponent(trimStrC[i])).split("").map(val => val.charCodeAt())).length
        realLength += byteItemLength
        if (realLength >= 1 && realLength <= 3) {
          this.themeTitleLen = 1
        } else {
          if (Math.ceil(parseFloat(realLength) / 3) > 10) {
            trimStrC = trimStrC.splice(0, i)
            this.$message.warning({
              showClose: true,
              message: '相册主题最多不能超过10字哦～',
            })
            this.themeTitleLen = 10
          } else {
            this.themeTitleLen = Math.ceil(parseFloat(realLength) / 3)
          }
        }
      }
      this.editThemeData.themeName = trimStrC.join("")
      this.themeStatistics = Math.ceil(parseFloat(unescape(encodeURIComponent(this.editThemeData.themeName)).split("").map(val => val.charCodeAt()).length) / 3)
    },

    // 获取俱乐部介绍长度
    introlenMin(e) {
      let cursor = e.length						          // 获取光标位置
      let regSpace = /^\n{2,}/gm			          // 多个空格
      let trimStrL = e.replace(/^\s*/g, "")      // 去掉左边空格
      let trimStrC = ''								          // 替换空格后数据
      let realLength = 0 							          // 实际字符串的长度
      let byteItemLength = 0					          // 单个字节长度
      if (cursor !== -1) {
        let strL = trimStrL.slice(0, cursor)
        cursor = strL.replace(regSpace, '\n').length
      }
      trimStrC = trimStrL.replace(regSpace, "\n")
      trimStrC = Array.from(trimStrC)
      if (trimStrC.length == 0) {
        this.themeContentLen = 0
      }
      for (let i = 0; i < trimStrC.length; i++) {
        byteItemLength = (unescape(encodeURIComponent(trimStrC[i])).split("").map(val => val.charCodeAt())).length
        realLength += byteItemLength
        if (realLength >= 1 && realLength <= 3) {
          this.themeContentLen = 1
        } else {
          if (Math.ceil(parseFloat(realLength) / 3) > 100) {
            trimStrC = trimStrC.slice(0, i)
            this.themeContentLen = 100
            this.$message({
              message: '介绍最多不能超过100字哦～',
              type: 'warning'
            })
          } else {
            this.themeContentLen = Math.ceil(parseFloat(realLength) / 3)
          }
        }
      }
      this.editThemeData.content = trimStrC.join("")
      this.introStatistics = Math.ceil(parseFloat(unescape(encodeURIComponent(this.editThemeData.content)).split("").map(val => val.charCodeAt()).length) / 3)
    },
  },
};
</script>

<style lang="scss" scoped>
.club-temem-page {
  background: #f7f8fa;
  margin-top: 0px;
  .top {
    width: 790px;
    min-height: 314px;
    background: #ffffff;
    border-radius: 5px;
    // margin-bottom: 105px;
    display: flex;
    flex-direction: column;
    position: relative;
    .title {
      font-size: 16px;
      font-weight: bold;
      color: #444444;
      padding: 20px 0;
      text-align: center;
    }
    .ablum {
      width: 100%;
      // height: 580px;
      height: 655px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 0 0 20px 20px;
      box-sizing: border-box;
      overflow-x: hidden;
      .img-avatars {
        width: 179px;
        height: 123px;
        border-radius: 5px;
        border: 1px dashed #dddddd;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-sizing: border-box;
      }
      .ablum-detail {
        width: 179px;
        cursor: pointer;
        margin-bottom: 25px;
        margin-right: 12px;
        position: relative;
        .ablum-img {
          width: 179px;
          height: 123px;
          margin-bottom: 10px;
          position: relative;
          .club-avatar {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 5px;
          }
          .ablumn-null {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fafafa;
            border-radius: 5px;
          }
        }
        .more-roles {
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
          height: 18px;
          width: 18px;
        }
        .sign-outs {
          cursor: pointer;
          width: 100px;
          height: 0;
          background: #fff;
          border-radius: 5px;
          position: absolute;
          right: -10px;
          z-index: 99;
          //修改页面抖动
          top: 35px;
          box-sizing: border-box;
          transition: all 0.25s cubic-bezier(0.49, -0.3, 0.68, 1.23);
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .edit-information {
            flex: 1;
            color: #444444;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 0;
            box-sizing: border-box;
          }
          .edit-information:hover {
            color: #1465cf !important;
          }
        }
        .show-outs {
          padding: 5px 0;
          height: 65px;
          box-shadow: -2px 3px 14px 8px rgba(0, 0, 0, 0.07);
        }
        .ablum-name {
          width: 100%;
          font-size: 16px;
          font-weight: bold;
          color: #444444;
          margin-bottom: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .ablum-count {
          width: 100%;
          font-size: 12px;
          color: #888888;
        }
      }
    }
  }
  .back-button {
    display: flex;
    flex-direction: row;
    justify-content: start;
    cursor: pointer;
    margin-bottom: 15px;
    .button-text {
      margin-left: 3px;
      margin-bottom: 2px;
      font-size: 16px;
      font-weight: 500;
      color: #1465cf;
      line-height: 22px;
    }
  }
  .theme-detail {
    width: 790px;
    height: 230px;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 15px;
    position: relative;
    display: flex;
    align-items: center;
    .theme-detail-left {
      flex: 1;
      height: 100%;
      padding: 35px 40px 35px 30px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .theme-name {
        color: #333;
        font-size: 18px;
      }
      .theme-content {
        color: #333;
        font-size: 14px;
        margin-top: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .theme-address {
        color: #333;
        font-size: 14px;
        margin-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .theme-count {
        color: #888;
        font-size: 14px;
        margin-top: 10px;
      }
    }
    .theme-detail-right {
      width: 440px;
      height: 100%;
      background-color: #efefef;
      border-radius: 0 5px 5px 0;
      overflow: hidden;
      .theme-cover {
        width: 100%;
        height: 100%;
      }
    }
    .theme-edit {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
    .role-pop {
      cursor: pointer;
      width: 100px;
      height: 0;
      background: #fff;
      border-radius: 5px;
      position: absolute;
      top: 38px;
      right: -20px;
      z-index: 99;
      //修改页面抖动
      box-sizing: border-box;
      transition: all 0.25s cubic-bezier(0.49, -0.3, 0.68, 1.23);
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .edit-information {
        flex: 1;
        color: #444444;
        font-size: 14px;
        padding: 5px 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .edit-information:hover {
        color: #1465cf !important;
      }
    }
    .show-role-pop {
      box-shadow: -2px 3px 14px 8px rgba(0, 0, 0, 0.07);
      padding: 5px 0;
    }
  }
  .bottom {
    width: 791px;
    height: 497px;
    background: #ffffff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 20px 0 20px 20px;
    box-sizing: border-box;
    margin-bottom: 105px;
    .title {
      font-size: 16px;
      font-weight: bold;
      color: #444444;
      margin-bottom: 20px;
      text-align: center;
    }
    .picture-list {
      width: 100%;
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      .img-avatar {
        width: 140px;
        height: 140px;
        border-radius: 5px;
        overflow: hidden;
        border: 1px dashed #dddddd;
        margin-right: 10px;
        cursor: pointer;
        .upload {
          width: 30px;
          height: 30px;
          margin-left: 57px;
          margin-top: 55px;
        }
      }
      .ablum-img {
        width: 140px;
        height: 140px;
        margin-right: 10px;
        margin-bottom: 20px;
        position: relative;
        .choice-circel {
          position: absolute;
          top: 10px;
          right: 10px;
          width: 18px;
          height: 18px;
          z-index: 99;
          border-radius: 50%;
        }
        .club-avatar {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }
    .button-box {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      .default-btn {
        width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        font-size: 14px;
        color: #ffffff;
        background-color: #ddd;
        cursor: pointer;
      }
      .cancel {
        background-color: #eee;
        color: #333;
        margin-right: 10px;
      }
      .del {
        background-color: #ec4747;
      }
      .confirm {
        background-color: #1465cf;
      }
    }
  }
}

.login {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
  z-index: 99999;
  .login-wrap {
    width: 480px;
    height: 341px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
  }
  .title-line {
    padding-top: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .login-text {
      text-align: center;
      width: 100%;
      font-size: 18px;
      font-weight: bold;
      color: #000000;
    }
    .close {
      position: absolute;
      right: 27px;
      cursor: pointer;
    }
  }
  .login-area {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    position: relative;
    .edit-name-line {
      width: 310px;
      height: 34px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      box-sizing: border-box;
      position: relative;
      .album-theme {
        flex: 1;
        border-radius: 5px;
        .theme-input {
          width: 100%;
          font-size: 14px;
          line-height: 20px;
        }
      }
      .text-statistics {
        position: absolute;
        top: 9px;
        right: 10px;
        font-size: 12px;
        text-align: center;
        color: #a8a8a8;
      }
    }
    .edit-content-line {
      width: 310px;
      height: 90px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      border: 1px solid #ddd;
      border-radius: 5px;
      padding-bottom: 5px;
      box-sizing: border-box;
      .input-content {
        flex: 1;
        font-size: 14px;
      }
      .text-statistic {
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 12px;
        color: #a8a8a8;
        text-align: right;
      }
    }
    .club-address {
      width: 310px;
      height: 34px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      .address-text {
        font-size: 14px;
        color: #444444;
        margin-right: 12px;
      }
      .input-dress {
        flex: 1;
        height: 100%;
        .input-address {
          width: 100%;
          height: 100%;
          padding-left: 5px;
          border-radius: 5px;
          border: 1px solid #dddddd;
          box-sizing: border-box;
        }
        .searchLocations {
          position: relative;
        }
      }
    }
  }
  .submit-area {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    .submit {
      width: 100px;
      height: 30px;
      background: #1465cf;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      transition: all 0.1s;
    }
    button:active {
      opacity: 0.5;
    }
  }
}
</style>