<template>
	<div class="club-data">
		<!-- <div class="top-nav">
			<div class="nav-item" :class="current===0?'nav-select':''" @click="selsecTabs(0)">
				关键数据
			</div>
			<div class="nav-item" :class="current===1?'nav-select':''" @click="selsecTabs(1)">
				更多
			</div>
		</div> -->
		<div class="tabs one" v-if="current===0 && clubRoles">
			<div class="member-card">
				<div class="card-title">数据概览</div>
				<div class="card-main">
					<div class="card-item">
						<div class="item-title">俱乐部粉丝数量（个）</div>
						<div class="item-num">{{clubData.fansCount}}</div>
						<div class="item-day">
							<span class="item-left">日</span>
							<span class="item-right" v-if="clubData.weekFansCount === 0 ">--</span>
							<div v-else>
								<el-image class="item-icon" fit="cover" :src="clubData.dailyFansCount < 0 ? require('@/assets/club-drop.png') : require('@/assets/club-grow.png')"></el-image>
								{{Math.abs(clubData.dailyFansCount)}}
							</div>
						</div>
						<div class="item-day">
							<span class="item-left">周</span>
							<span class="item-right"  v-if="clubData.weekFansCount === 0 ">--</span>
							<div v-else>
								<el-image class="item-icon" fit="cover" :src="clubData.weekFansCount < 0 ? require('@/assets/club-drop.png') : require('@/assets/club-grow.png')"></el-image>
								{{Math.abs(clubData.weekFansCount)}}
							</div>
						</div>
						<div class="item-day">
							<span class="item-left">月</span>
							<span class="item-right" v-if="clubData.monthFansCount === 0 ">--</span>
							<div v-else>
								<el-image class="item-icon" fit="cover" :src="clubData.monthFansCount < 0 ? require('@/assets/club-drop.png') : require('@/assets/club-grow.png')"></el-image>
								{{Math.abs(clubData.monthFansCount)}}
							</div>
						</div>
					</div>
					<div class="line"></div>
					<div class="card-item">
						<div class="item-title">俱乐部成员数量（个）</div>
						<div class="item-num">{{clubData.memberCount}}</div>
						<div class="item-day">
							<span class="item-left">日</span>
							<span class="item-right" v-if="clubData.dailyMemberCount === 0 ">--</span>
							<div v-else>
								<el-image class="item-icon" fit="cover" :src="clubData.dailyMemberCount < 0 ? require('@/assets/club-drop.png') : require('@/assets/club-grow.png')"></el-image>
								{{Math.abs(clubData.dailyMemberCount)}}
							</div>
						</div>
						<div class="item-day">
							<span class="item-left">周</span>
							<span class="item-right" v-if="clubData.weekMemberCount === 0 ">--</span>
							<div v-else>
								<el-image class="item-icon" fit="cover" :src="clubData.weekMemberCount < 0 ? require('@/assets/club-drop.png') : require('@/assets/club-grow.png')"></el-image>
								{{Math.abs(clubData.weekMemberCount)}}
							</div>
						</div>
						<div class="item-day">
							<span class="item-left">月</span>
							<span class="item-right" v-if="clubData.monthMemberCount === 0 ">--</span>
							<div v-else>
								<el-image class="item-icon" fit="cover" :src="clubData.monthMemberCount < 0 ? require('@/assets/club-drop.png') : require('@/assets/club-grow.png')"></el-image>
								{{Math.abs(clubData.monthMemberCount)}}
							</div>
						</div>
					</div>
					<div class="line"></div>
					<div class="card-item">
						<div class="item-title">俱乐部浏览量总和（次）</div>
						<div class="item-num">{{clubData.views}}</div>
					</div>
				</div>
			</div>
			<div class="member-card" v-if="$store.state.clubRoles.role === 3">
				<div class="card-title" style="display: flex; align-items: center">
					<span>用户复购率</span>
					<el-tooltip class="item" effect="dark" content="近n天，交易次数大于1次的人数/俱乐部用户数量" placement="top">
						<el-image fit="cover" :src="require('@/assets/icon-tip.png')" style="width: 15px; height: 15px; margin-left: 5px;"></el-image>
          </el-tooltip>
				</div>
				<div class="card-main">
					<div class="card-item">
						<div class="repurchase" style="margin-bottom: 15px">
							<span class="repurchase__label">周复购率</span>
							<span class="repurchase__number">{{ clubData.sevenDayRepurchaseRate }}</span>
						</div>
						<div class="repurchase">
							<span class="repurchase__label">较7天前</span>
							<el-image v-if="clubData.sevenDayRepurchaseRateChange < 0" class="repurchase__icon" fit="cover" :src="require('@/assets/club-drop.png')"></el-image>
							<el-image v-if="clubData.sevenDayRepurchaseRateChange > 0" class="repurchase__icon" fit="cover" :src="require('@/assets/club-grow.png')"></el-image>
							<span class="repurchase__sm">{{ clubData.sevenDayRepurchaseRateChange }}%</span>
						</div>
					</div>
					<div class="line"></div>
					<div class="card-item">
						<div class="repurchase" style="margin-bottom: 15px">
							<span class="repurchase__label">月复购率</span>
							<span class="repurchase__number">{{ clubData.thirtyDayRepurchaseRate }}</span>
						</div>
						<div class="repurchase">
							<span class="repurchase__label">较30天前</span>
							<el-image v-if="clubData.sevenDayRepurchaseRateChange < 0" class="repurchase__icon" fit="cover" :src="require('@/assets/club-drop.png')"></el-image>
							<el-image v-if="clubData.sevenDayRepurchaseRateChange > 0" class="repurchase__icon" fit="cover" :src="require('@/assets/club-grow.png')"></el-image>
							<span class="repurchase__sm">{{ clubData.thirtyDayRepurchaseRateChange }}%</span>
						</div>
					</div>
				</div>
			</div>
			<div class="member-card">
				<div class="card-title">俱乐部动态</div>
				<div class="card-main">
					<div class="card-item">
						<div class="item-title">俱乐部动态数量（个）</div>
						<div class="item-num">{{clubData.clubNewsCount}}</div>
						<div class="item-day">
							<span class="item-left">日</span>
							<span class="item-right" v-if="clubData.dailyClubNewsCount === 0 ">--</span>
							<div v-else>
								<el-image class="item-icon" fit="cover" :src="clubData.dailyClubNewsCount < 0 ? require('@/assets/club-drop.png') : require('@/assets/club-grow.png')"></el-image>
								{{Math.abs(clubData.dailyClubNewsCount)}}
							</div>
						</div>
						<div class="item-day">
							<span class="item-left">周</span>
							<span class="item-right" v-if="clubData.weekClubNewsCount === 0 ">--</span>
							<div v-else>
								<el-image class="item-icon" fit="cover" :src="clubData.weekClubNewsCount < 0 ? require('@/assets/club-drop.png') : require('@/assets/club-grow.png')"></el-image>
								{{Math.abs(clubData.weekClubNewsCount)}}
							</div>
						</div>
						<div class="item-day">
							<span class="item-left">月</span>
							<span class="item-right" v-if="clubData.monthClubNewsCount === 0 ">--</span>
							<div v-else>
								<el-image class="item-icon" fit="cover" :src="clubData.monthClubNewsCount < 0 ? require('@/assets/club-drop.png') : require('@/assets/club-grow.png')"></el-image>
								{{Math.abs(clubData.monthClubNewsCount)}}
							</div>
						</div>
					</div>
					<div class="line"></div>
					<div class="card-item">
						<div class="item-title">俱乐部图片和视频数量（个）</div>
						<div class="item-num">{{clubData.clubMediaCount}}</div>
						<div class="item-day">
							<span class="item-left">日</span>
							<span v-if="clubData.dailyClubMediaCount === 0 " class="item-right">--</span>
							<div v-else>
								<el-image class="item-icon" fit="cover" :src="clubData.dailyClubMediaCount < 0 ? require('@/assets/club-drop.png') : require('@/assets/club-grow.png')"></el-image>
								{{Math.abs(clubData.dailyClubMediaCount)}}
							</div>
						</div>
						<div class="item-day">
							<span class="item-left">周</span>
							<span v-if="clubData.weekClubMediaCount === 0 " class="item-right">--</span>
							<div v-else>
								<el-image class="item-icon" fit="cover" :src="clubData.weekClubMediaCount < 0 ? require('@/assets/club-drop.png') : require('@/assets/club-grow.png')"></el-image>
								{{Math.abs(clubData.weekClubMediaCount)}}
							</div>
						</div>
						<div class="item-day">
							<span class="item-left">月</span>
							<span class="item-right" v-if="clubData.monthClubMediaCount === 0 ">--</span>
							<div v-else>
								<el-image class="item-icon" fit="cover" :src="clubData.monthClubMediaCount < 0 ? require('@/assets/club-drop.png') : require('@/assets/club-grow.png')"></el-image>
								{{Math.abs(clubData.monthClubMediaCount)}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="member-card">
				<div class="card-title">俱乐部活动</div>
				<div class="card-main chart-box">
					<div class="chart-title">
						<div class="left">近30天俱乐部活动</div>
						<div class="right">每日凌晨3点更新</div>
					</div>
					<div class="chart-icon" id="main" style="width: 686px;height:300px;"></div>
				</div>
				<div class="chart-bottom">
					<div class="num-title">
					<span class="line-color"></span>报名人数
					</div>
					<div class="num-title">
						<span class="line-color"></span>签到人数
					</div>
				</div>
				<div class="bottom-info">
					<div class="info-item">
						<el-image class="item-icon" fit="cover" :src="require('@/assets/chart-activity.png')"></el-image>
						<div class="right-text">
							<div class="top-num">{{clubData.activityCount}}</div>
							<span>总共发布活动</span>
						</div>
					</div>
					<div class="info-item">
						<el-image class="item-icon" fit="cover" :src="require('@/assets/chart-sign.png')"></el-image>
						<div class="right-text">
							<div class="top-num">{{clubData.activityTotalEnrollCount}}</div>
							<span>总共报名人数</span>
						</div>
					</div>
					<div class="info-item">
						<el-image class="item-icon" fit="cover" :src="require('@/assets/chart-enroll.png')"></el-image>
						<div class="right-text">
							<div class="top-num">{{clubData.activityTotalSignCount}}</div>
							<span>总共签到人数</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="tabs two" v-else-if="current===1 && clubRoles">

		</div> -->
		<div class="err-box" v-else>
			暂无管理数据
		</div>
	</div>
</template>

<script>
	import 'echarts/lib/chart/bar'
	export default {
		data() {
			return {
				current: 0,
				grow: false,
				clubData: {},					 		// 俱乐部管理数据
				EchartsList: [],					// 活动数组
				clubRoles: true,					// 数据管理权限
			}
		},
		mounted() {
			this.getMyClubRole()
			this.getKeyData()
		},
		methods: {
			//获取用户在俱乐部的权限
			async getMyClubRole() {
				try {
					let res = await this.$request.get(`/clubMemberInfo/getMyRole/${this.$route.params.clubId}`)
					let data = {
						clubId: this.$route.params.clubId,
						role: 0,
						canEditNews: false,
						canEditActivity: false,
						canEditMember: false,
						canEditTheme: false,
						canEditData: false,
						canEditClub: false,
						canActivityData: false,
                        canManagerSuperShare: false,
					}
					if(res.data.role) {
						this.myRole = res.data.role
						this.clubRoles = res.data.roles
						data.role = res.data.role
						for(let i = 0; i < res.data.roles.length; i++) {
							if(res.data.roles[i].roleId == 2) {
								// 俱乐部动态管理
								data.canEditNews = true
							}
							else if(res.data.roles[i].roleId == 3) {
								// 俱乐部活动管理
								data.canEditActivity = true
							}
							else if(res.data.roles[i].roleId == 4) {
								// 俱乐部成员管理
								data.canEditMember = true
							}
							else if(res.data.roles[i].roleId == 5) {
								// 俱乐部相册管理
								data.canEditTheme = true
							}
							else if(res.data.roles[i].roleId == 6) {
								// 编辑俱乐部信息
								data.canEditClub = true
							}
							else if(res.data.roles[i].roleId == 7) {
								// 查看俱乐部数据
								data.canEditData = true
							}
							else if (res.data.roles[i].roleId == 10) {
								// 查看活动数据
								data.canActivityData = true
							}
                            else if (res.data.roles[i].roleId == 11) {
                                // 超级分享者管理
                                data.canManagerSuperShare = true
                            }
						}
					}
					this.$store.commit('SET_CLUBROLES', data)
				} catch (error) {
					//
				}
			},
			selsecTabs(index) {
				if(index === 1 ) {
					this.$alert('敬请期待', {
							confirmButtonText: '确定',
							customClass:'message_box_alert'
						}
					)
					return
				}
				this.current = index
				this.getKeyData()
			},
			async getKeyData() {
				try{
					let list = []
					let xList = []
					let signCount = []
					let enrollCount = []
					let res = await this.$request.get(`/clubData/getKeyData?clubId=${this.$route.params.clubId}`)
					this.clubData = res.data
					this.clubData.activityDataList.forEach((temp,index) => {
						if(temp.clubActivityKeyDataList || index=== 0 || index === this.clubData.length-1){
							let t = temp.startTime.replace(/-/g,'/')
								t = t.replace(/\/0/g,"/")
								t = t.split('/')
							temp.startTime = `${t[1]}/${t[2]}`
							temp.signRate = temp.clubActivityKeyDataList?temp.clubActivityKeyDataList[0].signCount === 0 ? 0: (Math.round(parseFloat(temp.clubActivityKeyDataList[0].signCount) / parseFloat(temp.clubActivityKeyDataList[0].enrollCount) * 10000) / 100.00):0
							list.push(temp)
							xList.push(temp.startTime)
							enrollCount.push(temp.clubActivityKeyDataList?temp.clubActivityKeyDataList[0].enrollCount : 0)
							signCount.push(temp.clubActivityKeyDataList?temp.clubActivityKeyDataList[0].signCount : 0)
						}
					})
					this.EchartsList = list
					this.clubEcharts(xList, enrollCount, signCount)
				} catch(e) {
					this.clubRoles = false
				}
			},
			clubEcharts(xList,enrollCount,signCount) {
				let chart = document.getElementById('main')
				let clubChart = this.$echarts.init(chart)
				var option = {
					grid:{
						top:"10%",
						left:"5%",
						right:"5%",
						bottom:"10%"
					},
					tooltip: {
						renderMode: 'html',
						backgroundColor:'rgba(0,0,0,0.5)',
						padding:15,
						color:'#fff',
						borderWidth:'0',
						textStyle:{
							color:'#fff',
							fontSize:10,
						},
						formatter:(params)=> {
							let index = params.dataIndex
							let firstParams = this.EchartsList[index];
							return firstParams.clubActivityKeyDataList[0].activityName  + '<br>' +"<div style='height:8px'></div>" +'报名人数：' + firstParams.clubActivityKeyDataList[0].enrollCount + '<br>' +"<div style='height:8px'></div>"+ ' 签到人数：' + firstParams.clubActivityKeyDataList[0].signCount + '<br>' +"<div style='height:8px'></div>" +  ' 签到率：'+firstParams.signRate+' %';
						}
					},
					legend: {},
					yAxis: [
						{
							type:'value',
							splitLine:{
								show: true,
								lineStyle: {
									type:'dashed',
									color:'#ddd'
								}
							},
							minInterval: 1,
							min:0,
							dataMax: 'value',
							axisLabel: {}
						}
					],
					xAxis:[
						{
							type:'category',
							axisTick: {
								show:false,
							},
							axisLine: {
							lineStyle: {
								type:  'solid' ,
								color:  '#ddd'//左边线的颜色
								}
							},
							data: xList
						}
					],
					series: [
						{type:'bar', color:'#013D6E', barWidth:5, data: enrollCount ,itemStyle: {
							normal: {
								barBorderRadius:[3,3,0,0]
							}
						}, barCategoryGap: 2},
						{type:'bar', color:'#FFAD2A', barWidth:5, data: signCount, itemStyle: {
							normal: {
								barBorderRadius:[3,3,0,0]
							}
						}, barCategoryGap: 2}
						]
				}
				clubChart.setOption(option)
			}
		}
	}

</script>

<style scoped lang="scss">
	.club-data{
		width: 785px;
		box-sizing: border-box;
		.top-nav{
			width: 100%;
			box-sizing: border-box;
			background-color: #fff;
			height: 51px;
			border-radius: 5px;
			margin-bottom: 15px;
			display: flex;
			align-items: center;
			justify-content: center;
			.nav-item{
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color:#444;
				transform: all 0.5s;
				position: relative;
				cursor: pointer;
			}
			.nav-item:first-child{
				margin-right: 100px;
			}
			.nav-select{
				color:#1465CF;
			}
			.nav-select::after{
				position: absolute;
				content: '';
				bottom:0;
				left:0;
				width: 100%;
				height: 2px;
				background-color: #1465CF;
				border-radius: 2px;
			}
		}
		.tabs{
			width: 100%;
			box-sizing: border-box;
			.member-card{
				// height: 277px;
				background: #FFFFFF;
				border-radius: 5px;
				margin-bottom: 15px;
				.card-title{
					padding:16px 20px 13px;
					border-bottom: 1px solid #ddd;
					font-size:16px ;
					color:#444;
					font-weight: bold;
				}
				.card-main{
					display: flex;
					padding:24px 20px 30px;
					box-sizing: border-box;
					justify-content: space-around;
					.card-item{
						flex: 1;
						display: flex;
						flex-direction: column;
						align-items: center;
						font-size: 14px;
						color:#444;
						font-weight: bold;
						.repurchase {
							display: flex;
							align-items: center;
							&__label {
								font-size: 14px;
								color: #333;
								margin-right: 10px;
							}
							&__number {
								font-size: 16px;
								font-weight: 500;
							}
							&__sm {
								font-size: 14px;
								font-weight: 500;
							}
							&__icon {
								width: 10px;
								height: 10px;
								margin-right: 10px;
							}
						}
						.item-title{
							font-size: 12px;
							font-weight: 400;
						}
						.item-num{
							margin-top: 10px;
							font-size: 16px;

						}
						.item-day{
							display: flex;
							align-items: center;
							justify-content: center;
							margin-top:20px;
							.item-left{
								color:#888;
								font-weight: 400;
							}
							.item-icon{
								width: 9px;
								height: 4px;
								margin:0 10px;
							}

							.item-right{
								display: inline-block;
								color:#888;
								font-weight: 400;
								margin-left: 29px;
							}
						}
					}
					.line{
						width: 1px;
						background-color: #ddd;
					}
				}
				.chart-box{
					cursor: pointer;
					background-color: #fff;
					display: flex;
					flex-direction: column;
					padding-bottom: 0!important;
					box-sizing: border-box;
					.chart-title{
						display: flex;
						align-items: center;
						justify-content: space-between;
						font-size: 12px;
						color: #000;
						padding-right: 5%;
						.right{
							color:#888;
						}
					}
				}
				.chart-bottom{
					width: 100%;
					box-sizing: border-box;
					margin:10px 0 41px;
					display: flex;
					align-items: center;
					justify-content: center;
					.num-title{
						font-size:12px ;
						color:#444;
						display: flex;
						align-items: center;
						.line-color{
							width:20px ;
							height: 5px;
							border-radius: 3px;
							background-color: #013D6E;
							display: inline-block;
							margin-right: 10px;
						}
					}
					.num-title:nth-child(2){
						margin-left:30px ;
						.line-color{
							background-color: #FFAD2A;
						}
					}
				}
				.bottom-info{
					display: flex;
					align-items: center;
					justify-content: space-around;
					box-sizing: border-box;
					padding:0 20px 20px;
					.info-item{
						display: flex;
						align-items: center;
						justify-content: space-around;
						.item-icon{
							width: 40px;
							height: 40px;
							margin-right: 10px;
						}
						.right-text{
							.top-num{
								font-size: 16px;
								color: #013D6E;
								font-weight: bold;
							}
							font-size: 12px;
							color: #444;
						}
					}
				}
			}

		}
		.err-box{
			width: 100%;
			box-sizing: border-box;
			background-color: #fff;
			height: 500px;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color:#444;
		}
	}
</style>
