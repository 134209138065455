<template>
  <div id="app">
    <transition name="el-fade-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
  export default {
    data() {
      return {

      }
    },
    watch: {
      '$route': function() {document.querySelector('html').scrollTop = 0;}
    }
  }
</script>

<style lang="scss">
#app {
  font-family: PingFang SC, Hiragino Sans GB, Helvetica Neue, Microsoft Yahei,Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --vh: 1vh;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
h1,
h2,
h3,
h4 {
  padding: 0;
  margin: 0;
}

input {
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
}

button {
  outline: none;
  border: none;
}

button:hover {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #222;
}

.el-button--primary {
  background: #005faf !important;
  color: #fff !important;
  border-color: #005faf !important;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #005faf;
  color: #fff;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #005faf;
  border-color: #005faf;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #222;
}
.el-checkbox.is-bordered.is-checked {
  border-color: #005faf;
}

// .el-message-box{
//   width: 480px;
//   height: 180px;
//   box-sizing: border-box;
//   border-radius: 10rpx;
//   align-items: center;
//   justify-content: center;
//   padding:20px 17px 25px;
//   text-align: center;
//   .el-message-box__header{
//     padding:0;
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     .el-message-box__title{
//       margin:0px;
//       span{
//         color:#000;
//         font-size:18px ;
//         font-weight: bold;
//       }
//     }
//     .el-message-box__headerbtn{
//       top:-4px;
//       right:1px;
//     }
//   }
//   .el-message-box__content{
//     margin:30px auto;
//     padding:0px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     .el-message-box__message p{
//       margin:0px;
//       color:#444;
//       font-size: 14px;
//     }
//   }
//   .el-message-box__btns{
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding:0px;
//     .el-button--primary{
//       width: 100px;
//       height: 30px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       margin-right: 0px;
//       margin-top: 0px;
//       span{
//         color:#fff;
//         font-size: 14px;
//         font-weight: bold;
//       }
//     }
//     .el-button--primary:nth-child(2){
//       margin-left: 150px;
//     }
//   }
// }

// 点赞动画
.liked {
  opacity: 0;
  animation: 1.2s like-animation both cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
@keyframes like-animation {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  15% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  32% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
// 取消点赞动画
.unliked {
  opacity: 0;
  animation: 1.2s unlike-animation both cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
@keyframes unlike-animation {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  15% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  30% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
/* 设置滚动条的样式 */

::-webkit-scrollbar {
  width:8px;
  height: 8px;
  box-sizing: border-box;
}

/* 滚动槽 */

::-webkit-scrollbar-track {
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
  border-radius:8px;
}

/* 滚动条滑块 */

::-webkit-scrollbar-thumb {
  border-radius:8px;
  background:rgba(0,0,0,0.1);
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
}

@media screen and (max-height: 730px) {
  :root {
    --vh: 7.3px;
  }
}
</style>
