<template>
  <div class="container">
    <div class="list" v-if="list">
      <div class="item" @click="toDetailPages(item)" v-for="(item,index) in list" :key="index">
        <div class="float-tag" v-if="item.types !== 0">
          <el-image class="el-image-more" :src="item.types === 1 ? require('@/assets/signPhoto.png') : require('@/assets/signVideo.png')" fit="cover"></el-image>
        </div>
        <el-image class="top-photo" fit="cover" :src="$cosUrl + (item.urlResponseVoList[0].cover ? item.urlResponseVoList[0].cover : item.urlResponseVoList[0].url)" alt="photo">
          <div slot="error">
            <el-image :src="imgUrl" fit="contain" style="width: 220px; height: 390px;"></el-image>
          </div>
        </el-image>
        <div class="bottom-desc">{{ item.title }}</div>
        <div class="bottom-box">
          <div class="bottom-left">
            <div class="avatar">
              <img-head :avatarUrl="item.avatarUrl" :Radius="50" :size="30"></img-head>
            </div>
            <div class="nickname">{{ item.nickname }}</div>
          </div>
          <div class="bottom-right">
            <icon-font class="liked" name="heart" size="15px" color="#444444"></icon-font>
            <div class="icon-text">{{ item.likeCount }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading" v-if="loading" v-loading="loading"></div>
    <div class="more-text" v-else @click.stop="isshow = !isshow">查看更多</div>

    <download-app :flag="isshow" @close="closeQRcode"></download-app>
  </div>
</template>

<script>
import iconFont from '../components/IconFont.vue'
import downloadApp from '../components/download-app.vue'
import errorImg from "@/assets/error-img.png"
import imgHead from '../components/img-head.vue'
export default {
  data() {
    return {
      imgUrl: errorImg,
      isshow: false,
      list: [],
      loading: false,
    }
  },

  components: {
    iconFont,
    downloadApp,
    imgHead
  },

  mounted() {
    this.getRecommond()
    document.addEventListener('click', this.HiddenClick, true)
  },

  beforeDestroy() {
    document.removeEventListener('click', this.HiddenClick, true)
  },

  methods: {
    //关闭下载二维码
    closeQRcode() {
      this.isshow = false
    },

    toDetailPages(item) {
      this.$router.push({ name: 'DetailDynamic' })
      this.$store.commit('SET_NEWS_DETAIL', item)
    },

    // 点击页面事件 隐藏需要隐藏的区域
    HiddenClick() {
      this.isshow = false
    },

    // 获取关注内容
    async getRecommond() {
      this.loading = true
      try {
        let res = await this.$request.get(`/home/getWebHomeDiscoveryMain`)
        if (res.code === 200) {
          //用types来区分类型 0为默认,1为多图,2为视频
          let arrs = []
          for (let item of res.data) {
            item.types = 0
            if (item.urlResponseVoList[0].url.indexOf('.mp4') != -1) {
              item.types = 2
            } else if (item.urlResponseVoList.length > 1) {
              item.types = 1
            }
            arrs.push(item)
          }
          this.list = arrs
        }
      } catch (e) {
        //
      }
      this.loading = false
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  // width: 1180px;
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .loading {
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .item {
      // width: 220px;
      width: 19%;
      height: 390px;
      display: flex;
      // align-items: center;
      flex-direction: column;
      background-color: white;
      border-radius: 10px;
      // margin-right: 20px;
      margin-bottom: 20px;
      position: relative;
      cursor: pointer;
      .float-tag {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 32px;
        height: 32px;
        z-index: 2;
      }
      .top-photo {
        width: 100%;
        height: 290px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      .bottom-desc {
        width: 100%;
        box-sizing: border-box;
        padding: 15px;
        font-size: 14px;
        font-weight: bold;
        color: black;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
      .bottom-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px 15px 15px;
        box-sizing: border-box;
        .bottom-left {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20rpx;
          .avatar {
            margin-right: 10px;
          }
          .nickname {
            flex: 1;
            display: flex;
            align-content: center;
            font-size: 12px;
            color: #444444;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
        }
        .bottom-right {
          display: flex;
          justify-content: center;
          align-items: center;
          .icon-text {
            font-size: 12px;
            color: #444444;
            margin-left: 5px;
          }
        }
      }
      &:nth-child(5n) {
        margin-right: 0;
      }
      &:hover ::after {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 2, 4, 0.02);
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 10px;
        content: '';
      }
    }
  }
  .more-text {
    margin-top: 60px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: #1465cf;
  }
}
@media screen and (max-width: 1330px) and (min-width: 1050px) {
  .container .list .item {
    width: 23.9%;
  }
}
@media screen and (max-width: 1050px) and (min-width: 750px) {
  .container {
    width: 80%;
  }
  .container .list .item {
    width: 31%;
  }
}

/*手机端样式*/
@media screen and (max-width: 750px) {
  .container {
    width: 90%;
  }
  .container .list .item {
    width: 100%;
  }
  .home .container {
    margin-top: -60px;
  }
}
</style>