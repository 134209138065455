<template>
  <div class="data-board">
    <div class="header">数据看板</div>
    <div class="main">
      <div class="main-item" v-for="(item, index) in dataList" :key="index" v-loading="item.loading">
        <div class="title-area">
          <div class="title">
            <div>{{ item.title }}</div>
            <div class="title-icon" v-if="item.tip">
              <el-tooltip class="item" effect="dark" :content="item.tip" placement="top">
                <i class="el-icon-warning-outline" style="color: #0584F9"/>
              </el-tooltip>
            </div>
          </div>
          <el-dropdown class="select" @command="(value) => handleCommand(value, index)">
            <div class="select-title">
              <div class="select-value">{{ item.timeType === 1 ? '本周' : item.timeType === 2 ? '本月' : '本年' }}</div>
              <i class="el-icon-arrow-down"></i>
            </div>
            <el-dropdown-menu slot="dropdown" style="width: 75px; text-align: center; margin-right: 0px">
              <el-dropdown-item command="1">本周</el-dropdown-item>
              <el-dropdown-item command="2">本月</el-dropdown-item>
              <el-dropdown-item command="3">本年</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="data-area">
          <div class="data-item">
            <div class="label">数值</div>
            <div class="value">{{ item.data.count }}次</div>
          </div>
          <div class="data-item">
            <div class="label">{{ formatCompareText(item.timeType) }}</div>
            <div class="value" :class="item.data.compareLastCount === 0 ? '' : item.data.compareLastCount > 0 ? 'up' : 'down'">{{ item.data.compareLastCount > 0 ? '+' : '' }}{{ item.data.compareLastCount }}次</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        dataList: [
          {
            title: '活动数量',
            tip: '',
            timeType: 1,
            loading: false,
            data: {
              count: 0,
              compareLastCount: 0
            }
          },
          {
            title: '活动人数',
            tip: '参与活动人数去重得出的数据',
            timeType: 1,
            loading: false,
            data: {
              count: 0,
              compareLastCount: 0
            }
          },
          {
            title: '活动人次',
            tip: '参与活动人数不去重得出的数据',
            timeType: 1,
            loading: false,
            data: {
              count: 0,
              compareLastCount: 0
            }
          }
        ]
      }
    },

    mounted() {
      this.getActivityCount()
      this.getActivityMember()
      this.getTotalActivityMember()
    },

    methods: {
      handleCommand(command, index) {
        let lastType = this.dataList[index].timeType
        if(lastType != command) {
          this.dataList[index].timeType = parseInt(command)
          if(index === 0) {
            this.getActivityCount()
          } else if(index === 1) {
            this.getActivityMember()
          } else if(index === 2) {
            this.getTotalActivityMember()
          }
        }
      },

      formatCompareText(number) {
        let str = ''
        switch(number) {
          case 1:
            str = '较上周'
            break
          case 2:
            str = '较上月'
            break
          case 3:
            str = '较上年'
            break
        }
        return str
      },

      // 获取活动数量
      async getActivityCount() {
        this.dataList[0].loading = true
        try {
          let res = await this.$request.get(`/clubData/getActivityCount?clubId=${this.$route.params.clubId}&timeType=${this.dataList[0].timeType}`)
          this.dataList[0].data = res.data
          this.dataList[0].loading = false
        } catch (error) {
          this.dataList[0].loading = false
        }
      },

      // 获取活动人数（去重）
      async getActivityMember() {
        this.dataList[1].loading = true
        try {
          let res = await this.$request.get(`/clubData/getActivityMember?clubId=${this.$route.params.clubId}&timeType=${this.dataList[1].timeType}`)
          this.dataList[1].data = res.data
          this.dataList[1].loading = false
        } catch (error) {
          this.dataList[1].loading = false
        }
      },

      // 获取总参与活动人数（不去重）
      async getTotalActivityMember() {
        this.dataList[2].loading = true
        try {
          let res = await this.$request.get(`/clubData/getTotalActivityMember?clubId=${this.$route.params.clubId}&timeType=${this.dataList[2].timeType}`)
          this.dataList[2].data = res.data
          this.dataList[2].loading = false
        } catch (error) {
          this.dataList[2].loading = false
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .data-board {
    width: 790px;
    height: 207px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    .header {
      width: 100%;
      height: 54px;
      font-size: 18px;
      font-weight: bold;
      color: #444;
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      border-bottom: 0.5px solid #ddd;
    }
    .main {
      flex: 1;
      width: 100%;
      display: flex;
      align-items: flex-start;
      margin-top: 18px;
      .main-item {
        flex: 1;
        padding: 0 40px;
        box-sizing: border-box;
        border-right: 0.5px solid #ddd;
        .title-area {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 25px;
          .title {
            color: #333;
            font-size: 16px;
            font-weight: bold;
            display: flex;
            align-items: center;
            .title-icon {
              margin-left: 5px;
            }
          }
          .select {
            width: 75px;
            height: 28px;
            border-radius: 5px;
            border: 0.5px solid #ddd;
            padding-right: 5px;
            box-sizing: border-box;
            font-size: 12px;
            color: #333;
            cursor: pointer;
            .select-title {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .select-value {
                flex: 1;
                text-align: center;
              }
            }
          }
        }
        .data-area {
          .data-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            .label {
              color: #888;
            }
            .value {
              color: #333;
              font-weight: bold;
            }
            .up {
              color: #EC4747;
            }
            .down {
              color: #54D733;
            }
            &:first-child {
              margin-bottom: 15px;
            }
          }
        }
        &:last-child {
          border: none;
        }
      }
    }
  }
</style>