<template>
  <span class="u-icon" @click="click">
    <i class="iconfont" :class="'icon-' + name" :style="{ color: color, fontSize: size }"></i>
  </span>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '14px'
    },
    color: {
      type: String,
      default: '#222222'
    }
  },
  methods:{
    click() {
      this.$emit('click', true)
    },
  }
}
</script>

<style lang="scss" scoped>
  .u-icon{
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>