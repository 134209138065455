<template>
<div v-if="delTable">
	<div class="dynamic-centent">
		<div class="dynamic-main">
			<div class="dynamic-info">
				<!-- 左侧头像 -->
				<div class="left-heard" @click.stop="isshow = !isshow">
					<img-head v-if="item.avatarUrl" :avatarUrl="item.avatarUrl" :Radius="50" :size="50"></img-head>	
				</div>
				<!-- 头侧动态-内容 -->
				<div class="right-info">
					<!-- 动态-顶部 -->
					<div class="info-top">
						<div class="info-name">
							<div class="name">
								<span class="left-name">{{ item.nickname }}</span>
							</div>
							<p class="time">{{ formatTime(item.createTime) }}</p>
						</div>
						<div v-if="showAttention"></div>
						<el-popover
							placement="bottom"
							width="60"
							trigger="hover"
							v-else>
							<div class="message-popover" v-if="isRecommendNews">
								<div class="message-popover-item" @click="unLikeNews">不感兴趣</div>
								<div class="message-popover-item" @click="reportNews">投诉</div>
							</div>
							<div class="message-popover" v-else-if="(isClubNews && canEditNews) || (isClubNews && userId === item.userId)">
								<div class="message-popover-item" @click="deleteNews">删除动态</div>
								<!-- <div class="message-popover-item" @click="reportNews">投诉</div> -->
							</div>
							<div class="message-popover" v-else-if="isClubNews && !canEditNews">
								<div class="message-popover-item" @click="reportNews">投诉</div>
							</div>
							<div class="message-popover" v-else-if="!isClubNews && userId === item.userId">
								<div class="message-popover-item" @click="deleteNews">删除这条动态</div>
								<div class="message-popover-item" @click="turnAccessRole">转为私密</div>
							</div>
							<div class="message-popover" v-else>
								<div class="message-popover-item" @click="reportNews">投诉</div>
							</div>
							<div class="drop-icon" slot="reference">
								<icon-font name="arrow-down" color="#b1b1b1" size="14px"></icon-font>
							</div>
						</el-popover>
					</div>
					<!-- 详情-图片-视频 -->
					<div class="info-main">
						<div class="mian-text">
							<p class="moment-title">{{ item.title }}</p>
							<div class="commeny-box" id="my-coment" >
								<div class="coment-text" ref="desc" :style="{'max-height': statusMore? textHeight: ''}" :class="{statusText:statusMore}">
									<div v-for="(item, index) in contentList" :key="index">
										<span class="text" v-if="item.type == 1 && item.content">{{ item.content ? item.content : ''}}</span>
										<span class="topic" v-if="item.type == 2">{{ item.content ? item.content : '' }}</span>
										<span class="topic" v-if="item.type == 3">{{ item.content ? item.content : '' }}</span>
										<br v-if="item.type == 4">
									</div>
								</div>
								<div class="text-more" v-if="idShowText">
									<span v-if="statusMore" @click="statusMore =!statusMore">展开</span>
									<span v-else @click="statusMore =!statusMore">收起</span>
								</div>
							</div>
						</div>
						<!-- 图片 视频-->
						<div class="main-img">
							<!-- 一张 -->
							<div class="main-img-type" v-if="newImgArr.length === 1">
								<div class="img-item one" v-if="newImgArr[0].isImage" @click="openBigImg(0)">
									<img-card class="moment-img" :item="item.urlResponseVoList[0]"></img-card>
								</div>
								<div class="img-item one" v-else>
									<div class="img-box">
										<img-card class="moment-img" :item="item.urlResponseVoList[0]" ></img-card>
										<div class="mask-play" @click="openBigImg(0)"><icon-font class="play-icon" name="play" size="60px" color="#fff"></icon-font></div>
									</div>
								</div>
							</div>
							<!-- 二和四张 -->
							<div class="main-img-type" v-else-if="item.urlResponseVoList.length === 2 || item.urlResponseVoList.length === 4">
								<div class="two" v-for="(items, index) in newImgArr" :key="index">
									<div class="img-item" v-if="items.isImage" @click="openBigImg(index)">
										<img-card class="moment-img" :item="item.urlResponseVoList[index]"></img-card>
									</div>
									<div class="img-item" v-else>
										<div class="img-box">
											<img-card class="moment-img" :item="item.urlResponseVoList[index]"></img-card>
											<div class="mask-play" @click="openBigImg(index)"><icon-font class="play-icon" name="play" size="60px" color="#fff"></icon-font></div>
										</div>
									</div>
								</div>
							</div>
							<!-- 三五六九张 -->
							<div class="main-img-type" v-else>
								<div class="all" v-for="(items, index) in newImgArr" :key="index">
									<div class="img-item" v-if="items.isImage" @click="openBigImg(index)">
										<img-card class="moment-img" :item="item.urlResponseVoList[index]"></img-card>
									</div>
									<div class="img-item" v-else>
										<div class="img-box">
											<img-card class="moment-img" :item="item.urlResponseVoList[index]"></img-card>
											<div class="mask-play" @click="openBigImg(index)"><icon-font class="play-icon" name="play" size="60px" color="#fff"></icon-font></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 底部收藏关注评论分享 -->
			<div class="info-bottom">
				<div class="func-icon" @click.stop="isshow = !isshow">
					<icon-font class="liked" name="heart" size="20px" color="#444444" v-if="item.isLike === 2"></icon-font>
					<icon-font class="unliked" name="heart-fill" size="20px" color="#de5644" v-else></icon-font>
					<span class="icon-text">{{ item.likeCount > 0 ? item.likeCount : "点赞" }}</span>
				</div>
				<div class="func-icon" @click.stop="isshow = !isshow">
					<el-image
					class="pic-icon"
					:src="require('@/assets/chating.png')"
					fit="cover"
					></el-image>
					<span class="icon-text">{{ item.commentCount > 0 ? item.commentCount : "评论" }}</span>
				</div>
				<div class="func-icon" @click.stop="isshow = !isshow">
					<icon-font class="liked" name="star" size="20px" color="#444444" v-if="item.isCollect === 2"></icon-font>
					<icon-font class="unliked" name="star-fill" size="20px" color="#f7b500" v-else></icon-font>
					<span class="icon-text">{{ item.collectCount > 0 ? item.collectCount : "收藏" }}</span>
				</div>
			</div>
			<club-complaint :item="this.item" :flag="iscomplaint" @close="closeComplaint" ></club-complaint>
		</div>
    <download-app :flag="isshow" @close="closeQRcode"></download-app>
		<tip-popup title="确定要删除此动态吗？" :isOpen="isOpen" @close="closePopup" @confirm="confirm"></tip-popup>
		<big-img-video :z-index="2000" :initial-index="imageIndex" v-if="isShowBigImg"  :url-list="newImgArr" :on-close="closeViewer"></big-img-video>
	</div>
</div>	
</template>

<script>
	import downloadApp from '../components/download-app.vue'
	import clubComplaint from '../components/club/club-complaint.vue'
	import bigImgVideo from '../components/big-img-video/big-img-video'
	import iconFont from './IconFont.vue'
	import imgCard from './img-card.vue'
	import imgHead from './img-head.vue'
	import tipPopup from './tip-popup.vue'
	let times = 0																			// 动态创建的时间戳
	let timesCalc = 0																	// 动态创建时间和当前时间的差值
	let prevOverflow = ''
	export default {	
	data() {	
		return {	
			isOpen: false,
      isshow: false,
			info: '',
			userId: '',																		// 当前用户id
			isImage: true,																// 是否图片动态
			contentList: [],															// 动态文本字符串列表
			videoLink: '',																// 视频链接
			newImgArr:[],																	// 图片数组
			delTable: true,																// 是否假性删除
			isMoment: false,															// 是否来自Moment组件
			iscomplaint: false,	
			isShowBigImg: false,													// 预览大图
			imageIndex: 0,																// 预览索引
			textHeight: null,															// 文本高度
			statusMore: false,														// 三行或者多行
			idShowText: false,														// 显示更多按钮
		}
	},
	
	props: {
		item: {
			type: Object,
			default: () => {}
		},

		isClubNews: {
			type: Boolean,
			default: false
		},

		canEditNews: {
			type: Boolean,
			default: false
		},

		isRecommendNews: {
			type: Boolean,
			default: false
		},

		showAttention: {
			type: Boolean,
			default: false			
		}
	},
	
	components: {
		iconFont,
		imgCard,
		imgHead,
		clubComplaint,
		downloadApp,
		tipPopup,
		bigImgVideo
	},
	
	mounted() {
		if(this.$store.state.userInfo) {
			this.userId = this.$store.state.userInfo.id
		}
		this.handleContent()
		this.handleImage()
		if(this.$parent.isMoment) {
			this.isMoment = true
		}
    document.addEventListener('click', this.HiddenClick,true)
	},
	beforeDestroy(){
    document.removeEventListener('click', this.HiddenClick,true)
  },
	
	methods: {
    // 点击页面事件 隐藏需要隐藏的区域
    HiddenClick () {
      this.isshow = false
    },
		
		// 删除动态
		async deleteNews(){
			this.isOpen = true
		},

		// 转为私密
		async turnAccessRole() {
			const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      })
			let data = {
				accessRole: this.item.accessRole === 1 ? 2 : 1,
				id: this.item.newsId
			}
			try{
				await this.$request.post('/newsInfo/editNews', data)
				this.$message({
					message: '设置成功',
					type: 'success'
				})
				this.delTable = false
			}catch(e){
				// uni.hideLoading();
			}
			loading.close()
		},

		// 投诉
		reportNews() {
			this.iscomplaint = true
		},

		// 不感兴趣
		async unLikeNews(){
			const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      })
			try{
				let data = { dislikeId: this.item.userId, dislikeType: 1 }
				await this.$request.post('/userDislike/dislike', data)
				this.delTable = false
				this.$message({
					message: '操作成功，将减少此类推荐',
					type: 'success'
				})
			}catch(e){
				// uni.hideLoading()
			}
			loading.close()
		},
		
		// 处理图片链接
		handleImage(){
			let imgArr = JSON.parse(JSON.stringify(this.item.urlResponseVoList))
			let newImgArr = []
			timesCalc = new Date().getTime() - times * 1000
			let newUrl
			let isImage = true
			let urlVideo = null 
			let urlImg = null
			let isTrue = 1
			imgArr.forEach((item,index )=> {
				newUrl = item.url
				if(item.cover){
					newUrl = `${item.cover}`
					isImage = false
					urlVideo = this.handleVideo(index)
					isTrue = item.fileStatus
				}
				else if (item.url.indexOf('.mp4') > 0) {
					newUrl = `${newUrl.replace(/(\..{3})$/, '_cover_0.jpg')}`
					isImage = false
					urlVideo = this.handleVideo(index)
					isTrue = item.fileStatus
				} else {
					newUrl = `${newUrl}`
					urlImg = this.$cosUrl + newUrl
					isImage = true
					isTrue = item.fileStatus
				}
				if (timesCalc < 300000) {
					newUrl += '&time=' + new Date().getTime()
					urlImg = this.$cosUrl + newUrl
				}
				else if(timesCalc >= 300000 && timesCalc <= 86400000){
					newUrl += '&time=' + new Date().getTime()
					urlImg = this.$cosUrl + newUrl
				}
				newImgArr.push({newUrl: newUrl, isImage: isImage, urlVideo: urlVideo, urlImg: urlImg, fileStatus:isTrue})
			})
			this.newImgArr = newImgArr
		},

		//处理视频链接
		handleVideo(index){
			let time = new Date().getTime() - times * 1000;
			let videoLink = '';
			if (time < 300000) {
				videoLink = this.$cosUrl + this.item.urlResponseVoList[index].url + '?time=' + new Date().getTime();
			} 
			else if(time >= 300000 && time <= 86400000){
				videoLink = this.$cosUrl + this.item.urlResponseVoList[index].url + '?time=' + new Date().getTime();
			}
			else {
				videoLink = this.$cosUrl + this.item.urlResponseVoList[index].url
			}
			return videoLink
		},

		// 点击图片预览大图
		openBigImg(index){
			prevOverflow = document.body.style.overflow;
			document.body.style.overflow = 'hidden';
			this.isShowBigImg = !this.isShowBigImg
			this.imageIndex = index
		},

		// 关闭图片预览
		closeViewer() {
			document.body.style.overflow = prevOverflow
			this.isShowBigImg = false
		},
		
		// 处理文本内容
		handleContent(){
			if(this.item.content.length<=0){
				return
			}
			let content = this.item.content
			let userAtList = this.item.userAtResponseVoList?this.item.userAtResponseVoList: []
			let myTopic = new RegExp(/(#(?!#)|@\$bxw\{)[\S]{1,}[\s]{1}/gi)  // 话题正则
			let atUserReg = new RegExp(/@\$bxw{[0-9]*\}bxw\$\s{1}/gi) 	// 好友正则
			let atIdReg = new RegExp(/[0-9]*/gi)												// Id正则
			let brReg = new RegExp(/\n|\r/gi) 													// 换行或回车正则
			let strList = []																						// 描述拆分数组
			let brArr = []																							// 替换换行符
			let end = 0																									// 记录当前话题结束位置
			let allNo = false																						// 没有配到用户
			let lastTopic = ''																					// 记录最后一个话题
			let textContent = ''																				// 存放话题文本
			if(!content) return
			if(content.match(myTopic) != null) {
				do{
					let topicArr = myTopic.exec(content)
					if(topicArr != null) {
						if(topicArr.index != 0) {
							strList.push({type:1,content:topicArr.input.substring(end,topicArr.index)})
						}
						textContent = topicArr[0].match(atUserReg)
						if(textContent != null) {
							do{
								let atArr = atUserReg.exec(topicArr[0])
								if(atArr != null) {
									if(atArr[0].length == atArr.input.length) {
										let userId =''
										atArr[0].match(atIdReg).forEach(item=>{
											if(item){
												userId = item
											}
										})
										userAtList.forEach(item => {
											if(item.userId == userId) {
												allNo = true
												strList.push({type:3,content:'@'+item.nickname+' ',userId:item.userId})
											}
										})
										if(!allNo) {
											strList.push({type:1,content:atArr[0]})
										}
										
									} else {
										let newStr = atArr.input.split(atArr[0])
										strList.push({type:1,content:newStr[0]})
										let userId =''
										atArr[0].match(atIdReg).forEach(item=>{
											if(item){
												userId = item
											}
										})
										userAtList.forEach(item => {
											if(item.userId == userId) {
												allNo = true
												strList.push({type:3,content:'@'+item.nickname+' ',userId:item.userId})
											}
										})
										if(!allNo) {
											strList.push({type:1,content:atArr[0]})
										}
									}
								} else {
									break
								}
							} while(true)
						} else {
							let lastItem = topicArr[0].charAt(topicArr[0].length-1,1)
							// 判断是否是换行结束
							if(!/\n|\r/.test(lastItem)) {
								strList.push({type:2,content:topicArr[0]})
							} else {
								strList.push({type:1,content:topicArr[0]})
							}
						}
						end = myTopic.lastIndex
						lastTopic = topicArr[0]
					} else {
						if (end != content.length) {
							let lastStr = content.split(lastTopic)
							strList.push({type:1,content:lastStr[1]})
						}
						break
					}
				} while( true )
			} else {
				strList.push({type:1,content:content})
			}
			// 再次划分换行
			for (let i = 0 ; i< strList.length; i++) {
				if (strList[i].type == 1) {
					let brContent = strList[i].content
					let brEnd = 0
					if (brContent && brContent.match(brReg) != null) {
						do {
							let brTypeArr = brReg.exec(brContent)
							if (brTypeArr != null) {
								if(brTypeArr.index != 0) {
									brArr.push({type:1,content:brTypeArr.input.substring(brEnd,brTypeArr.index)})
								}
								brArr.push({type:4,content:brTypeArr[0]})
								brEnd = brReg.lastIndex-1
							} else {
								if (brEnd != brContent.length) {
									brArr.push({type:1,content:brContent.substring(brEnd,)})
								}
								break
							}
						} while (true)
					} else {
						// 没有换行
						brArr.push(strList[i])
					}
				} else {
					// 其他类型
					brArr.push(strList[i])
				}
			}
			this.contentList = brArr
			this.$nextTick(()=>{
				this.calculateText()
			})
		},
		
		formatTime(str){
			// 将时间转化为秒时间戳
			let time = new Date(str.substring(0,19).replace(/-/g, "/")).getTime() / 1000
			return this.$beautifyTime(time)
		},
		
		// 计算是否展示更多按钮
		calculateText(){
			let threeHeight = 21 * 4
			this.textHeight = `${threeHeight}px`
			let divObj = this.$refs.desc
			if(divObj && divObj.offsetHeight > threeHeight) {
				this.statusMore = true
				this.idShowText =  true
			} else {
				this.statusMore = false
				this.idShowText = false
			}
		},

		//处理投诉
		closeComplaint() {
			this.iscomplaint = false
		},

		//关闭下载二维码
		closeQRcode() {
			this.isshow = false
		},

		//关闭删除动态弹窗
		closePopup(){
			this.isOpen = false
		},

		//确定删除动态
		async confirm(){
			const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      })
			try{
				let data = {clubId: this.item.clubId ? this.item.clubId : 0, id: this.item.newsId}
				await this.$request.post(`/newsInfo/removeNews`, data)
				this.$message({
					message: '动态已删除',
					type: 'success'
				})
				this.delTable = false
			}catch(e){
				//TODO handle the exception
			}
			loading.close()
		}
	}
	};
</script>

<style lang="scss" scoped>
	.dynamic-centent {
		width: 790px;
		margin-bottom: 15px;
		box-sizing: border-box;
		.dynamic-main{
			background: #fff;
			border-radius: 5px;
			box-sizing: border-box;
		}
		.dynamic-info{
			display: flex;
			padding: 20px 20px 15px;
			box-sizing: border-box;
			.left-heard {
				width: 50px;
				height: 50px;
				border-radius: 50%;
				margin-right: 15px;
				cursor: pointer;
			}
			.right-info {
				display: flex;
				flex:1;
				flex-direction: column;
				box-sizing: border-box;
				.info-top {
					display: flex;
					align-items: center;
					.info-name {
						flex: 1;
						.name {
							display: flex;
							align-items: center;
							justify-content: space-between;
							font-size: 14px;
							font-weight: bold;
							color: #444;
						}
						.time {
							font-size: 12px;
							color: #444;
							margin-top: 2px;
						}
					}
					.focus{
						width: 70px;
						height: 27px;
						background: #FFFFFF;
						border-radius: 15px;
						border: 1px solid #1465CF;
						text-align: center;
						padding-top: 4px;
						color: #1465CF;
						cursor: pointer;
					}
					.drop-icon-box{
						position: relative;
						padding-right: 10px;
						.drop-icon {
							cursor: pointer;
							transition: all 0.3s cubic-bezier(0.49, -0.3, 0.68, 1.23);
						}
						.rotate{
							transform: rotate(180deg) !important;
						}
						.show-dele {
							width: 220px;
							height: 0;
							position: absolute;
							top: 39px;
							left: 50%;
							transform: translateX(-50%);
							z-index: 2;
							background: #ffffff;
							border-radius: 20px;
							overflow: hidden;
							box-sizing: border-box;
							transition: all 0.3s cubic-bezier(0.49, -0.3, 0.68, 1.23);
							.dele-text {
								width: 100%;
								height: 50px;
								font-size: 16px;
								color: #0a0a0a;
								cursor: pointer;
								display: flex;
								align-items: center;
								justify-content: center;
								transition: all 0.2s;
							}
							.dele-text:hover{
								background: rgba(0, 95, 175, 0.1);
							}
						}
						.active-complaint {
							height: auto;
							width: 114px;
							box-shadow: -2px 3px 14px 8px rgba(0, 0, 0, 0.07);
							border-radius: 5px;
						}
					}
				}
				.info-main {
					padding-top: 4px;
					padding-right: 70px;
					box-sizing: border-box;
					.mian-text {
						color: #000;
						margin-bottom: 10px;
						box-sizing: border-box;
						.moment-title{
							font-size: 18px;
							margin-bottom: 6px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
							box-sizing: border-box;
						}
						.commeny-box{
							width: 100%;
							// display:flex;
							margin:0px;
							.statusText{
								-webkit-line-clamp:4;
							}
							.coment-text{
								width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								box-sizing: border-box;
								line-height: 21px;
								.topic {
									color: #005faf;
									font-weight: bold;
									margin-right: 2px;
									font-size: 15px;
									white-space:normal;
									word-break:break-all;
								}
								.text {
									color: #222;
									font-size: 15px;
									white-space:normal;
									word-break:break-all;
								}
								.all-line{
									width: 100%;
									box-sizing: border-box;
									height: 10px;
								}
							}
							.text-more{
								height: auto;
								display: flex;
								align-items: flex-end;
								color:#4c8adb;
								font-size: 15px;
								font-weight: bold;
								flex-shrink: 0;
								cursor: pointer;
							}
						}
					}
					.main-img {
						width: 100%;
						box-sizing: border-box;
						.main-img-type {
							width: 100%;
							display: flex;
							flex-wrap: wrap;
							.one {
								width: 100%;
								max-height: 300px;
								border-radius: 5px;
								cursor: pointer;
								overflow: hidden;
								box-sizing: border-box;
								.img-box{
									width: 100%;
									height: 100%;
									box-sizing: border-box;
									border-radius: 5px;
									position: relative;
									.mask-play{
										width: 100%;
										height: 100%;
										box-sizing: border-box;
										border-radius: 5px;
										position: absolute;
										left: 0px;
										top:0px;
										.play-icon{
											position: absolute;
											top: 50%;
											left: 50%;
											transform: translate(-50%, -50%);
											z-index: 1;
										}
									}
								}
								.video-box{
									width: 100%;
									height: 100%;
									box-sizing: border-box;
									border-radius: 5px;
								}
								.video-box{
									width: 100%;
									height: 100%;
									box-sizing: border-box;
									border-radius: 5px;
									.moment-video{
										width: 100%;
										height: 300px;
										display: flex;
										align-items: center;
										justify-content: center;
										background-color: #F8F8F8;
									}
								}
							}
							.two {
								width: calc((100% - 10px) / 2);
								height: 233px;
								border-radius: 5px;
								cursor: pointer;
								overflow: hidden;
								box-sizing: border-box;
								margin-top: 10px;
								.img-item{
									width: 100%;
									height: 100%;
									box-sizing: border-box;
									border-radius: 5px;
									.img-box{
										width: 100%;
										height: 100%;
										box-sizing: border-box;
										border-radius: 5px;
										position: relative;
										.mask-play{
											width: 100%;
											height: 100%;
											box-sizing: border-box;
											border-radius: 5px;
											position: absolute;
											left: 0px;
											top:0px;
											.play-icon{
												position: absolute;
												top: 50%;
												left: 50%;
												transform: translate(-50%, -50%);
												z-index: 1;
											}
										}
									}
									.video-box{
										width: 100%;
										height: 100%;
										box-sizing: border-box;
										border-radius: 5px;
									}
									.video-box{
										width: 100%;
										height: 100%;
										box-sizing: border-box;
										border-radius: 5px;
										.moment-video{
											width: 100%;
											height: 300px;
											box-sizing: border-box;
											display: flex;
											align-items: center;
											justify-content: center;
											background-color: #F8F8F8;
										}
									}
								}
							}
							.two:nth-child(2n) {
								margin-left: 10px;
							}
							.all{
								width: calc((100% - 20px) / 3);
								height: 160px;
								border-radius: 5px;
								cursor: pointer;
								overflow: hidden;
								margin-top: 10px;
								box-sizing: border-box;
								.img-item{
									width: 100%;
									height: 100%;
									box-sizing: border-box;
									border-radius: 5px;
									.img-box{
										width: 100%;
										height: 100%;
										box-sizing: border-box;
										border-radius: 5px;
										position: relative;
										.mask-play{
											width: 100%;
											height: 100%;
											box-sizing: border-box;
											border-radius: 5px;
											position: absolute;
											left: 0px;
											top:0px;
											.play-icon{
												position: absolute;
												top: 50%;
												left: 50%;
												transform: translate(-50%, -50%);
												z-index: 1;
											}
										}
									}
									.video-box{
										width: 100%;
										height: 100%;
										box-sizing: border-box;
										border-radius: 5px;
									}
									.video-box{
										width: 100%;
										height: 100%;
										box-sizing: border-box;
										border-radius: 5px;
										.moment-video{
											width: 100%;
											height: 160px;
											display: flex;
											align-items: center;
											justify-content: center;
											background-color: #F8F8F8;
										}
									}
								}
							}
							.all:nth-child(2),
							.all:nth-child(3),
							.all:nth-child(5),
							.all:nth-child(6),
							.all:nth-child(8),
							.all:nth-child(9){
							margin-left: 10px;
							}
							.moment-img{
								width: 100%;
								height: 100%;
								display: block;
							}
						}
						.for-box{
							width: 100%;
							height: 100%;
							box-sizing: border-box;
							border-radius: 5px;
							.one {
								width: 100%;
								max-height: 300px;
								border-radius: 5px;
								cursor: pointer;
								overflow: hidden;
								box-sizing: border-box;
							}
							.two {
								width: calc((100% - 10px) / 2);
								height: 233px;
								border-radius: 5px;
								cursor: pointer;
								overflow: hidden;
								box-sizing: border-box;
								margin-top: 10px;
							}
							.two:nth-child(2n) {
								margin-left: 10px;
							}
							.all{
								width: calc((100% - 20px) / 3);
								height: 160px;
								border-radius: 5px;
								cursor: pointer;
								overflow: hidden;
								margin-top: 10px;
								box-sizing: border-box;
							}
							.all:nth-child(2),
							.all:nth-child(3),
							.all:nth-child(5),
							.all:nth-child(6),
							.all:nth-child(8),
							.all:nth-child(9){
							margin-left: 10px;
							}
							.img-item{
								.img-box{
									width: 100%;
									height: 100%;
									box-sizing: border-box;
									border-radius: 5px;
									position: relative;
									.play-icon{
										position: absolute;
										top: 50%;
										left: 50%;
										transform: translate(-50%, -50%);
										z-index: 1;
									}
								}
								.video-box{
									width: 100%;
									height: 100%;
									box-sizing: border-box;
									border-radius: 5px;
								}
								.video-box{
									.moment-video{
										width: 100%;
										height: 300px;
										display: flex;
										align-items: center;
										justify-content: center;
										background-color: #F8F8F8;
									}
								}
							}
						}
					}
				}
			}
		}
		.info-bottom {
			width: 100%;
			height: 50px;
			display: flex;
			align-items: center;
			border-top: 0.5px solid #DDD;
			.func-icon {
				flex: 1;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-right: 1px solid #DDD;
				cursor: pointer;
				.icon-text {
					font-size: 12px;
					color: #444;
					margin-left: 5px;
				}
				.pic-icon{
					width: 25px;
					height: 25px;
				}
			}
			.func-icon:nth-child(3){
				border-right: 1px solid #ffffff !important;
			}
			.share-box{
				position: absolute;
				top:47px;
				right:0px;
				background: #FFFFFF;
				box-shadow: -2px 3px 14px 8px rgba(0, 0, 0, 0.07);
				box-sizing: border-box;
				z-index: 99;
				.share-top{
					width: 100%;
					display: flex;
					padding:13px 19px 8px 22px;
					box-sizing: border-box;
					align-items: center;
					justify-content:space-between;
					.share-left{
						display: flex;
						align-items: center;
						.user-img{
							width: 53px;
							height: 53px;
							border-radius: 50%;
							margin-right: 10px;
						}
						.left-name{
							font-size: 20px;
							color:#000;
						}
					}
				}
				.share-bottom{
					margin:24px 36px;
					display: flex;
					align-items: center;
					.min-wx{
						margin-right:28px;
						width: 89px;
						height: 89px;
					}
					.share-info{
						.info-title{
							font-size: 18px;
							color:#000;
						}
						.info-text{
							padding-top:5px;
							font-size: 16px;
							color: rgba(0, 0, 0, 0.5);
						}
					}
				}
			}
		}
	}
	.message-popover {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.message-popover-item {
			margin-bottom: 10px;
			text-align: center;
			cursor: pointer;
			font-size: 14px;
			color: #444;
		}
		.message-popover-item:last-child {
			margin-bottom: 0;
		}
		.message-popover-item:hover {
			color: #1465CF;
		}
	}
	.img-big-box{
		width: 100%;
		height: auto;
	}
</style>