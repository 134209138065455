<template>
	<div class="club-data">
		<div class="tabs one">
			<div class="member-card">
				<div class="card-title">活动参与情况</div>
				<div class="card-main chart-box">
					<div>
						<div class="top-choice">
							<div class="choice-left">
								<div class="statics-content">统计内容</div>
								<div class="mychioce">
									<el-select  v-model="statisticalContent" placeholder="请选择">
										<el-option
											@click.native="changes(item.value)"
											v-for="item in options"
											:key="item.value"
											:label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</div>
							</div>
							<div class="choice-middle">
								<div class="day7-button" @click="goto(0)" :class="this.current===0 ? 'active' : ''">近7天</div>
								<div class="day14-button" @click="goto(1)" :class="this.current===1 ? 'active' : ''">近14天</div>
								<div class="day30-button" @click="goto(2)" :class="this.current===2 ? 'active' : ''">近30天</div>
							</div>
							<div class="choice-right">
								<div class="choice-time">时间</div>
								<div style="width:195px">
									<el-date-picker
										v-model="mychoiceTimes"
										type="daterange"
										range-separator="—"
										start-placeholder="开始日期"
										end-placeholder="结束日期">
									</el-date-picker>
								</div>
								<div class="search-button" @click="searchData">查询</div>
							</div>
						</div>
						<div class="chart-title">
							<div class="left">当前时间段俱乐部共有{{ this.activityCount }}个活动</div>
						</div>
					</div>
					<div class="chart-icon" id="main" ></div>
					<div class="chart-icon" id="mains"></div>
				</div>
				<div class="chart-bottom" v-if="this.value == 0">
					<div class="num-title">
					<span class="line-color"></span>参与活动
					</div>
					<div class="num-title">
						<span class="line-color"></span>签到活动
					</div>
					<div class="x-number">次数</div>
					<div class="titles-intro">*无数据的俱乐部成员暂不展示</div>
				</div>
				<div class="chart-bottom" v-else>
					<div class="num-title">
						<div class="line-color"></div>签到率
					</div>
					<div class="x-rate">签到率</div>
					<div class="title-intro">*签到率=签到活动数/活动总数</div>
					<div class="title-intros">*无数据的俱乐部成员暂不展示</div>
				</div>
				<div class="bottom-info">
				</div>
			</div>
			<div class="member-card">
				<div class="card-title">成员动态情况</div>
				<div class="card-main chart-box">
					<div >
						<div class="top-choice">
							<div class="choice-middle" style="margin-left: 5px">
								<div class="day7-button" @click="MemberGoto(0)" :class="this.memberCurrent===0 ? 'active' : ''">近7天</div>
								<div class="day14-button" @click="MemberGoto(1)" :class="this.memberCurrent===1 ? 'active' : ''">近14天</div>
								<div class="day30-button" @click="MemberGoto(2)" :class="this.memberCurrent===2 ? 'active' : ''">近30天</div>
							</div>
							<div class="choice-right">
								<div class="choice-time">时间</div>
								<div style="width:195px">
									<el-date-picker
										v-model="mychoiceMemberTimes"
										type="daterange"
										range-separator="—"
										start-placeholder="开始日期"
										end-placeholder="结束日期">
									</el-date-picker>
								</div>
								<div class="search-button" @click="MemberSearchData">查询</div>
							</div>
						</div>
					</div>
					<div class="chart-icon" id="member" style="width: 696px;min-height:300px"></div>
				</div>
				<div class="chart-bottom">
					<div class="num-title">
					<span class="line-color"></span>动态被点赞数
					</div>
					<div class="num-title">
						<span class="line-color"></span>发表动态数
					</div>
					<div class="x-number">个数</div>
					<div class="titles-intro">*无数据的俱乐部成员暂不展示</div>
					<div class="rate-intro">*统计的动态为俱乐部成员发布的所有动态</div>
				</div>
				<div class="bottom-info">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import 'echarts/lib/chart/bar'
	export default {
		data() {
			return {
				grow: false,
				clubData: {},						  											// 俱乐部管理数据
				EchartsList: [],																// 活动数组
				clubRoles: true,																// 数据管理权限
				statisticalContent: "活动参与情况",  						 // 统计内容选择
				value: 0,                                     	// 统计内容选择默认0
				mychoiceTimes: null,                          	// 我选择的活动参与情况日期区间
				mychoiceMemberTimes: null,                    	// 我选择的活动参与情况日期区间
				options: [{
          value: 0,
          label: '活动参与情况'
        }, {
          value: 1,
          label: '签到率'
				}],
				activityCount: 0,           										// 活动总数
				newsCount: 0,           												// 成员动态总数
				current: 0,                                   	// 活动默认高亮近7天
				selectType:1,                                 	// 活动默认查询近7天
				memberCurrent: 0,                             	// 成员默认高亮近7天
				memberSelectType:1,                           	// 成员默认查询近7天
				noneActivity: false,                          	// 暂无活动
				noneMemberDynamic: false,                     	// 暂无成员动态
			}
		},

		mounted() {
			this.getMyClubRole()
			this.getEventParticipationa()
			this.getMemebrSituation()
		},

		methods: {
			//获取用户在俱乐部的权限
			async getMyClubRole() {
				try {
					let res = await this.$request.get(`/clubMemberInfo/getMyRole/${this.$route.params.clubId}`)
					let data = {
						clubId: this.$route.params.clubId,
						role: 0,
						canEditNews: false,
						canEditActivity: false,
						canEditMember: false,
						canEditTheme: false,
						canEditData: false,
						canEditClub: false,
						canActivityData: false,
                        canManagerSuperShare: false,
					}
					if(res.data.role) {
						this.myRole = res.data.role
						this.clubRoles = res.data.roles
						data.role = res.data.role
						for(let i = 0; i < res.data.roles.length; i++) {
							if(res.data.roles[i].roleId == 2) {
								// 俱乐部动态管理
								data.canEditNews = true
							}
							else if(res.data.roles[i].roleId == 3) {
								// 俱乐部活动管理
								data.canEditActivity = true
							}
							else if(res.data.roles[i].roleId == 4) {
								// 俱乐部成员管理
								data.canEditMember = true
							}
							else if(res.data.roles[i].roleId == 5) {
								// 俱乐部相册管理
								data.canEditTheme = true
							}
							else if(res.data.roles[i].roleId == 6) {
								// 编辑俱乐部信息
								data.canEditClub = true
							}
							else if(res.data.roles[i].roleId == 7) {
								// 查看俱乐部数据
								data.canEditData = true
							}
							else if (res.data.roles[i].roleId == 10) {
                                // 查看活动数据
                                data.canActivityData = true
                            }
                            else if (res.data.roles[i].roleId == 11) {
                                // 超级分享者管理
                                data.canManagerSuperShare = true
                            }
						}
					}
					this.$store.commit('SET_CLUBROLES', data)
				} catch (error) {
					//
				}
			},

			// 活动便捷按钮
			goto(e) {
				this.mychoiceTimes = null
				if(this.value==0){
					if (e===0) {
						this.current = 0
						this.selectType = 1
						this.getEventParticipationa()
					}
					if (e===1) {
						this.current = 1
						this.selectType = 2
						this.getEventParticipationa()
					}
					if (e===2) {
						this.current = 2
						this.selectType = 3
						this.getEventParticipationa()
					}
				}else{
					if (e===0) {
						this.current = 0
						this.selectType = 1
						this.getParticipationRate()
					}
					if (e===1) {
						this.current = 1
						this.selectType = 2
						this.getParticipationRate()
					}
					if (e===2) {
						this.current = 2
						this.selectType = 3
						this.getParticipationRate()
					}
				}

			},

			// 成员动态便捷按钮
			MemberGoto(e) {
				this.mychoiceMemberTimes = null
				if (e===0) {
					this.memberCurrent = 0
					this.memberSelectType = 1
					this.getMemebrSituation()
				}
				if (e===1) {
					this.memberCurrent = 1
					this.memberSelectType = 2
					this.getMemebrSituation()
				}
				if (e===2) {
					this.memberCurrent = 2
					this.memberSelectType = 3
					this.getMemebrSituation()
				}
			},

			// 筛选统计内容类型
			changes(value) {
				if(value==0){
					this.value = 0
					this.getEventParticipationa()
				}
				if(value==1){
					this.value = 1
					this.getParticipationRate()
				}
			},

			// 成员动态空白表格
			blankEcharts(yList,enrollCount,signCount) {
				let chart = document.getElementById('member')
				// chart.setAttribute("style","width: 696px;height:300px");
				let clubChart = this.$echarts.init(chart)
				var option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor:'rgba(0,0,0,0.5)',
						textStyle: {
							color: "#fff"
						},
						padding:15,
						borderWidth:'0',
						axisPointer: {
							type: 'shadow'
						},
					formatter: function (datas) {
						var res = datas[0].name + '<br/>'
						for (var i = 0, length = datas.length; i < length; i++) {
							res += datas[i].marker + " " + datas[i].value + "次" + '<br/>'
						}
						return res
					}
					},
					legend: {},
					grid: {
						left: '3%',
						right: '10%',
						// top: '10%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: {
						type: 'value',
						// name: '次数',
						nameLocation: 'end',
						nameTextStyle: {
							padding: [-100,0,-1000,10]
						},
						boundaryGap: [0, 0.01],
						splitLine:{
							show: true,
							lineStyle: {
								type:'dashed',
								color:'#ddd'
							}
						},
						minInterval: 1,
						splitNumber: 10,
						min:0,
						max:100,
						// dataMax: 'value',
						axisLabel: {}
					},
					yAxis: {
						name: '成员',
						nameTextStyle: {
							fontSize: 12,
							color: '#000'
						},
						type: 'category',
						data: yList,
						axisLabel: {
							color: "#333333"
						},
						axisLine: {
							lineStyle: {
								type:  'solid' ,
								color:  '#F1F4FB'	//左边线的颜色
							}
						},
					},
					series: [
						{type:'bar', color:'#013D6E', barWidth:9, data: enrollCount ,itemStyle: {
							normal: {
								barBorderRadius:[0,4,4,0]
							}
						}, barCategoryGap: 2},
						{type:'bar', color:'#FFAD2A', barWidth:9, data: signCount, itemStyle: {
							normal: {
								barBorderRadius:[0,4,4,0]
							}
						}, barCategoryGap: 2}
						]
				};
				clubChart.setOption(option)
			},

			// 活动动态空白表格
			blankActivityEcharts(yList,enrollCount,signCount) {
				let chart = document.getElementById('main')
				let clubChart = this.$echarts.init(chart)
				var option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor:'rgba(0,0,0,0.5)',
						textStyle: {
							color: "#fff"
						},
						padding:15,
						borderWidth:'0',
						axisPointer: {
							type: 'shadow'
						},
					formatter: function (datas) {
						var res = datas[0].name + '<br/>'
						for (var i = 0, length = datas.length; i < length; i++) {
							res += datas[i].marker + " " + datas[i].value + "次" + '<br/>'
						}
						return res
					}
					},
					legend: {},
					grid: {
						left: '3%',
						right: '10%',
						// top: '10%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: {
						type: 'value',
						// name: '次数',
						nameLocation: 'end',
						nameTextStyle: {
							padding: [-100,0,-1000,10]
						},
						boundaryGap: [0, 0.01],
						splitLine:{
							show: true,
							lineStyle: {
								type:'dashed',
								color:'#ddd'
							}
						},
						minInterval: 1,
						splitNumber: 10,
						min:0,
						max:100,
						// dataMax: 'value',
						axisLabel: {}
					},
					yAxis: {
						name: '成员',
						nameTextStyle: {
							fontSize: 12,
							color: '#000'
						},
						type: 'category',
						data: yList,
						axisLabel: {
							color: "#333333"
						},
						axisLine: {
							lineStyle: {
								type:  'solid' ,
								color:  '#F1F4FB'	//左边线的颜色
							}
						},
					},
					series: [
						{type:'bar', color:'#013D6E', barWidth:9, data: enrollCount ,itemStyle: {
							normal: {
								barBorderRadius:[0,4,4,0]
							}
						}, barCategoryGap: 2},
						{type:'bar', color:'#FFAD2A', barWidth:9, data: signCount, itemStyle: {
							normal: {
								barBorderRadius:[0,4,4,0]
							}
						}, barCategoryGap: 2}
						]
				};
				clubChart.setOption(option)
			},

			// 时间戳转换
			timestampToTime(timestamp){
				var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
				var Y = date.getFullYear() + '-';
				var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
				var D = date.getDate()+1 + ' ';
				var h = (date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':' ;
				var m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ':' ;
				var s = (date.getSeconds() < 10 ? '0'+(date.getSeconds()) : date.getSeconds());
				return Y+M+D+h+m+s;
			},

			// 获取活动参与情况
			async getEventParticipationa() {
				try{
					let timeStamp = Math.round((new Date()).valueOf());
					let list = []
					let yList = []
					let signCount = []
					let enrollCount = []
					let data = {
						clubId: this.$route.params.clubId,
						startTime: timeStamp,
						endTime: timeStamp,
						selectType: this.selectType,
					}
					let res = await this.$request.post(`/clubData/getActivityParticipationData`,data)
					this.clubData = res.data
					this.activityCount = res.data.activityCount
					if(this.activityCount==0){
						this.noneActivity = true
						yList.unshift("")
						enrollCount.unshift("0")
						signCount.unshift("0")
						this.EchartsList = list
						this.clubEcharts(yList, enrollCount, signCount)
					}else{
						for(let temp of res.data.resList){
								list.unshift(temp)
								yList.unshift(temp.nickname)
								enrollCount.unshift(temp.activityCount.length!=0?temp.activityCount : 0)
								signCount.unshift(temp.signCount.length!=0?temp.signCount : 0)
						}
						this.EchartsList = list
						this.clubEcharts(yList, enrollCount, signCount)
					}
				} catch(e) {
					this.clubRoles = false
				}
			},

			clubEcharts(yList,enrollCount,signCount) {
				let chart = document.getElementById('main')
				chart.setAttribute("style","width: 696px;min-height:300px");
				let charts = document.getElementById('mains')
				charts.style.display = "none"
				let clubChart = this.$echarts.init(chart)
				var option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor:'rgba(0,0,0,0.5)',
						textStyle: {
							color: "#fff"
						},
						padding:15,
						borderWidth:'0',
						axisPointer: {
							type: 'shadow'
						},
						formatter: function (datas) {
							var res = datas[0].name + '<br/>'
							for (var i = 0, length = datas.length; i < length; i++) {
								res += datas[i].marker + " " + datas[i].value + "次" + '<br/>'
							}
							return res
						}
					},
					legend: {},
					grid: {
						left: '3%',
						right: '10%',
						// top: '10%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: {
						type: 'value',
						boundaryGap: [0, 0.01],
						splitLine:{
							show: true,
							lineStyle: {
								type:'dashed',
								color:'#ddd'
							}
						},
						minInterval: 1,
						// splitNumber: 4,      //段数是4
						min:0,
						max: function (value) {
							if(value.max==0){
								return value.max + 100
							}else{
								return value.max;
							}
						},
						dataMax: 'value',
						axisLabel: {}
					},
					yAxis: {
						name: '成员',
						nameTextStyle: {
							fontSize: 12,
							color: '#000'
						},
						type: 'category',
						data: yList,
						axisLabel: {
							color: "#333333"
						},
						axisLine: {
							lineStyle: {
								type:  'solid' ,
								color:  '#F1F4FB'	//左边线的颜色
							},
							interval: 5
						},
					},
					series: [
						{type:'bar', color:'#013D6E', barWidth:9, data: enrollCount ,itemStyle: {
							normal: {
								barBorderRadius:[0,4,4,0]
							}
						}, barCategoryGap: 2},
						{type:'bar', color:'#FFAD2A', barWidth:9, data: signCount, itemStyle: {
							normal: {
								barBorderRadius:[0,4,4,0]
							}
						}, barCategoryGap: 2}
					]
				};
				clubChart.setOption(option)
				var autoHeight = yList.length * 50 + 50;
				clubChart.getDom().style.height = autoHeight + "px";
				clubChart.resize();
			},

			// 获取成员签到率
			async getParticipationRate() {
				try{
					let timeStamp = Math.round((new Date()).valueOf());
					let list = []
					let yList = []
					let enrollCount = []
					let data = {
						clubId: this.$route.params.clubId,
						startTime: timeStamp,
						endTime: timeStamp,
						selectType: this.selectType,
					}
					let res = await this.$request.post(`/clubData/getActivityParticipationRate`,data)
					this.clubData = res.data
					this.activityCount = res.data.activityCount
					for(let temp of res.data.resList){
							// let t = temp.startTime.replace(/-/g,'/')
							// 	t = t.replace(/\/0/g,"/")
							// 	t = t.split('/')
							// temp.startTime = `${t[1]}/${t[2]}`
							// temp.signRate = temp.clubActivityKeyDataList?temp.clubActivityKeyDataList[0].signCount === 0 ? 0: (Math.round(parseFloat(temp.clubActivityKeyDataList[0].signCount) / parseFloat(temp.clubActivityKeyDataList[0].enrollCount) * 10000) / 100.00):0
							list.unshift(temp)
							yList.unshift(temp.nickname)
							enrollCount.unshift(parseInt(temp.rate))
							// signCount.unshift(temp.signCount.length!=0?temp.signCount : 0)
					}
					this.EchartsList = list
					this.joinClubEcharts(yList, enrollCount)
				} catch(e) {
					this.clubRoles = false
				}
			},

			joinClubEcharts(yList,enrollCount) {
				let chart = document.getElementById('mains')
				chart.setAttribute("style","width: 696px;min-height:300px");
				let charts = document.getElementById('main')
				charts.style.display = "none"
				let clubCharts = this.$echarts.init(chart)
				var option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor:'rgba(0,0,0,0.5)',
						textStyle: {
							color: "#fff"
						},
						padding:15,
						borderWidth:'0',
						axisPointer: {
							type: 'shadow'
						},
						formatter: function (datas) {
							var res = datas[0].name + '<br/>'
							for (var i = 0, length = datas.length; i < length; i++) {
								res += datas[i].marker + " " + datas[i].value + "%" + '<br/>'
							}
							return res
						}
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: {
						type: 'value',
						boundaryGap: [0, 0.01],
						splitLine:{
							show: true,
							lineStyle: {
								type:'dashed',
								color:'#ddd'
							}
						},
						// minInterval: "1%",
						min: 0,
						max:100,
						splitNumber:9,
						// dataMax: 'value',
						axisLabel: {
							formatter: '{value} %'
						}
					},
					yAxis: {
						name: '成员',
						nameTextStyle: {
							fontSize: 12,
							color: '#000'
						},
						type: 'category',
						data: yList,
						axisLabel: {
							color: "#333333"
						},
						axisLine: {
							lineStyle: {
								type:  'solid' ,
								color:  '#F1F4FB'	//左边线的颜色
							}
						},
					},
					series: [
						{type:'bar', color:'#013D6E', barWidth:9, data: enrollCount ,itemStyle: {
							normal: {
								barBorderRadius:[0,4,4,0]
							}
						}, barCategoryGap: 2},
					]
				};
				clubCharts.setOption(option)
				// var autoHeight = yList.length * 50 + 50
				// clubChart.getDom().style.height = autoHeight + "px"
				// clubChart.resize()
			},

			// 获取成员动态情况
			async getMemebrSituation() {
				try{
					let timeStamp = Math.round((new Date()).valueOf());
					let list = []
					let yList = []
					let signCount = []
					let enrollCount = []
					let data = {
						clubId: this.$route.params.clubId,
						startTime: timeStamp,
						endTime: timeStamp,
						selectType: this.memberSelectType,
					}
					let res = await this.$request.post(`/clubData/getClubMemberNewsData`,data)
					this.clubData = res.data
					if(res.data.newsCount==0){
						this.noneMemberDynamic = true
						for(let temp of res.data.resList){
							list.unshift(temp)
							yList.unshift(temp.nickname)
							enrollCount.unshift(temp.newsLikeCount)
							signCount.unshift(temp.newsCount)
						}
						this.EchartsList = list
						this.blankEcharts(yList, enrollCount, signCount)
						return
					}else{
						for(let temp of res.data.resList){
							list.unshift(temp)
							yList.unshift(temp.nickname)
							enrollCount.unshift(temp.newsLikeCount)
							signCount.unshift(temp.newsCount)
						}
						this.EchartsList = list
						this.memberclubEcharts(yList, enrollCount, signCount)
					}

				} catch(e) {
					this.clubRoles = false
				}
			},

			memberclubEcharts(yList,enrollCount,signCount) {
				let chart = document.getElementById('member')
				// chart.setAttribute("style","width: 696px;height:300px");
				let clubChart = this.$echarts.init(chart)
				var option = {

					tooltip: {
						trigger: 'axis',
						backgroundColor:'rgba(0,0,0,0.5)',
						textStyle: {
							color: "#fff"
						},
						padding:15,
						borderWidth:'0',
						axisPointer: {
							type: 'shadow'
						},
					formatter: function (datas) {
						var res = datas[0].name + '<br/>'
						for (var i = 0, length = datas.length; i < length; i++) {
							res += datas[i].marker + " " + datas[i].value + "个" + '<br/>'
						}
						return res
					}
					},
					legend: {},
					grid: {
						left: '3%',
						right: '10%',
						// top: '10%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: {
						type: 'value',
						// name: '次数',
						nameLocation: 'end',
						nameTextStyle: {
							padding: [-100,0,-1000,10]
						},
						boundaryGap: [0, 0.01],
						splitLine:{
							show: true,
							lineStyle: {
								type:'dashed',
								color:'#ddd'
							}
						},
						minInterval: 1,
						// splitNumber: 3,
						min:0,
						max: function (value) {
							if(value.max==0){
								return value.max==100
							}
							return value.max ;
						},
						// dataMax: 'value',
						axisLabel: {}
					},
					yAxis: {
						name: '成员',
						nameTextStyle: {
							fontSize: 12,
							color: '#000'
						},
						type: 'category',
						data: yList,
						axisLabel: {
							color: "#333333"
						},
						axisLine: {
							lineStyle: {
								type:  'solid' ,
								color:  '#F1F4FB'	//左边线的颜色
							}
						},
					},
					series: [
						{type:'bar', color:'#013D6E', barWidth:9, data: enrollCount ,itemStyle: {
							normal: {
								barBorderRadius:[0,4,4,0]
							}
						}, barCategoryGap: 2},
						{type:'bar', color:'#FFAD2A', barWidth:9, data: signCount, itemStyle: {
							normal: {
								barBorderRadius:[0,4,4,0]
							}
						}, barCategoryGap: 2}
						]
				};
				clubChart.setOption(option)
				var autoHeight = yList.length * 50 + 50;
				clubChart.getDom().style.height = autoHeight + "px";
				clubChart.resize();
			},

			// 活动参与情况查询信息
			async searchData(){
				if(!this.mychoiceTimes){
					this.$message.warning({
						showClose:true,
						message:"请先选择时间"
					})
					return
				}
				let startTime = this.timestampToTime(this.mychoiceTimes[0])
				let endTime = this.timestampToTime(this.mychoiceTimes[1])
				if(this.value==0){
					this.current = -1
					let list = []
					let yList = []
					let signCount = []
					let enrollCount = []
					let data = {
						clubId: this.$route.params.clubId,
						startTime: startTime,
						endTime: endTime,
					}
					let res = await this.$request.post(`/clubData/getActivityParticipationData`,data)
					this.clubData = res.data
					this.activityCount = res.data.activityCount
					if(this.activityCount==0){
						this.noneActivity = true
						for(let temp of res.data.resList){
								temp.signRate = temp.clubActivityKeyDataList?temp.clubActivityKeyDataList[0].signCount === 0 ? 0: (Math.round(parseFloat(temp.clubActivityKeyDataList[0].signCount) / parseFloat(temp.clubActivityKeyDataList[0].enrollCount) * 10000) / 100.00):0
								list.unshift(temp)
								yList.unshift("")
								enrollCount.unshift(temp.activityCount.length!=0?temp.activityCount : 0)
								signCount.unshift(temp.signCount.length!=0?temp.signCount : 0)
						}
						this.EchartsList = list
						this.blankActivityEcharts(yList, enrollCount, signCount)
					}else{
						for(let temp of res.data.resList){
								temp.signRate = temp.clubActivityKeyDataList?temp.clubActivityKeyDataList[0].signCount === 0 ? 0: (Math.round(parseFloat(temp.clubActivityKeyDataList[0].signCount) / parseFloat(temp.clubActivityKeyDataList[0].enrollCount) * 10000) / 100.00):0
								list.unshift(temp)
								yList.unshift(temp.nickname)
								enrollCount.unshift(temp.activityCount.length!=0?temp.activityCount : 0)
								signCount.unshift(temp.signCount.length!=0?temp.signCount : 0)
						}
						this.EchartsList = list
						this.clubEcharts(yList, enrollCount, signCount)
					}
				}else{
					this.current = -1
					let list = []
					let yList = []
					let enrollCount = []
					let data = {
						clubId: this.$route.params.clubId,
						startTime: startTime,
						endTime: endTime,
					}
					let res = await this.$request.post(`/clubData/getActivityParticipationRate`,data)
					this.clubData = res.data
					this.activityCount = res.data.activityCount
					for(let temp of res.data.resList){
						list.unshift(temp)
						yList.unshift(temp.nickname)
						enrollCount.unshift(parseInt(temp.rate))
					}
					this.EchartsList = list
					this.joinClubEcharts(yList, enrollCount)
				}
			},

			// 成员动态查询信息
			async MemberSearchData(){
				if(!this.mychoiceMemberTimes){
					this.$message.warning({
						showClose:true,
						message:"请先选择时间"
					})
					return
				}
				let startTime = this.timestampToTime(this.mychoiceMemberTimes[0])
				let endTime = this.timestampToTime(this.mychoiceMemberTimes[1])
				this.memberCurrent = -1
				let list = []
				let yList = []
				let signCount = []
				let enrollCount = []
				let data = {
					clubId: this.$route.params.clubId,
					startTime: startTime,
					endTime: endTime,
				}
				let res = await this.$request.post(`/clubData/getClubMemberNewsData`,data)
				if(res.data.newsCount==0){
					this.noneMemberDynamic = true
					this.clubData = res.data
					for(let temp of res.data.resList){
						list.unshift(temp)
						yList.unshift("")
						enrollCount.unshift(temp.newsLikeCount)
						signCount.unshift(temp.newsCount)
					}
					this.EchartsList = list
					this.blankEcharts(yList, enrollCount, signCount)
					return
				}else{
					this.clubData = res.data
					this.activityCount = res.data.activityCount
					for(let temp of res.data.resList){
							list.unshift(temp)
							yList.unshift(temp.nickname)
							enrollCount.unshift(temp.newsLikeCount)
							signCount.unshift(temp.newsCount)
					}
					this.EchartsList = list
					this.memberclubEcharts(yList, enrollCount, signCount)
				}
			}
		}
	}

</script>

<style scoped lang="scss">
	.club-data{
		width: 785px;
		box-sizing: border-box;
		.top-nav{
			width: 100%;
			box-sizing: border-box;
			background-color: #fff;
			height: 51px;
			border-radius: 5px;
			margin-bottom: 15px;
			display: flex;
			align-items: center;
			justify-content: center;
			.nav-item{
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color:#444;
				transform: all 0.5s;
				position: relative;
				cursor: pointer;
			}
			.nav-item:first-child{
				margin-right: 100px;
			}
			.nav-select{
				color:#1465CF;
			}
			.nav-select::after{
				position: absolute;
				content: '';
				bottom:0;
				left:0;
				width: 100%;
				height: 2px;
				background-color: #1465CF;
				border-radius: 2px;
			}
		}
		.tabs{
			width: 100%;
			box-sizing: border-box;
			.member-card{
				background: #FFFFFF;
				border-radius: 5px;
				margin-bottom: 15px;
				.card-title{
					padding:16px 20px 13px;
					border-bottom: 0.5px solid #ddd;
					font-size:16px ;
					color:#444;
					font-weight: bold;
				}
				.card-main{
					display: flex;
					padding:24px 15px 30px;
					box-sizing: border-box;
					justify-content: space-around;
					.card-item{
						display: flex;
						flex-direction: column;
						align-items: center;
						font-size: 14px;
						color:#444;
						font-weight: bold;
						.item-title{
							font-size: 12px;
							font-weight: 400;
						}
						.item-num{
							margin-top: 10px;
							font-size: 16px;

						}
						.item-day{
							display: flex;
							align-items: center;
							justify-content: center;
							margin-top:20px;
							.item-left{
								color:#888;
								font-weight: 400;
							}
							.item-icon{
								width: 9px;
								height: 4px;
								margin:0 10px;
							}

							.item-right{
								display: inline-block;
								color:#888;
								font-weight: 400;
								margin-left: 29px;
							}
						}
					}
					.line{
						height:184px;
						width: 1px;
						background-color: #ddd;
					}
				}
				.chart-box{
					cursor: pointer;
					background-color: #fff;
					display: flex;
					flex-direction: column;
					padding-bottom: 0!important;
					box-sizing: border-box;
					.top-choice{
						display: flex;
						align-items: center;
						.choice-left{
							display: flex;
							align-items: center;
							box-sizing: border-box;
							.statics-content{
								box-sizing: border-box;
								cursor:default;
								font-size: 12px;
								font-family: PingFang-SC-Regular, PingFang-SC;
								font-weight: 400;
								color: #000000;
								line-height: 17px;
								margin-right: 10px;
							}
						}
						.choice-middle{
							display: flex;
							align-items: center;
							border: 0.5px solid #ddd;
							box-sizing: border-box;
							border-radius: 5px;
							margin-left: 30px;
							.day7-button{
								height: 34px;
								width: 62px;
								border: 1px solid #fff;
								border-radius: 5px;
								display: flex;
								align-items: center;
								justify-content: center;
								font-size: 12px;
								font-family: PingFang-SC-Regular, PingFang-SC;
								font-weight: 400;
								color: #000000;
								line-height: 17px;
							}
							.day7-button:hover {
								color: #1465CF;
							}
							.day14-button{
								height: 34px;
								width: 62px;
								display: flex;
								align-items: center;
								justify-content: center;
								border-radius: 5px;
								font-size: 12px;
								font-family: PingFang-SC-Regular, PingFang-SC;
								font-weight: 400;
								color: #000000;
								line-height: 17px;
								text-align: center;
								border: 1px solid #fff;
							}
							.day14-button:hover {
								color: #1465CF;
							}
							.day30-button{
								height: 34px;
								width: 62px;
								display: flex;
								align-items: center;
								justify-content: center;
								border: 1px solid #fff;
								border-radius: 5px;
								font-size: 12px;
								font-family: PingFang-SC-Regular, PingFang-SC;
								font-weight: 400;
								color: #000000;
								line-height: 17px;
								text-align: center;
							}
							.day30-button:hover {
								color: #1465CF;
							}
						}
						.choice-right{
							display: flex;
							align-items: center;
							box-sizing: border-box;
							margin-left: 25px;
							cursor:default;
							.choice-time{
								font-size: 12px;
								font-family: PingFang-SC-Regular, PingFang-SC;
								font-weight: 400;
								color: #000000;
								line-height: 17px;
								margin-right: 10px;
							}
							.search-button{
								cursor: pointer;
								background: #1465CF;
								border-radius: 5px;
								font-size: 14px;
								font-family: PingFangSC-Medium, PingFang SC;
								font-weight: 500;
								color: #FFFFFF;
								line-height: 20px;
								padding: 7px 20px;
								margin-left: 10px;
							}
						}
					}
					.chart-title{
						display: flex;
						align-items: center;
						justify-content: center;
						margin-top: 30px;
						cursor:default;
						font-size: 12px;
						color: #000;
						padding-right: 5%;
						.right{
							color:#888;
						}
					}
				}
				.chart-bottom{
					width: 100%;
					box-sizing: border-box;
					margin:10px 0 15px;
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;
					.num-title{
						font-size:12px ;
						color:#444;
						display: flex;
						align-items: center;
						.line-color{
							width:20px ;
							height: 5px;
							border-radius: 3px;
							background-color: #013D6E;
							display: inline-block;
							margin-right: 10px;
						}
					}
					.num-title:nth-child(2){
						margin-left:30px ;
						.line-color{
							background-color: #FFAD2A;
						}
					}
					.x-number{
						position: absolute;
						right: 40px;
						top: -35px;
						font-size: 12px;
						font-family: PingFang-SC-Regular, PingFang-SC;
						font-weight: 400;
						color: #000;
						line-height: 17px;
					}
					.x-rate{
						position: absolute;
						right: 22px;
						top: -35px;
						font-size: 12px;
						font-family: PingFang-SC-Regular, PingFang-SC;
						font-weight: 400;
						color: #000;
						line-height: 17px;
					}
					.titles-intro{
						position: absolute;
						right: 40px;
						top: 0;
						font-size: 12px;
						font-family: PingFang-SC-Regular, PingFang-SC;
						font-weight: 400;
						color: #888888;
						line-height: 17px;
					}
					.title-intro{
						position: absolute;
						right: 20px;
						top: 0;
						font-size: 12px;
						font-family: PingFang-SC-Regular, PingFang-SC;
						font-weight: 400;
						color: #888888;
						line-height: 17px;
					}
					.title-intros{
						position: absolute;
						right: 22px;
						top: 20px;
						font-size: 12px;
						font-family: PingFang-SC-Regular, PingFang-SC;
						font-weight: 400;
						color: #888888;
						line-height: 17px;
					}
					.rate-intro{
						position: absolute;
						right: 38px;
						top: 20px;
						font-size: 12px;
						font-family: PingFang-SC-Regular, PingFang-SC;
						font-weight: 400;
						color: #888888;
						line-height: 17px;
					}
				}
				.bottom-info{
					display: flex;
					align-items: center;
					justify-content: space-around;
					box-sizing: border-box;
					padding:0 20px 20px;
					.info-item{
						display: flex;
						align-items: center;
						justify-content: space-around;
						.item-icon{
							width: 40px;
							height: 40px;
							margin-right: 10px;
						}
						.right-text{
							.top-num{
								font-size: 16px;
								color: #013D6E;
								font-weight: bold;
							}
							font-size: 12px;
							color: #444;
						}
					}
				}
			}

		}
		.err-box{
			width: 100%;
			box-sizing: border-box;
			background-color: #fff;
			height: 500px;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color:#444;
		}
    .active{
      color: #1465CF !important;
			background: #F3F8FF;
			border-radius: 5px;
			border: 1px solid #1465CF !important;
    }
	}
</style>
<style lang="scss">
.el-select-dropdown__list {
    list-style: none;
    padding: 2px 0 13px 0;
    margin: 0;
    box-sizing: border-box;
}
.el-date-editor .el-range-input {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	outline: 0;
	padding: 0;
	width: 150%;
	color: #606266;
}
.el-date-editor .el-range-separator {
	padding: 0 0px;
	line-height: 32px;
	margin-bottom: 8px;
	width: 5%;
	color: #ddd!important;
}
.el-date-editor .el-range__close-icon {
	font-size: 14px;
	color: #C0C4CC;
	width: 25px;
	display: inline-block;
	float: right;
	line-height: 32px;
	margin-bottom: 6px;
}
.el-select-dropdown__item.selected {
    color: #409EFF;
    font-weight: 500;
}
.el-date-editor .el-range__icon {
    font-size: 14px;
    margin-left: -5px;
    color: #C0C4CC;
    float: left;
    line-height: 32px;
    margin-bottom: 7px;
}
</style>