import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

axios.defaults.timeout = 120000;
axios.interceptors.request.use(function (config) {
    config.headers.common['token'] = localStorage.getItem("imlatte_token")
    config.headers.common['terminalType'] = 'web'
    return config
})

export default {
    get: function (url = '', data = {}, responseType = 'json') {
        return new Promise((resolve, reject) => {
            axios({url, method: 'get', data, responseType, timeout: 120000})
                .then((res) => {
                    if (res.data.code === 200) {
                        return resolve(res.data)
                    } else if (res.data.code === 400) {
                        // 参数不合法
                        // alert(res.data.msg)
                        return reject(res.data)
                    } else if (res.data.code === 401) {
                        // 没有权限
                        // 清除全部缓存
                        // 跳转至首页
                        return reject(res.data)
                    } else if (res.data.code === 404) {
                        // 路径不存在
                        alert(res.data.msg)
                        return reject(res.data)
                    } else if (res.data.code === 500 || res.data.code === 2002) {
                        // 代码异常
                        // console.log('code=500', res.data)
                        alert(res.data.msg)
                        return reject(res.data)
                    } else if (res.data.code === 2003) {
                        // 活动发布成功，等待后台审核
                        alert(res.data.msg)
                        return resolve(res.data)
                    } else if (res.data.code === 2004) {
                        // 当前时间距离活动开始时间小于两小时，所以你的活动费用需审核通过后退还
                        alert(res.data.msg)
                        return reject(res.data);
                    } else if (res.data.code === 2006) {
                        // 活动不存在
                        return reject(res.data)
                    } else if (res.data.code === 2007) {
                        // 动态不存在
                        return reject(res.data)
                    } else if (res.data.code === 2008) {
                        // 评论不存在
                        return reject(res.data)
                    } else {
                        return resolve(res.data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    post: function (url = '', data = {}, responseType = 'json') {
        return new Promise((resolve, reject) => {
            axios({url, method: 'post', data, responseType, timeout: 120000})
                .then((res) => {
                    if (res.data.code === 200) {
                        return resolve(res.data)
                    } else if (res.data.code === 400) {
                        // 参数不合法
                        alert(res.data.msg)
                        return reject(res.data)
                    } else if (res.data.code === 401) {
                        // 没有权限
                        // 清除全部缓存
                        // 跳转至首页
                        return reject(res.data)
                    } else if (res.data.code === 404) {
                        // 路径不存在
                        alert(res.data.msg)
                        return reject(res.data)
                    } else if (res.data.code === 500 || res.data.code === 2002) {
                        // 代码异常
                        alert(res.data.msg)
                        return reject(res.data)
                    } else if (res.data.code === 2003) {
                        // 活动发布成功，等待后台审核
                        alert(res.data.msg)
                        return resolve(res.data)
                    } else if (res.data.code === 2004) {
                        // 当前时间距离活动开始时间小于两小时，所以你的活动费用需审核通过后退还
                        alert(res.data.msg)
                        return reject(res.data);
                    } else if (res.data.code === 2006) {
                        // 活动不存在
                        return reject(res.data)
                    } else if (res.data.code === 2007) {
                        // 动态不存在
                        return reject(res.data)
                    } else if (res.data.code === 2008) {
                        // 评论不存在
                        return reject(res.data)
                    } else {
                        return resolve(res.data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}