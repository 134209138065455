<template>
  <div class="container" :style="{'background-color': isIndex ? 'transparent' : 'white'}">
    <div class="main">
      <div class="left">
        <div class="brand" @click="checkedNeedTip(0)">
          <img class="brand__logo" src="../assets/image/imlatte-logo.png">
          <img class="brand__name" src="../assets/image/latLogo.png">
        </div>
        <div class="nav">
          <span class="nav__item" :class="showLink === 0 ? 'active' : ''" @click="checkedNeedTip(0)">主页</span>
          <span class="nav__item" :class="showLink === 1 ? 'active' : ''" @click="checkedNeedTip(1)">发现</span>
          <span class="nav__item" :class="showLink === 3 ? 'active' : ''" @click="checkedNeedTip(3)">俱乐部</span>
          <div class="nav__item" @mouseenter="toggleQundao" @mouseleave="toggleQundao">
            <span :class="showLink === 4 ? 'active' : ''">群岛</span>
            <div class="qundao" v-if="showQundao" :style="{'opacity': showQundao ? '1' : '0'}">
              <div class="triangle"></div>
              <img class="qundao__url" src="../assets/qundao_logo.png">
              <div class="qundao__split"></div>
              <div class="qundao__tip">一分钟拥有自己的社群小程序</div>
              <a class="qundao__button" href="https://domain.imlatte.com/" target="blank">免费体验</a>
            </div>
          </div>
        </div>
        <div class="menu" @click="showDrawer = !showDrawer">
          <div class="one"></div>
          <div class="two"></div>
          <div class="three"></div>
        </div>
      </div>
      <div class="headRight">
        <div class="btnModel" @mouseenter="openUser" @mouseleave="openUser">
          <div class="nav-user" v-if="login && !noshowAvatar">
            <div class="user-img">
              <img-head v-if="userAvatar" :avatarUrl="userAvatar" :Radius="50" :size="46"></img-head>
            </div>
          </div>
          <button v-else @click="openLogin">登录</button>
          <div class="lauoutModelDalog" v-if="showOut">
            <div class="triangle"></div>
            <div class="dalogHead">
              <div class="user-img">
                <img-head v-if="userAvatar" :avatarUrl="userAvatar" :Radius="50" :size="46"></img-head>
              </div>
              <div class="textInfor">
                <p class="userNick">{{ usernick }}</p>
                <p class="status" :class="bindWxOpenId?'bdWeix':''">{{bindWxOpenId?"已绑定拿铁号（ID）":'尚未绑定拿铁号'}}</p>
              </div>
            </div>
            <div class="qrcodeModel">
              <img class="img" src="../assets/image/60_60.png" alt="">
              <div class="textt">{{ bindWxOpenId ? '扫码登录小程序' : '请您前往LattePlus小程序完' }}</div>
              <div class="textt">{{ bindWxOpenId ? '分享LattePlus™更多快乐' : '成注册，以便体验更多功能!' }}</div>
            </div>
            <div class="laoutGogin">
              <span @click.stop="goQuit" v-if="bindWxOpenId">退出登录</span>
              <button class="btnLayout" @click.stop="showOut = false" v-if="!bindWxOpenId">确定</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showDrawer" class="mask">
      <div class="drawer">
        <div class="drawer__close">
          <div class="drawer__close__icon" @click="showDrawer = false">
            <div class="drawer__close__line1"></div>
            <div class="drawer__close__line2"></div>
          </div>
        </div>
        <div class="drawer__item" :class="showLink === 0 ? 'active' : ''" @click="checkedNeedTip(0)">主页</div>
        <div class="drawer__item" :class="showLink === 1 ? 'active' : ''" @click="checkedNeedTip(1)">发现</div>
        <div class="drawer__item" :class="showLink === 3 ? 'active' : ''" @click="checkedNeedTip(3)">俱乐部</div>
        <a class="drawer__item" href="https://domain.imlatte.com/" target="blank" @click="showDrawer = false">群岛</a>
      </div>
    </div>
    <login-popup :show="showLogin" @close="closeLogin"></login-popup>

    <!-- 提示弹窗 -->
    <tip-popup :title="tipContent" :isOpen="isOpenTip" confirmColor="#1465CF" @close="cancelPopup" @confirm="goLink"></tip-popup>
  </div>
</template>

<script>

import { eventBus } from '../main.js'
import imgHead from './img-head.vue'
import LoginPopup from './Login.vue'
import tipPopup from './tip-popup.vue'
export default {
  data() {
    return {
      bindWxOpenId: false,
      showOut: false,
      showLogin: false,
      usernick: '',			// 用户昵称
      userAvatar: '',			// 用户头像
      login: false,
      noshowAvatar: false,
      isOpenNewPages: false,
      isOpenTip: false,
      tipContent: '',
      type: 0, // 链接跳转类型
      showQundao: false,
      showDrawer: false,
    }
  },

  props: {
    isIndex: {
      type: Boolean,
      default: false
    },
    showLink: {
      type: Number,
      default: 0
    },
    showAvatar: {
      type: Boolean,
      default: true
    },
  },

  watch: {
    '$store.state.userInfo': function() {
      this.usernick = this.$store.state.userInfo ? this.$store.state.userInfo.nickname : ''
      this.bindWxOpenId = this.$store.state.userInfo ? this.$store.state.userInfo.bindWxOpenId : ''
    },
    '$store.state.token': function() {
      if (this.$store.state.token) {
        this.noshowAvatar = false
      }
    },
  },

  beforeMount() {
    if (!window.localStorage.getItem('imlatte_token')) {
      this.noshowAvatar = true
    } else {
      this.noshowAvatar = false
    }
    if (this.$store.state.userInfo) {
      this.login = true
      let data = this.$store.state.userInfo
      this.usernick = data.nickname
      this.userAvatar = data.avatarUrl
    }
    else {
      if (this.isIndex) return
      this.$request.post('/userInfo/personalInfo').then(res => {
        if (res.code === 200) {
          let data = res.data
          this.$store.commit('SET_USER', data)
          this.usernick = data.nickname
          this.userAvatar = data.avatarUrl
        }
      }).catch(() => {
        // 
      })
    }
  },

  components: {
    imgHead,
    LoginPopup,
    tipPopup
  },

  async created() {
    eventBus.$on('tipsShowWatch', this.tipsShowWatch)
    eventBus.$on("haveConfirm", (num) => {
      if (num == 0) {
        this.isOpenNewPages = false
      }
    })
    eventBus.$on("dataChanges", (num) => {
      if (num == 0) {
        this.isOpenNewPages = true
      } else {
        this.isOpenNewPages = false
      }
    })
    if (window.localStorage.getItem('imlatte_token')) {
      try {
        await this.$request.post('/userInfo/personalInfo')
        this.login = true
      } catch (e) {
        // token失效
        this.login = false
      }
    } else {
      this.login = false
    }
  },

  beforeDestroy() {
    eventBus.$off('tipsShowWatch')
  },

  methods: {
    toggleQundao() {
      this.showQundao = !this.showQundao
    },

    tipsShowWatch(event) {
      if (event.show) {
        this.type = 4
        this.isOpenTip = true
        this.tipContent = event.content
      } else if (!event.show) {
        this.isOpenTip = false
      }
    },

    checkedNeedTip(type) {
      this.type = type
      if (this.isOpenNewPages) {
        this.isOpenTip = true
        this.tipContent = '离开页面无法保存数据, 是否继续？'
      } else {
        this.isOpenTip = false
        this.goLink()
      }
      this.showDrawer = false
    },

    cancelPopup() {
      this.type = 0
      this.isOpenNewPages = true
      this.isOpenTip = false
      this.$message.warning({
        showClose: true,
        type: 'info',
        message: '已取消跳转'
      })
    },

    // 导航跳转
    goLink() {
      if (this.type == 4) {
        eventBus.$emit('tipsShowAccept')
        this.type = 0
        this.isOpenTip = false
        return
      }
      this.isOpenNewPages = false
      this.isOpenTip = false
      if (this.type === 0) {
        this.src = 0
        this.$router.push({ name: 'Index' })
      } else if (this.type === 1) {
        this.src = 1
        this.$router.push({ name: 'Moment' })
      } else if (this.type === 3) {
        this.src = 3
        if (window.localStorage.getItem('imlatte_token')) {
          this.$router.push({ name: 'ClubRecommendation' })
        } else {
          this.openLogin()
        }
      }
    },

    // 打开退出框
    async openUser() {
      if (this.login && !this.noshowAvatar) {
        this.showOut = !this.showOut
      }
    },

    // 点击退出
    goQuit() {
      this.isOpenNewPages = false
      this.login = false
      this.showOut = false
      this.$store.commit('CLEAR_STORAGE')
      window.localStorage.clear()
      this.$router.push({ name: 'Moment' })
      this.noshowAvatar = true
    },

    //刷新页面
    refresh() {
      if (this.$store.state.userInfo) {
        let data = this.$store.state.userInfo
        this.usernick = data.nickname
        this.userAvatar = data.avatarUrl
      }
      else {
        if (this.isIndex) return
        this.$request.post('/userInfo/personalInfo').then(res => {
          if (res.code === 200) {
            let data = res.data
            this.$store.commit('SET_USER', data)
            this.usernick = data.nickname
            this.userAvatar = data.avatarUrl
          }
        }).catch(() => {
          // 
        })
      }
    },

    closeLogin() {
      this.showLogin = false
      this.current = 0
      this.login = true
      this.showOut = false
      this.refresh()
    },

    openLogin() {
      this.showLogin = true
      this.refresh()
    },
  }
};
</script>

<style lang="scss" scoped>
  .container {
    width: 100%;
  }
  .main {
    width: 85%;
    max-width: 1440px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .brand {
        display: flex;
        align-items: center;
        margin-right: 100px;
        &__logo {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
        &__name {
          width: 140px;
        }
      }
      .nav {
        // height: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        &__item {
          height: 100%;
          flex-shrink: 0;
          font-weight: 500;
          font-size: 24px;
          color: #888;
          margin-left: 80px;
          transition: all 0.15s;
          cursor: pointer;
          position: relative;
          display: flex;
          align-items: center;
          &.active {
            font-weight: 800;
            color: #003D6E;
          }
          &:first-child {
            margin-left: 0;
          }
          .qundao {
            position: absolute;
            width: 182px;
            height: 185px;
            top: 75px;
            left: -70px;
            z-index: 10;
            box-shadow: 0px 0px 10px 0px #dfdfdf;
            background: #fff;
            border-radius: 5px;
            padding: 0px 15px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 15px;
            box-sizing: border-box;
            opacity: 0;
            transition: all 0.2s;
            .triangle {
              position: absolute;
              left: 50%;
              margin-left: -3px;
              top: -4px;
              width: 0;
              height: 0;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-bottom: 6px solid #fff;
            }
            &__url {
              width: 48px;
              height: 48px;
              object-fit: contain;
            }
            &__split {
              width: 150px;
              height: 1px;
              background: #E4E4E4;
              margin: 15px 0;
            }
            &__tip {
              font-size: 12px !important;
              font-weight: 400 !important;
              color: black !important;
            }
            &__button {
              width: 118px;
              height: 32px;
              border-radius: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #005AFF;
              font-size: 16px !important;
              font-weight: 400 !important;
              color: #005AFF !important;
            }
          }
        }
        &__item:hover {
          font-weight: 800;
          color: #003D6E;
        }
      }
      .menu {
        display: none;
        cursor: pointer;
        .one {
          width: 30px;
          height: 3px;
          border-radius: 3px;
          background: #003D6E;
          margin-bottom: 7px;
        }
        .two {
          width: 30px;
          height: 3px;
          border-radius: 3px;
          background: #003D6E;
          margin-bottom: 7px;
        }
        .three {
          width: 30px;
          height: 3px;
          border-radius: 3px;
          background: #003D6E;
        }
      }
    }
    .headRight {
      display: flex;
      align-items: center;
      .btnModel {
        flex-grow: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .nav-user {
          height: 76px;
          display: flex;
          align-items: center;
          cursor: pointer;
          .user-img {
            width: 46px;
            height: 46px;
            border-radius: 50%;
            background: #d9d9d9;
            border-radius: 100px;
          }
          .user-name {
            font-size: 16px;
            font-weight: bold;
            color: #000;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .sign-outs {
            width: 100px;
            height: 0;
            background: #fff;
            border-radius: 5px;
            position: absolute;
            top: 72px;
            right: 56px;
            z-index: 99;
            box-sizing: border-box;
            transition: all 0.2s cubic-bezier(0.49, -0.3, 0.68, 1.23);
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .switchingclubs {
              padding-left: 25px;
              color: #888888;
              font-size: 14px;
            }
            .switchingclubs:hover {
              color: #1465cf !important;
            }
          }
          .show-outs {
            height: 40px;
            box-shadow: -2px 3px 14px 8px rgba(0, 0, 0, 0.07);
          }
        }
        .lauoutModelDalog {
          position: absolute;
          width: 200px;
          height: 230px;
          top: 75px;
          z-index: 10;
          box-shadow: 0px 0px 10px 0px #dfdfdf;
          background: #fff;
          border-radius: 5px;
          padding: 0px 15px;
          box-sizing: border-box;
          .triangle {
            position: absolute;
            left: 50%;
            margin-left: -3px;
            top: -4px;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid #fff;
          }
          .dalogHead {
            display: flex;
            align-items: center;
            height: 73px;
            border-bottom: 0.5px solid #e4e4e4;
            .user-img {
              width: 46px;
              height: 46px;
              border-radius: 50%;
              background: #d9d9d9;
              border-radius: 100px;
              margin-right: 5px;
            }
            .textInfor {
              display: flex;
              flex-direction: column;
              .userNick {
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                line-height: 14px;
                color: #003d6e;
                margin-bottom: 2px;
              }
              .status {
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 14px;
                color: red;
                cursor: pointer;
                &.bdWeix {
                  cursor: unset;
                  color: #0078ff;
                }
              }
            }
          }
          .qrcodeModel {
            height: 116px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            border-bottom: 0.5px solid #e4e4e4;
            .img {
              width: 60px;
              height: 60px;
              margin-bottom: 2px;
            }
            .textt {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 17px;
              text-align: center;
              color: #555555;
            }
          }
          .laoutGogin {
            height: 37px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            text-align: center;
            color: #555555;
            cursor: pointer;
            .btnLayout {
              width: 90px;
              height: 26px;
              background: linear-gradient(
                80.72deg,
                #0078ff -31.46%,
                #84aeff 145.61%
              );
              border-radius: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 17px;
              color: #ffffff;
            }
          }
        }
        & > button {
          width: 120px;
          height: 46px;
          border-radius: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          color: white;
          cursor: pointer;
          background: linear-gradient(81deg, #0078ff -31%, #84aeff 145%);
        }
      }
    }
  }
  .mask {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    display: none;
  }
  .drawer {
    position: fixed;
    top: 0;
    left: 0;
    width: 190px;
    height: 100vh;
    background: white;
    &__close {
      height: 80px;
      display: flex;
      align-items: center;
      margin-left: 60px;
      &__icon {
        width: 28px;
        height: 28px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(45deg);
        cursor: pointer;
      }
      &__line1 {
        width: 28px;
        height: 3px;
        border-radius: 3px;
        background: #003D6E;
        position: absolute;
      }
      &__line2 {
        width: 3px;
        height: 28px;
        border-radius: 3px;
        background: #003D6E;
        position: absolute;
      }
    }
    &__item {
      font-size: 24px;
      color: #888;
      margin-left: 60px;
      margin-bottom: 40px;
      cursor: pointer;
    }
    .active {
      font-weight: 800;
      color: #003D6E;
    }
  }
  @media screen and (max-width: 960px) and (min-width: 730px) {
    .main {
      .left {
        .brand {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
        .nav {
          display: none;
        }
        .menu {
          display: block;
        }
      }
      .headRight {
        .lauoutModelDalog {
          right: -5vw;
          .triangle {
            left: 65% !important;
          }
        }
      }
    }
    .mask {
      display: block;
    }
  }
  /*手机端样式*/
  @media screen and (max-width: 730px) {
    .container {
      .main {
        .left {
          .brand {
            margin-right: 0;
            &__logo {
              width: 30px;
              height: 30px;
              margin-right: 7px;
            }
            &__name {
              width: 108px;
            }
          }
          .nav {
            display: none;
          }
          .menu {
            display: none;
          }
        }
        .headRight {
          display: none;
        }
      }
    }
    .mask {
      display: none;
    }
  }
</style>
