<template>
  <div v-if="show" class="container">
    <div class="main">
      <div class="close" @click="close">
        <div class="close__line1"></div>
        <div class="close__line2"></div>
      </div>
      <div class="logo">
        <img class="logo__url" src="../assets/login_logo.png" alt="LattePlus/看见你的热爱">
      </div>
      <div class="title">用户登录</div>
      <div class="input">
        <span class="area-code">+86</span>
        <span class="split"></span>
        <input class="phone" type="text" v-model="phone" placeholder="请输入LattePlus小程序绑定的手机号" />
      </div>
      <div class="input">
        <input class="code" type="text" maxlength="6" v-model="verification" placeholder="输入验证码" @keyup.enter.prevent="submitForm" />
        <span v-if="btnDisabled" class="count-down">{{ verificationText }}</span>
        <span v-else class="get-code" @click="getVerification">获取验证码</span>
      </div>
      <div class="submit" :class="verification.length === 6 ? '' : 'disabled'" @click="submitForm">登录</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showLogin: false,
      phone: '',
      verification: '',
      btnDisabled: false,
      verificationText: '获取验证码'
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    show(n) {
      if (n) {
        this.stopScroll()
      } else if (!n) {
        this.canScroll()
      }
    },
  },
  computed: {
    isBackgroundActive() {
      if (this.phone && this.verification) return true
      return false
    },
  },
  methods: {
    // 关闭弹窗
    close() {
      this.$emit('close')
    },

    //获取验证码
    async getVerification() {
      try {
        if (!this.phone) {
          this.$message({
            message: '手机号不能为空',
            type: 'info',
          })
          return
        } else if (!/^1[23456789]\d{9}$/.test(this.phone)) {
          this.$message({
            message: '请输入正确的手机号码',
            type: 'info',
          })
          return
        }
        if (this.btnDisabled) {
          return
        } else {
          this.btnDisabled = true
        }
        await this.$login.post('/web/phoneNumberLogin/sendCode', {phoneNumber: this.phone})
        this.$message({
          message: '验证码已发送，请注意手机短信',
          type: 'success',
        })
        let time = 60
        this.verificationText = `重新发送（${time}s）`
        let timer = setInterval(() => {
          time--
          this.verificationText = `重新发送（${time}s）`
          if (time === 0) {
            clearInterval(timer)
            this.btnDisabled = false
            this.verificationText = '获取验证码'
          }
        }, 1000)
      } catch (error) {
        this.btnDisabled = false
        this.$message({
          message: e.msg,
          type: 'warning',
        })
      }
    },

    //提交数据
    async submitForm() {
      if (!this.phone || !this.verification || this.verification.length !== 6) return
      //验证手机号码是否正确
      if (!/^1[23456789]\d{9}$/.test(this.phone)) {
        this.$message({
          message: '请输入正确的手机号码',
          type: 'info',
        })
        return
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      try {
        let res = await this.$login.post('/web/phoneNumberLogin/login', {
          phoneNumber: this.phone,
          code: this.verification,
        })
        if (res.code === 200) {
          this.$store.commit('SET_TOKEN', res.data.token)
          this.$message.success('登录成功')
          await this.getUserInfo()

          navigator.sayswho = (function() {
            var ua = navigator.userAgent, tem,
              M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
            if (/trident/i.test(M[1])) {
              tem = /\brv[ :]+(\d+)/g.exec(ua) || []
              return 'IE ' + (tem[1] || '')
            }
            if (M[1] === 'Chrome') {
              tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
              if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera')
            }
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
            if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
            return M.join(' ')
          })()

          let data = {
            deviceModel: navigator.sayswho,
            osVersion: navigator.appVersion,
            terminalType: 3
          }
          // 记录设备信息
          await this.$request.post('/userInfo/initUseDeviceInfoOther', data)
          this.$router.push({ name: 'ClubRecommendation' })
          this.phone = ''
          this.verification = ''
          this.$emit('close')
        }
        if (res.code === 500) {
          this.$message.error('你没有俱乐部管理权限')
          return
        }
      } catch (err) {
        this.$store.commit('CLEAR_STORAGE')
      }
      loading.close()
    },

    // 获取用户信息
    getUserInfo() {
      return new Promise((resolve, reject) => {
        try {
          this.$request.post('/userInfo/personalInfo').then((res) => {
            this.currentUserId = res.data.id
            this.$store.commit('SET_USER', res.data)
            return resolve()
          })
        } catch (error) {
          return reject()
        }
      })
    },

    closeLogin() {
      this.showLogin = false
      this.$emit('close')
    },

    openLogin() {
      this.showLogin = true
    },
  }
}
</script>

<style lang="scss" scoped>
  .container {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    .main {
      width: 420px;
      height: 327px;
      border-radius: 10px;
      background: white;
      position: relative;
    }
    .close {
      width: 24px;
      height: 24px;
      background: #E4E4E4;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotate(45deg);
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      &__line1 {
        width: 14px;
        height: 2px;
        border-radius: 2px;
        background: white;
      }
      &__line2 {
        width: 2px;
        height: 14px;
        border-radius: 2px;
        background: white;
        position: absolute;
      }
    }
    .logo {
      width: 130px;
      height: 34px;
      margin-top: 20px;
      margin-left: 20px;
      &__url {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      font-family: 'PangMenZhengDao';
      font-size: 32px;
      color: #003D6E;
      text-align: center;
      line-height: 37px;
      margin-top: 20px;
      margin-bottom: 15px;
    }
    .input {
      width: 317px;
      height: 40px;
      border-radius: 40px;
      border: 1px solid #CCC;
      margin: 15px auto;
      padding: 0 15px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .area-code {
        font-size: 14px;
        font-weight: bold;
        color: #888;
      }
      .split {
        width: 1px;
        height: 25px;
        margin: 0 10px;
        background: #DDD;
      }
    }
    .phone {
      flex: 1;
      font-size: 14px;
      color: #333;
      caret-color: #0C67F0;
    }
    .code {
      flex: 1;
      font-size: 14px;
      color: #333;
      caret-color: #0C67F0;
    }
    .get-code {
      font-size: 14px;
      color: #1272DE;
      cursor: pointer;
    }
    .count-down {
      font-size: 14px;
      color: #CCC;
      cursor: pointer;
    }
    .submit {
      width: 220px;
      height: 46px;
      border-radius: 46px;
      font-size: 18px;
      font-weight: bold;
      color: white;
      background: linear-gradient(81deg, #0078FF -31%, #84AEFF 145%);
      box-shadow: 0px 5px 20px rgba(25, 113, 212, 0.300773);
      margin: 30px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .disabled {
      background: #E6E6E6;
      box-shadow: none
    }
  }
  .show {
    opacity: 1;
  }
  input::-webkit-input-placeholder {
    color: #CCC;
    font-size: 14px;
  }
  input:-moz-placeholder {
    color: #CCC;
    font-size: 14px;
  }
  input:-ms-input-placeholder {
    color: #CCC;
    font-size: 14px;
  }
</style>