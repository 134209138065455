<template>
  <div class="container">
    <div class="main">
      <div class="footer-left">
        <img class="logo" src="../assets/imlatte-logo.png" alt="LattePlusLogo">
        <div>
          <p class="text">深圳市毕宿五网络科技有限公司</p>
          <a class="text" href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2020129504号</a>
        </div>
      </div>
      <div class="footer-right">
        <p class="right-text">
          <a class="text" href="https://imlatte.com/agreement/user" style="color: white;">用户协议</a>
          <a class="text" href="https://imlatte.com/agreement/private" style="color: white;">隐私政策</a>
          <span class="text" @click="goAbout">联系我们</span>
        </p>
        <p class="beian">
          <img class="beian__logo" src="../assets/gongan.png">
          <a class="text" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502006723">粤公网安备44030502006723号</a>
        </p>
      </div>
    </div>
    <div class="fotter-bottom text">Copyright © 2021-2023 imlatte.com All Rights Reserved.</div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {

    }
  },
  methods: {
    goAgreement() {
      this.$router.push('/Agreement')
    },
    Privacy() {
      this.$router.push('/Privacy')
    },
    //跳转About
    goAbout() {
      this.$router.push('/About')
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 45%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #333;
  margin: 0 auto;
  .main {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .text {
    font-size: 12px;
    color: white;
  }
  .footer-left {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .logo {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  }
  .footer-right {
    .right-text {
      margin-bottom: 5px;
      .text {
        cursor: pointer;
        padding-right: 10px;
      }
    }
    .beian {
      display: flex;
      align-items: center;
      &__logo {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
    }
  }
  .fotter-bottom {
    display: none;
    font-size: 8px;
    color: white;
    margin: 5px auto 0;
  }
}
@media screen and (max-width: 960px) and (min-width: 730px) {
  .container {
    width: 60%;
  }
}

/*手机端样式*/
@media screen and (max-width: 730px) {
  .container {
    width: 100%;
    height: 75px;
    align-items: flex-start;
    .text {
      font-size: 10px;
    }
    .footer-left {
      .logo {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
    }
    .footer-right {
      .right-text {
        display: none;
      }
      .beian {
        display: flex;
        align-items: center;
        &__logo {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
      }
    }
    .fotter-bottom {
      display: block;
    }
  }
}
</style>