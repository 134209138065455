<template>
  <div class="noDataTip" v-if="isOpen">
    <div class="tipTop">
      <p class="text">{{title}}</p>
      <p class="text">{{desc}}</p>
    </div>
    <div class="contentTip">
      <img class="xcxLogo" :src="imgLogo" alt="">
      <p class="text">#服务100万俱乐部</p>
    </div>
    <div class="bottomTip">
      <p class="text">扫码打开LattePlus微信小程序</p>
      <p class="text">即刻享受专属小圈</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgLogo: require('../assets/image/60_60.png')
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: true
    },
    title: {
      require: true,
      type: String,
      default: ""
    },
    desc: {
      require: true,
      type: String,
      default: ""
    }
  },
}
</script>

<style lang="scss" scoped>
.noDataTip {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .tipTop {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
    .text {
      font-family: 'Alibaba PuHuiTi 2.0';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      text-transform: capitalize;
      color: #003d6e;
    }
  }
  .contentTip {
    padding: 20px 41px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 20px;
    .xcxLogo {
      width: 60px;
      height: 60px;
    }
    .text {
      padding-top: 20px;
      padding-bottom: 10px;
      font-family: 'Alibaba PuHuiTi 2.0';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      text-align: center;
      color: #555555;
    }
  }
  .bottomTip {
    .text {
      font-family: 'Alibaba PuHuiTi 2.0';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      text-transform: capitalize;
      color: #003d6e;
    }
  }
}
</style>