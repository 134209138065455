<template>
  <div class="data-table">
    <div class="header">
      <div class="header-left">历史活动</div>
      <div class="header-right">
        <div class="select-area">
          <div class="select-item">
            <div class="label">活动开始时间</div>
            <div class="time">
              <el-date-picker
                v-model="dateRange"
                type="datetimerange"
                range-separator="-"
                start-placeholder="起始"
                end-placeholder="结束"
                format="yyyy/MM/dd HH:mm"
                prefix-icon="el-icon-date"
                :clearable="false"
                :editable="false"
                :picker-options="pickerOptions"
                :default-time="['00:00:00', '23:59:59']"
                @change="changeDate">
              </el-date-picker>
            </div>
          </div>
          <div class="select-btn" @click="getHistoryActivity">查询</div>
          <div class="select-btn" @click="exportExcel">下载</div>
        </div>
      </div>
    </div>
    <div class="main"  v-loading="loading">
      <div class="empty" v-if="empty">暂未发起活动</div>
      <el-table
        v-if="tableData.length > 0"
        :data="tableData"
        style="width: 100%"
        border
        :header-cell-style="headerCellStyle">
        <el-table-column
          fixed
          prop="activityName"
          align="center"
          width="100">
          <template slot="header">
            <div class="table-header">活动主题</div>
          </template>
          <template slot-scope="scope">
            <div class="table-text">{{ scope.row.activityName }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="activityTime"
          align="center"
          width="168">
          <template slot="header">
            <div class="table-header">活动时间</div>
          </template>
          <template slot-scope="scope">
            <div class="table-text">
              <div>{{ scope.row.startTime }}</div>
              <div style="width: 7px; height: 1px; background-color: #333; margin: 0 auto"></div>
              <div>{{ scope.row.endTime }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          align="center"
          width="168">
          <template slot="header">
            <div class="table-header">活动地点</div>
          </template>
          <template slot-scope="scope">
            <span class="table-text">{{ scope.row.address.split('|')[1] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createNickname"
          align="center"
          width="120">
          <template slot="header">
            <div class="table-header">活动发布者</div>
          </template>
          <template slot-scope="scope">
            <span class="table-text">{{ scope.row.createNickname }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createNickname"
          align="center"
          width="168">
          <template slot="header">
            <div class="table-header">发布时间</div>
          </template>
          <template slot-scope="scope">
            <span class="table-text">{{ scope.row.createTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createNickname"
          align="center"
          width="168">
          <template slot="header">
            <div class="table-header">报名结束时间</div>
          </template>
          <template slot-scope="scope">
            <span class="table-text">{{ scope.row.joinEndTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createNickname"
          align="center"
          width="120"
          class-name="group-list">
          <template slot="header">
            <div class="table-header">活动组别</div>
          </template>
          <template slot-scope="scope">
            <div class="group">
              <div class="group-item" v-for="(item, index) in scope.row.activityGroupEnrollData" :key="index">{{ item.activityFeeBranchName }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createNickname"
          align="center"
          width="120"
          class-name="group-list">
          <template slot="header">
            <div class="table-header">活动限制人数</div>
          </template>
          <template slot-scope="scope">
            <div class="group">
              <div class="group-item" v-for="(item, index) in scope.row.activityGroupEnrollData" :key="index">{{ item.maxPeople }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="enrollPeople"
          align="center"
          width="120"
          class-name="group-list">
          <template slot="header">
            <div class="table-header">
              <div>报名人数</div>
              <div>(含候补人数)</div>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="group">
              <div class="group-item" v-for="(item, index) in scope.row.activityGroupEnrollData" :key="index">{{ item.enrollPeople }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="participationPeople"
          align="center"
          width="120"
          class-name="group-list">
          <template slot="header">
            <div class="table-header">参与人数</div>
          </template>
          <template slot-scope="scope">
            <div class="group">
              <div class="group-item" v-for="(item, index) in scope.row.activityGroupEnrollData" :key="index">{{ item.joinPeople == null ? '-' : item.joinPeople }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="peopleType"
          align="center"
          width="120">
          <template slot="header">
            <div class="table-header">活动范围</div>
          </template>
          <template slot-scope="scope">
            <span class="table-text">{{ scope.row.peopleType === 1 ? '所有人' : scope.row.peopleType === 2 ? ' 成员及粉丝' : '仅成员'}}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          fixed="right"
          width="120">
          <template slot="header">
            <div class="table-header">操作</div>
          </template>
          <template slot-scope="scope">
            <span class="table-text member" @click="clickMemberList(scope.row)">活动成员列表</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  import Export2Excel from "@/utils/Export2Excel.js"
  export default {
    data() {
      return {
        loading: false,
        activityStatus: 0,
        feeType: 0,
        startTime: '',
        endTime: '',
        tableData: [],
        empty: false,
        dateRange: [],            // 筛选的日期范围
        headerCellStyle: {
          height: '48px',
          backgroundColor: '#F3F5F8',
          padding: '4px 0',
        },
        pickerOptions: {
          disabledDate(time) {
            let timestamp = time.getTime()
            let nowTimestamp = new Date(new Date().getTime()).setHours(23, 59, 59)
            let oneDay = 60 * 60 * 24 * 1000
            // 禁用距今一年前的日期
            if (timestamp - nowTimestamp > (oneDay * 31) || (nowTimestamp - timestamp) > (oneDay * 1825)) {
              return true
            }
          }
        },
      }
    },

    mounted() {
      let sTime = new Date(new Date(new Date().getTime() - (86400000 * 30)).setHours(0, 0, 0))
      let eTime = new Date(new Date(new Date().getTime()).setHours(23, 59, 59))
      this.dateRange = [
        sTime,
        eTime
      ]
      this.changeDate(this.dateRange)
      this.getHistoryActivity()
    },

    methods: {
      // 更改日期
      changeDate(value) {
        if(value) {
          let sDate = new Date(value[0])
          let eDate = new Date(value[1])
          this.startTime = `${sDate.getFullYear()}-${this.fullNumber(sDate.getMonth()+1)}-${this.fullNumber(sDate.getDate())} ${this.fullNumber(sDate.getHours())}:${this.fullNumber(sDate.getMinutes())}:00`
          this.endTime = `${eDate.getFullYear()}-${this.fullNumber(eDate.getMonth()+1)}-${this.fullNumber(eDate.getDate())} ${this.fullNumber(eDate.getHours())}:${this.fullNumber(eDate.getMinutes())}:00`
        } else {
          this.startTime = this.endTime = ''
        }
      },

      fullNumber(str) {
        if(parseInt(str) > 9) {
          return str
        } else {
          return `0${str}`
        }
      },

      formatStatus(num) {
        let str = ''
        switch(num) {
          case 0:
            str = '全部'
            break
          case 1:
            str = '报名中'
            break
          case 2:
            str = '报名截止'
            break
          case 3:
            str = '进行中'
            break
          case 4:
            str = '已结束'
            break
        }
        return str
      },

      formatType(num) {
        let str = ''
        switch(num) {
          case 0:
            str = '全部'
            break
          case 1:
            str = '免费'
            break
          case 2:
            str = '固定收费'
            break
          case 4:
            str = 'AA收费'
            break
        }
        return str
      },

      // 点击活动成员列表
      clickMemberList(data) {
        this.$emit('clickMemberList', data)
      },

      // 获取历史活动数据
      async getHistoryActivity() {
        if(this.loading) {
          return
        } else {
          this.loading = true
        }
        try {
          let res
          if(this.startTime && this.endTime) {
            res = await this.$request.get(`/clubData/getHistoryActivity?clubId=${this.$route.params.clubId}&activityStatus=${this.activityStatus}&feeType=${this.feeType}&activityStartTime=${this.startTime}&activityEndTime=${this.endTime}`)
          } else {
            res = await this.$request.get(`/clubData/getHistoryActivity?clubId=${this.$route.params.clubId}&activityStatus=${this.activityStatus}&feeType=${this.feeType}`)
          }
          // console.log('获取历史活动数据', res)
          this.tableData = res.data
          if(res.data.length > 0) {
            this.empty = false
          } else {
            this.empty = true
          }
          this.loading = false
        } catch (error) {
          this.loading = false
        }
      },

      // 导出excel
      exportExcel() {
        let datalist = this.tableData
        let newlist = []
        let merges = []
        let row = 2
        for(let m = 0; m < datalist.length; m++) {
          let item = datalist[m]
          for(let n = 0; n < item.activityGroupEnrollData.length; n++) {
            row++
            let groupItem = item.activityGroupEnrollData[n]
            let newItem = null
            if(n == 0) {
              newItem = {
                activityName: item.activityName,
                activityTime: `${item.startTime}-${item.endTime}`,
                address: item.address.split('|')[1],
                createNickname: item.createNickname,
                createTime: item.createTime,
                joinEndTime: item.joinEndTime,
                activityFeeBranchName: groupItem.activityFeeBranchName,
                maxPeople: groupItem.maxPeople,
                enrollPeople: groupItem.enrollPeople,
                joinPeople: groupItem.joinPeople == null ? '-' : groupItem.joinPeople,
                peopleType: item.peopleType === 1 ? '所有人' : item.peopleType === 2 ? ' 成员及粉丝' : '仅成员'
              }
            } else {
              newItem = {
                activityName: '',
                activityTime: '',
                address: '',
                createNickname: '',
                createTime: '',
                joinEndTime: '',
                activityFeeBranchName: groupItem.activityFeeBranchName,
                maxPeople: groupItem.maxPeople,
                enrollPeople: groupItem.enrollPeople,
                joinPeople: groupItem.joinPeople == null ? '-' : groupItem.joinPeople,
                peopleType: ''
              }
            }
            if(item.activityGroupEnrollData.length > 1 && n === item.activityGroupEnrollData.length - 1) {
              let start = row - (item.activityGroupEnrollData.length - 1)
              let arr = [`A${start}:A${row}`, `B${start}:B${row}`, `C${start}:C${row}`, `D${start}:D${row}`, `E${start}:E${row}`, `F${start}:F${row}`, `K${start}:K${row}`]
              merges = [...merges, ...arr]
            }
            newlist.push(newItem)
          }
        }
        let header = ['活动主题', '活动时间', '活动地点', '活动发布者', '发布时间', '报名结束时间', '活动组别', '活动限制人数', '报名人数(含候补人数)', '参与人数', '活动范围']
        let filterVal = ['activityName', 'activityTime', 'address', 'createNickname', 'createTime', 'joinEndTime', 'activityFeeBranchName', 'maxPeople', 'enrollPeople', 'joinPeople', 'peopleType']
        let list = (newlist || []).map((item, key) => {
          return {
            index: key + 1,
            activityName: item.activityName,
            activityTime: item.activityTime,
            address: item.address,
            createNickname: item.createNickname,
            createTime: item.createTime,
            joinEndTime: item.joinEndTime,
            activityFeeBranchName: item.activityFeeBranchName,
            maxPeople: item.maxPeople,
            enrollPeople: item.enrollPeople,
            joinPeople: item.joinPeople,
            peopleType: item.peopleType
          }
        })
        let data = Export2Excel.formatJson(filterVal, list)
        data.unshift(header)
        let sDate = new Date(this.dateRange[0])
        let eDate = new Date(this.dateRange[1])
        let startTime = `${this.fullNumber(sDate.getMonth()+1)}.${this.fullNumber(sDate.getDate())} ${this.fullNumber(sDate.getHours())}:${this.fullNumber(sDate.getMinutes())}`
        let endTime = `${this.fullNumber(eDate.getMonth()+1)}.${this.fullNumber(eDate.getDate())} ${this.fullNumber(eDate.getHours())}:${this.fullNumber(eDate.getMinutes())}`
        let multiHeader = [
          [`统计时间段：${startTime}~${endTime}`],
        ]
        Export2Excel.export_json_to_excel({
          multiHeader: [multiHeader],
          filename: `活动数据明细${startTime.substring(0, 5)}-${startTime.substring(0, 6)}`,
          sheetname: [`活动数据明细${startTime.substring(0, 5)}-${startTime.substring(0, 6)}`],
          data: [data],
          merges: [merges]
        })
      }
    }
  }
</script>

<style lang="scss">
  .data-table {
    width: 790px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    padding-bottom: 20px;
    box-sizing: border-box;
    .header {
      width: 100%;
      height: 54px;
      padding-left: 20px;
      padding-right: 15px;
      margin-bottom: 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.5px solid #ddd;
      .header-left {
        font-size: 18px;
        font-weight: bold;
        color: #444;
      }
      .header-right {
        .select-area {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          .select-item {
            display: flex;
            align-items: center;
            margin-left: 30px;
            .label {
              color: #333;
              font-size: 12px;
              margin-right: 10px;
            }
            .time {
              width: 254px;
              height: 34px;
              border-radius: 5px;
              box-sizing: border-box;
            }
            &:first-child {
              margin-left: 0;
            }
          }
          .select-btn {
            width: 68px;
            height: 34px;
            background-color: #1465CF;
            border-radius: 5px;
            color: white;
            font-size: 14px;
            margin-left: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }
    .main {
      width: calc(100% - 40px);
      min-height: 172px;;
      margin: 0 20px;
      box-sizing: border-box;
    }
  }
  .empty {
    width: 100%;
    height: 172px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #888;
  }
  .table-header {
    font-size: 12px;
    color: #333;
  }
  .table-text {
    color: #333;
    font-size: 12px;
    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
  }
  .member {
    color: #1465CF;
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
  }
  .group {
    .group-item {
      width: 100%;
      height: 72px;
      border-bottom: 1px solid #EBEEF5;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      &:last-child {
        border: none
      }
    }
  }
  .el-range-editor.el-input__inner {
    padding: 2px 4px;
    font-size: 12px;
    color: #333;
  }
  .el-date-editor .el-range__icon {
    font-size: 14px;
    margin-left: 3px;
    color: #333;
    float: left;
    line-height: 32px;
    margin-bottom: 6px;
  }
  .el-date-editor .el-range-input {
    color: #333;
    font-size: 12px;
  }
  .group-list {
    padding: 0 !important;
  }
  .el-table .cell {
    padding-left: 0;
    padding-right: 0;
  }
</style>