<template>
  <div class="active-user">
    <div class="header">
      <div class="header-left">
        <div>积极参与活动用户</div>
        <div>
          <el-tooltip class="item" effect="dark" content="包含所有报名活动的用户，活动开始仍在活动中则+1" placement="top">
            <i class="el-icon-warning-outline" style="color: #0584F9"/>
          </el-tooltip>
        </div>
      </div>
      <div class="header-right">
        <div class="label">活动开始时间</div>
        <div class="select">
          <el-date-picker
            v-model="dateRange"
            type="datetimerange"
            range-separator="-"
            start-placeholder="起始"
            end-placeholder="结束"
            format="yyyy/MM/dd HH:mm"
            prefix-icon="el-icon-date"
            :picker-options="pickerOptions"
            :clearable="false"
            :editable="false"
            :default-time="['00:00:00', '23:59:59']"
            @change="changeDate">
          </el-date-picker>
        </div>
        <div class="header-btn" @click="clickSelectBtn">查询</div>
        <div class="header-btn" @click="exportExcel">下载</div>
      </div>
    </div>
    <div class="main" v-loading="loading">
      <div class="empty" v-if="empty">暂无参与活动的用户</div>
      <div v-if="userList">
        <el-row type="flex" class="row-bg" v-for="(item, index) in currentLine" :key="index">
          <el-col :span="3" v-for="item1 in userList[index]" :key="item1.userId">
            <div class="user-item">
              <div class="top">
                <img-head class="avatar" :avatarUrl="item1.avatarUrl" :Radius="50" :size="60"></img-head>	
                <div class="tag" v-if="item1.type != 5">{{ formatUserType(item1.type) }}</div>
              </div>
              <div class="middle">{{ item1.nickname }}</div>
              <div class="bottom">{{ item1.count }}次</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="footer" v-if="!empty">
      <div class="f-button" v-if="currentLine > 1" @click="currentLine--">收起一行</div>
      <div class="f-button" v-if="currentLine < userList.length" @click="currentLine++">查看更多</div>
    </div>
  </div>
</template>

<script>
  import imgHead from '../../components/img-head.vue'
  import Export2Excel from "@/utils/Export2Excel.js"
  export default {
    data() {
      return {
        loading: false,
        startTime: '',
        endTime: '',
        currentLine: 1,
        empty: false,
        userList: [],
        dateRange: [],            // 筛选的日期范围
        pickerOptions: {
          disabledDate(time) {
            let timestamp = time.getTime()
            let nowTimestamp = new Date(new Date().getTime()).setHours(23, 59, 59)
            let oneDay = 60 * 60 * 24 * 1000
            // 禁用距今一年前的日期
            if (timestamp - nowTimestamp > (oneDay * 31) || (nowTimestamp - timestamp) > (oneDay * 365)) {
              return true
            }
          }
        },
      }
    },

    components: {
      imgHead
    },

    mounted() {
      let sTime = new Date(new Date(new Date().getTime() - (86400000 * 30)).setHours(0, 0, 0))
      let eTime = new Date(new Date(new Date().getTime()).setHours(23, 59, 59))
      this.dateRange = [
        sTime,
        eTime
      ]
      this.getActivityActiveUser()
    },

    methods: {
      // 格式化用户角色
      formatUserType(number) {
        let str = ''
        switch(number) {
          case 1:
            str = '创建者'
            break
          case 2:
            str = '管理员'
            break
          case 3:
            str = '成员'
            break
          case 4:
            str = '粉丝'
            break
        }
        return str
      },

      // 更改日期
      changeDate(value) {
        console.log('选择的时间', value)
        if(value) {
          let sDate = new Date(value[0])
          let eDate = new Date(value[1])
          this.startTime = `${sDate.getFullYear()}-${this.fullNumber(sDate.getMonth()+1)}-${this.fullNumber(sDate.getDate())} ${this.fullNumber(sDate.getHours())}:${this.fullNumber(sDate.getMinutes())}:00`
          this.endTime = `${eDate.getFullYear()}-${this.fullNumber(eDate.getMonth()+1)}-${this.fullNumber(eDate.getDate())} ${this.fullNumber(eDate.getHours())}:${this.fullNumber(eDate.getMinutes())}:00`
        } else {
          this.startTime = this.endTime = ''
        }
      },

      fullNumber(str) {
        if(parseInt(str) > 9) {
          return str
        } else {
          return `0${str}`
        }
      },

      // 点击查询按钮
      clickSelectBtn() {
        this.currentLine = 1
        this.getActivityActiveUser()
      },

      // 获取积极参与活动用户
      async getActivityActiveUser() {
        if(this.loading) {
          return
        } else {
          this.loading = true
        }
        try {
          let res
          if(this.startTime && this.endTime) {
            res = await this.$request.get(`/clubData/getActivityActiveUser?clubId=${this.$route.params.clubId}&activityStartTime=${this.startTime}&activityEndTime=${this.endTime}`)
          } else {
            res = await this.$request.get(`/clubData/getActivityActiveUser?clubId=${this.$route.params.clubId}`)
          }
          this.loading = false
          if(res.data.length === 0) {
            this.empty = true
            this.userList = []
            return
          } else {
            this.empty = false
          }
          let loopCount = Math.ceil(res.data.length / 8)
          let userList = []
          for(let i = 0; i < loopCount; i++) {
            let newArr = res.data.slice((i) * 8, (i + 1) * 8)
            userList.push(newArr)
          }
          this.userList = userList
        } catch (error) {
          this.loading = false
          // console.log('获取积极参与活动用户', error)
        }
      },

      formatIdentity(identity) {
        if(identity === 1) return '创建者'
        if(identity === 2) return '管理员'
        if(identity === 3) return '成员'
        if(identity === 4) return '粉丝'
        if(identity === 5) return '路人'
      },

      // 导出表格
      exportExcel() {
        let header = ['用户昵称', '参与次数', '用户身份']
        let filterVal = ['nickname', 'count', 'type']
        let data = []
        let merges = []
        let datalist = this.userList.flat()
        if(datalist.length > 0) {
          let list = (datalist || []).map((item, key) => { // 通过 map 方法遍历，组装数据成上面的格式
            return {
              index: key + 1,
              nickname: item.nickname,
              count: item.count,
              type: this.formatIdentity(item.type),
            }
          })
          let userdata = Export2Excel.formatJson(filterVal, list)
          userdata.unshift(header)
          data.push(userdata)
          merges = [['A1:C1']]
        }
        let sDate = new Date(this.dateRange[0])
        let eDate = new Date(this.dateRange[1])
        let startTime = `${this.fullNumber(sDate.getMonth()+1)}.${this.fullNumber(sDate.getDate())} ${this.fullNumber(sDate.getHours())}:${this.fullNumber(sDate.getMinutes())}`
        let endTime = `${this.fullNumber(eDate.getMonth()+1)}.${this.fullNumber(eDate.getDate())} ${this.fullNumber(eDate.getHours())}:${this.fullNumber(eDate.getMinutes())}`
        let multiHeader = [
          [`统计时间段：${startTime}~${endTime}`],
        ]
        Export2Excel.export_json_to_excel({
          multiHeader: [multiHeader],
          filename: `活动参与次数统计${startTime.substring(0, 5)}-${startTime.substring(0, 6)}`,
          sheetname: ['积极参与活动用户'],
          data: data,
          merges: merges
        })
      }
    }
  }
</script>

<style lang="scss" scope>
  .active-user {
    width: 790px;
    background-color: white;
    border-radius: 5px;
    margin-top: 15px;
    transition: all 0.1s ease;
    .header {
      width: 100%;
      height: 54px;
      padding-left: 20px;
      padding-right: 15px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.5px solid #ddd;
      .header-left {
        color: #444;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;
        div {
          &:last-child {
            margin-left: 5px;
          }
        }
      }
      .header-right {
        display: flex;
        align-items: center;
        .label {
          color: #000;
          font-size: 12px;
        }
        .select {
          width: 254px;
          height: 34px;
          box-sizing: border-box;
          margin-left: 10px;
        }
        .header-btn {
          width: 68px;
          height: 34px;
          background-color: #1465CF;
          border-radius: 5px;
          color: white;
          font-size: 14px;
          margin-left: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
    .main {
      min-height: 100px;;
      .empty {
        width: 100%;
        height: 172px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #888;
      }
      .el-row {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .row-bg {
        margin: 20px 0;
      }
      .user-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        .top {
          position: relative;
          width: 60px;
          height: 60px;
          .tag {
            color: white;
            font-size: 12px;
            background-color: #1465CF;
            border-radius: 100px;
            padding: 0px 5px;
            box-sizing: border-box;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            white-space: nowrap;
          }
        }
        .middle {
          width: 80%;
          margin-top: 5px;
          color: #333;
          font-size: 14px;
          margin-top: 5px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: center;
        }
        .bottom {
          color: #333;
          font-size: 12px;
        }
      }
    }
    .footer {
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .f-button {
        color: #1465CF;
        font-size: 14px;
        margin-left: 30px;
        cursor: pointer;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
  .el-range-editor.el-input__inner {
    padding: 2px 4px;
    font-size: 12px;
    color: #333;
  }
  .el-date-editor .el-range__icon {
    font-size: 14px;
    margin-left: 3px;
    color: #333;
    float: left;
    line-height: 32px;
    margin-bottom: 6px;
  }
  .el-date-editor .el-range-input {
    color: #333;
    font-size: 12px;
  }

</style>