<template>
  <div>
    <div v-if="!showMemberDetail">
      <!-- 数据看板 -->
      <data-board></data-board>

      <!-- 积极参与活动用户 -->
      <active-user></active-user>

      <!-- 数据列表 -->
      <data-table @clickMemberList="clickMemberList"></data-table>
    </div>

    <div v-else>
      <activity-detail ref="detail" @back="back"></activity-detail>
    </div>
  </div>
</template>

<script>
  import dataBoard from '../../components/clubActivityData/data-board.vue'
  import activeUser from '../../components/clubActivityData/active-user.vue'
  import dataTable from '../../components/clubActivityData/data-table.vue'
  import activityDetail from '../../components/clubActivityData/activity-detail.vue'
  export default {
    data() {
      return {
        showMemberDetail: false
      }
    },

    components: {
      dataBoard,
      activeUser,
      dataTable,
      activityDetail
    },

    methods: {
      clickMemberList(data) {
        this.showMemberDetail = true
        document.documentElement.scrollTop = 0
        this.$nextTick(() => {
          this.$refs.detail.init(data)
        })
      },

      back() {
        this.showMemberDetail = false
        setTimeout(() => {
          document.documentElement.scrollTop = 0
        }, 10)
      }
    }
  }
</script>

<style scoped lang="scss">
  
  
</style>