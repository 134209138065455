import { render, staticRenderFns } from "./dynamic-card.vue?vue&type=template&id=518de10c&scoped=true&"
import script from "./dynamic-card.vue?vue&type=script&lang=js&"
export * from "./dynamic-card.vue?vue&type=script&lang=js&"
import style0 from "./dynamic-card.vue?vue&type=style&index=0&id=518de10c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "518de10c",
  null
  
)

export default component.exports