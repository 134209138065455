<template>
  <div class="club-detail-page">
    <div class="club-top">
      <div class="float-nav">
        <club-roles :navIndex="navIndex" @currentNav="currentNav"></club-roles>
      </div>
    </div>
    <div class="club-body" style="width: 790px;margin-top:20px;">
      <transition name="fade-transform" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import { eventBus } from '../../main.js'
import clubRoles from '../../components/club/club-roles.vue'
export default {
  data() {
    return {
      clubDetail: null,
      myRole: 0,
      clubRoles: [],
      navIndex: 0,			          // 左侧导航栏
    }
  },

  components: {
    clubRoles
  },

  watch: {
    $route: {
      handler(val) {
        if (val.name == 'ClubMoment' || val.name == 'ClubActivity') {
          this.navIndex = 1
        } else if (val.name == 'ClubTheme') {
          this.current = 2
        } else if (val.name == 'ClubMember') {
          this.current = 3
        } else if (val.name == 'ClubData') {
          this.current = 4
        } else if (val.name == 'ClubDataMore') {
          this.current = 5
        } else if (val.name == 'AllMember') {
          this.current = 6
        } else if (val.name == 'ManageMember') {
          this.current = 7
        } else if (val.name == 'Administrator') {
          this.current = 8
        } else if (val.name == 'Blacklist') {
          this.current = 9
        }
      },
    }
  },

  mounted() {
    if (this.$route.name == 'ClubMoment' || this.$route.name == 'ClubActivity') {
      this.navIndex = 1
    } else if (this.$route.name == 'ClubTheme') {
      this.navIndex = 2
    } else if (this.$route.name == 'ClubMember') {
      this.navIndex = 3
    } else if (this.$route.name == 'ClubData') {
      this.navIndex = 4
    } else if (this.$route.name == 'ClubDataMore') {
      this.navIndex = 5
    } else if (this.$route.name == 'AllMember') {
      this.navIndex = 6
    } else if (this.$route.name == 'ManageMember') {
      this.navIndex = 7
    } else if (this.$route.name == 'Administrator') {
      this.navIndex = 8
    } else if (this.$route.name == 'Blacklist') {
      this.navIndex = 9
    }
    this.getClubDetail()
    this.getMyClubRole()
  },

  methods: {
    currentNav(index) {
      this.navIndex = index
    },

    // 获取俱乐部信息
    async getClubDetail() {
      try {
        let res = await this.$request.get(`/clubInfo/selectByClubId/${this.$route.params.clubId}`)
        this.clubDetail = res.data
        //俱乐部不存在进行捕获
        if (res.code === 2011) {
          eventBus.$emit('roleChanges', 1)
          return
        }
      }
      catch (e) {
        // console.log("----捕获错误",e)
      }
    },

    // 获取用户在俱乐部的权限
    async getMyClubRole() {
      try {
        let res = await this.$request.get(`/clubMemberInfo/getMyRole/${this.$route.params.clubId}`)
        let data = {
          clubId: this.$route.params.clubId,
          role: 0,
          canEditNews: false,
          canEditActivity: false,
          canEditMember: false,
          canEditTheme: false,
          canEditData: false,
          canActivityData: false,
          canEditClub: false,
          canManagerSuperShare: false,
        }
        if (res.data.role) {
          this.myRole = res.data.role
          this.clubRoles = res.data.roles
          data.role = res.data.role
          for (let i = 0; i < res.data.roles.length; i++) {
            if (res.data.roles[i].roleId == 2) {
              // 俱乐部动态管理
              data.canEditNews = true
            }
            else if (res.data.roles[i].roleId == 3) {
              // 俱乐部活动管理
              data.canEditActivity = true
            }
            else if (res.data.roles[i].roleId == 4) {
              // 俱乐部成员管理
              data.canEditMember = true
            }
            else if (res.data.roles[i].roleId == 5) {
              // 俱乐部相册管理
              data.canEditTheme = true
            }
            else if (res.data.roles[i].roleId == 6) {
              // 编辑俱乐部信息
              data.canEditClub = true
            }
            else if (res.data.roles[i].roleId == 7) {
              // 查看俱乐部数据
              data.canEditData = true
            }
            else if (res.data.roles[i].roleId == 10) {
              // 查看活动数据
              data.canActivityData = true
            }
                            else if (res.data.roles[i].roleId == 11) {
                                // 超级分享者管理
                                data.canManagerSuperShare = true
                            }
          }
        }
        if (res.data.role === 1) {
          eventBus.$emit('roleChanges', 1)
        }
        this.$store.commit('SET_CLUBROLES', data)
      } catch (error) {
        //
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.club-detail-page {
  display: flex;
  .club-top {
    width: 215px;
    flex-shrink: 0;
    background: #fff;
    margin-right: 17px;
    .float-nav {
      height: calc(100vh - 96px - 140px);
      position: sticky;
      top: 96px;
      left: 0;
      padding-bottom: 140px;
      box-sizing: border-box;
    }
  }
}
/*
enter-active 定义进入过渡的结束状态
leave-active 定义离开过渡的结束状态
 */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

/*
enter定义进入过渡的开始状态
 */
.fade-transform-enter {
  opacity: 0;
  transform: translateY(-30px);
}
/*
leave-to离场动画结束后的状态
 */
.fade-transform-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>