import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import global from './utils/global.js'
import uploadCredential from './utils/uploadCredential.js'
import uploadFile from './utils/uploadFile.js'
import request from './utils/request.js'
import login from './utils/login.js'
import beautifyTime from './utils/beautifyTime.js'
import timeFormat from './utils/timeFormat.js'
import { VueJsonp } from 'vue-jsonp'
import './utils/iconfont/iconfont.css'
import Clickoutside from '@/utils/clickoutside'
let echarts = require('echarts');
import test from './utils/test.js'

import dealTime from './utils/dealTime.js'

import ElementUI from 'element-ui'
import { message } from './utils/onlyMsgbox'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/reset.css'


Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueJsonp)

Vue.prototype.$message = message
Vue.prototype.$global = global
Vue.prototype.$credential = uploadCredential
Vue.prototype.$uploadFile = uploadFile
Vue.prototype.$request = request
Vue.prototype.$login = login
Vue.prototype.$beautifyTime = beautifyTime
Vue.prototype.$timeFormat = timeFormat
Vue.prototype.$cosUrl = process.env.COS_CDN_URL_PREFIX
Vue.prototype.$cos = process.env.COS_URL_PREFIX
Vue.prototype.$test = test
Vue.prototype.$echarts = echarts
Vue.prototype.$eventBus = new Vue()

Vue.prototype.$dealTime = dealTime

export const eventBus = new Vue()

Vue.directive(Clickoutside)


Vue.prototype.stopScroll = function () {
  var mo = function (e) { e.preventDefault() }
  document.body.style.overflow = 'hidden'
  document.addEventListener('touchmove', mo, false)// 禁止页面滑动
}
//弹出框可以滑动
Vue.prototype.canScroll = function () {
  var mo = function (e) {
    e.preventDefault()
  }
  document.body.style.overflow = ''// 出现滚动条
  document.removeEventListener('touchmove', mo, false)
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')